import { createReducer } from '../../util';
import * as actions from '../actions/pages';

import { INITIAL_TABLE, INITIAL_TABLE_PAGED } from '../constants/pages';

const initialStatusState = {
    error: false,
    errorMessage: '',
    pending: false,
};

const initialState = {
    'users-landing': {
        ...INITIAL_TABLE,
    },
    'roles-landing': {
        ...INITIAL_TABLE,
    },
    'dashboards-landing': {
        ...INITIAL_TABLE,
    },
    'dashboard-filters-landing': {
        ...INITIAL_TABLE,
    },
    'dashboard-dataSources-landing': {
        ...INITIAL_TABLE,
    },
    'dashboard-types-landing': {
        ...INITIAL_TABLE,
    },
    'clients-landing': {
        ...INITIAL_TABLE,
    },
    'partners-landing': {
        ...INITIAL_TABLE,
    },
    'feeSchedules-landing': {
        ...INITIAL_TABLE,
    },
    'contractsv2-landing': {
        ...INITIAL_TABLE,
    },
    'claim-drill-landing': {
        ...INITIAL_TABLE,
    },
    'statistics-files-landing': {
        ...INITIAL_TABLE,
    },
    'feeschedule-items-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'contracted-rates-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'payments-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'payers-contracts-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'code-services-landing': {
        ...INITIAL_TABLE,
    },
    'codes-landing': {
        ...INITIAL_TABLE,
    },
    'contracts-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'contracts-family-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'vendor-contracts-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'rules-landing': {
        ...INITIAL_TABLE,
    },
    'documents-landing': {
        ...INITIAL_TABLE,
    },
    'payerinfo-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'remit-payers-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'claimAnalysis-landing': {
        ...INITIAL_TABLE_PAGED,
        searchTerm:"",
        showIgnoredFilterDialog:false,
        ignoreAcknowledgement:false,

    },
    'settings-landing': {
        ...INITIAL_TABLE,
        filter: 'All'
    },
    'facilities-landing': {
        ...INITIAL_TABLE
    },
    'search-criteria-landing': {
        ...INITIAL_TABLE,
    },
    'executions-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'payers-scorecard-landing': {
        ...INITIAL_TABLE
    },
    'npiRoles-landing': {
        ...INITIAL_TABLE
    },
    'claim-archived-landing': {
        ...INITIAL_TABLE,
    },
    'modifiers-landing': {
        ...INITIAL_TABLE,
    },
    'claim-estimator-landing': {
        ...INITIAL_TABLE,
    },
    'claim-estimation-result-landing': {
        ...INITIAL_TABLE,
    },
    'estimator-history-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'estimator-history-details-landing': {
        ...INITIAL_TABLE,
    },
    'claim-audits-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    //pricer
    'charge-master-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'shoppable-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'associated-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'allowable-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'pricer-payerinfo-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'pricer-config-contracts-landing': {
        ...INITIAL_TABLE,
    },
    'pricer-config-rates-landing': {
        ...INITIAL_TABLE,
    },

    'code-service-description-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'claim-adjustments-landing': {
        ...INITIAL_TABLE,
    },
    'service-adjustments-landing': {
        ...INITIAL_TABLE,
    },
    'claim-service-adjustments-landing': {
        ...INITIAL_TABLE,
    },
    'processed-claims-landing': {
        ...INITIAL_TABLE,
    },

    'uploads-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'file-executions-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'drg-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'code-service-rules-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'opps-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'pricer-executions-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    // Control Room
    'job-statistics-landing': {
        ...INITIAL_TABLE,
    },
    's3-listners-landing': {
        ...INITIAL_TABLE,
    },
    'qs-dataset-landing': {
        ...INITIAL_TABLE,
    },
    //modeling
    'model-claim-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'model-claim-selection-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'model-executions-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'model-executions-result-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'model-analysis-results-landing': {
        ...INITIAL_TABLE,
    },
    'api-keys-landing': {
        ...INITIAL_TABLE,
    },
    'eapg-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'app-id-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'payer-plan-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'claim-status-logs-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'claim-payers-import-landing': {
        ...INITIAL_TABLE,
    },
    'remit-payers-import-landing': {
        ...INITIAL_TABLE,
    },
    'drg-import-landing': {
        ...INITIAL_TABLE,
    },
    'hospitalservices-master-landing': {
        ...INITIAL_TABLE,
    },
    'modifiers-import-landing': {
        ...INITIAL_TABLE,
    },
    'analysis-stats-landing': {
        ...INITIAL_TABLE,
    },
    open: false,
    anchorEl: null,
    filtersAnchorEl:null,

    'payers-stats-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'client-files-stats-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'stl-load-errors-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'remit-payers-stats-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'hospitalservices-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'mrf-modifiers-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    'claim-status-landing': {
        ...INITIAL_TABLE,
    },
    'payer-id-mapping-landing': {
        ...INITIAL_TABLE_PAGED,
    },
    ...initialStatusState,
};

export default createReducer(initialState, {
    [actions.PAGES_SET]: (state, { page, key, value }) => ({
        ...state,
        [page]: key === null
        ? { ...state[page], ...value,waitForCriteriaFilter:state[page]?.waitForCriteriaFilter,pending:state[page]?.pending}
            : { ...(state[page] || {}), [key]: value },
     }),

    [actions.PAGES_SET_MULTIPLE]: (state, { page, obj }) => ({
        ...state,
        [page]: { ...(state[page] || {}), ...obj },
    }),

    [actions.PAYERS_LANDING_PENDING]: (state) => ({
        ...state,
        'payerinfo-landing': {
            ...state['payerinfo-landing'],
            pending: true,
        },
    }),
    [actions.PAYERS_LANDING_FULFILLED]: (state, { payers, page, sort }) => ({
        ...state,
        'payerinfo-landing': {
            ...state['payerinfo-landing'],
            list: payers,
            page,
            sort,
            pending: false,
        }

    }),
    [actions.PAYERS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'payerinfo-landing': {
            ...state['payerinfo-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    [actions.CONTRACTS_LANDING_PENDING]: (state) => ({
        ...state,
        'contracts-landing': {
            ...state['contracts-landing'],
            pending: true,
        },
    }),
    [actions.CONTRACTS_LANDING_FULFILLED]: (state, { contracts, page, sort }) => ({
        ...state,
        'contracts-landing': {
            ...state['contracts-landing'],
            list: contracts,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.CONTRACTS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'contracts-landing': {
            ...state['contracts-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACTS_FAMILY_LANDING]: (state) => ({
        ...state,
        'contracts-family-landing': {
            ...state['contracts-family-landing'],
            pending: true,
        },
    }),
    [actions.CONTRACTS_FAMILY_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'contracts-family-landing': {
            ...state['contracts-family-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.CONTRACTS_FAMILY_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'contracts-family-landing': {
            ...state['contracts-family-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACTS_FEE_SCHEDULE_ITEMS_LANDING_PENDING]: (state) => ({
        ...state,
        'feeschedule-items-landing': {
            ...state['feeschedule-items-landing'],
            pending: true,
        },
    }),
    [actions.CONTRACTS_FEE_SCHEDULE_ITEMS_LANDING_FULFILLED]: (state, { feeScheduleRates, page, sort }) => ({
        ...state,
        'feeschedule-items-landing': {
            ...state['feeschedule-items-landing'],
            list: feeScheduleRates,
            page,
            sort,
            pending: false,
        }

    }),
    [actions.CONTRACTS_FEE_SCHEDULE_ITEMS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'feeschedule-items-landing': {
            ...state['feeschedule-items-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),
    [actions.CONTRACTS_CONTRACTED_RATES_LANDING_PENDING]: (state) => ({
        ...state,
        'contracted-rates-landing': {
            ...state['contracted-rates-landing'],
            pending: true,
        },
    }),
    [actions.CONTRACTS_CONTRACTED_RATES_LANDING_FULFILLED]: (state, { contractedRates, page, sort }) => ({
        ...state,
        'contracted-rates-landing': {
            ...state['contracted-rates-landing'],
            list: contractedRates,
            page,
            sort,
            pending: false,
        }

    }),
    [actions.CONTRACTS_CONTRACTED_RATES_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'contracted-rates-landing': {
            ...state['contracted-rates-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),
    [actions.PAYMENTS_LANDING_PENDING]: (state) => ({
        ...state,
        'payments-landing': {
            ...state['payments-landing'],
            pending: true,
        },
    }),
    [actions.PAYMENTS_LANDING_FULFILLED]: (state, { contractPayments, page, sort }) => ({
        ...state,
        'payments-landing': {
            ...state['payments-landing'],
            list: contractPayments,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.PAYMENTS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'payments-landing': {
            ...state['payments-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),
    [actions.PAYERS_CONTRACTS_LANDING_PENDING]: (state) => ({
        ...state,
        'payers-contracts-landing': {
            ...state['payers-contracts-landing'],
            pending: true,
        },
    }),
    [actions.PAYERS_CONTRACTS_LANDING_FULFILLED]: (state, { payerContracts, page, sort }) => ({
        ...state,
        'payers-contracts-landing': {
            ...state['payers-contracts-landing'],
            list: payerContracts,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.PAYERS_CONTRACTS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'payers-contracts-landing': {
            ...state['payers-contracts-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_AUDITS_LANDING_PENDING]: (state) => ({
        ...state,
        'claim-audits-landing': {
            ...state['claim-audits-landing'],
            pending: true,
        },
    }),
    [actions.CLAIM_AUDITS_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'claim-audits-landing': {
            ...state['claim-audits-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.CLAIM_AUDITS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'claim-audits-landing': {
            ...state['claim-audits-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ANAYLYSIS_LANDING_PENDING]: (state) => ({
        ...state,
        'claimAnalysis-landing': {
            ...state['claimAnalysis-landing'],
            pending: true,
        },
    }),
    [actions.CLAIM_ANAYLYSIS_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'claimAnalysis-landing': {
            ...state['claimAnalysis-landing'],
            list: results,
            page,
            sort,
            pending: false,
            showIgnoredFilterDialog: !state['claimAnalysis-landing']?.ignoreAcknowledgement && results?.length === 0 && state['claimAnalysis-landing']?.searchTerm?.length > 2
        }
    }),
    [actions.CLAIM_ANAYLYSIS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'claimAnalysis-landing': {
            ...state['claimAnalysis-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),
    [actions.EXECUTIONS_LANDING_PENDING]: (state) => ({
        ...state,
        'executions-landing': {
            ...state['executions-landing'],
            pending: true,
        },
    }),
    [actions.EXECUTIONS_LANDING_FULFILLED]: (state, { executions, page, sort }) => ({
        ...state,
        'executions-landing': {
            ...state['executions-landing'],
            list: executions,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.EXECUTIONS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'executions-landing': {
            ...state['executions-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),
    [actions.VENDOR_CONTRACTS_LANDING_PENDING]: (state) => ({
        ...state,
        'vendor-contracts-landing': {
            ...state['vendor-contracts-landing'],
            pending: true,
        },
    }),
    [actions.VENDOR_CONTRACTS_LANDING_FULFILLED]: (state, { contracts, page, sort }) => ({
        ...state,
        'vendor-contracts-landing': {
            ...state['vendor-contracts-landing'],
            list: contracts,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.VENDOR_CONTRACTS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'vendor-contracts-landing': {
            ...state['vendor-contracts-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),
    [actions.ESTIMATOR_HISTORY_LANDING_PENDING]: (state) => ({
        ...state,
        'estimator-history-landing': {
            ...state['estimator-history-landing'],
            pending: true,
        },
    }),
    [actions.ESTIMATOR_HISTORY_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'estimator-history-landing': {
            ...state['estimator-history-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.ESTIMATOR_HISTORY_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'estimator-history-landing': {
            ...state['estimator-history-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    //pricer
    [actions.CHARGE_MASTER_LANDING_PENDING]: (state) => ({
        ...state,
        'charge-master-landing': {
            ...state['charge-master-landing'],
            pending: true,
        },
    }),
    [actions.CHARGE_MASTER_LANDING_FULFILLED]: (state, { charges, page, sort }) => ({
        ...state,
        'charge-master-landing': {
            ...state['charge-master-landing'],
            list: charges,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.CHARGE_MASTER_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'charge-master-landing': {
            ...state['charge-master-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),
    [actions.SHOPPABLE_LANDING_PENDING]: (state) => ({
        ...state,
        'shoppable-landing': {
            ...state['shoppable-landing'],
            pending: true,
        },
    }),
    [actions.SHOPPABLE_LANDING_FULFILLED]: (state, { shoppables, page, sort }) => ({
        ...state,
        'shoppable-landing': {
            ...state['shoppable-landing'],
            list: shoppables,
            page,
            sort,
            pending: false,
        }

    }),
    [actions.SHOPPABLE_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'shoppable-landing': {
            ...state['shoppable-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),
    [actions.SHOPPABLE_UPDATE_STATUS_FULFILLED]: (state, payload) => ({
        ...state,
        'shoppable-landing': {
            ...state['shoppable-landing'],
            list: state['shoppable-landing'].list.map(
                (shoppable, i) => shoppable.id === payload.id ? { ...shoppable, enabled: payload.enabled } : shoppable //update shoppable status on the base of ID
            ),
        }
    }),
    [actions.ASSOCIATED_LANDING_PENDING]: (state) => ({
        ...state,
        'associated-landing': {
            ...state['associated-landing'],
            pending: true,
        },
    }),
    [actions.ASSOCIATED_LANDING_FULFILLED]: (state, { associated, page, sort }) => ({
        ...state,
        'associated-landing': {
            ...state['associated-landing'],
            list: associated,
            page,
            sort,
            pending: false,
        }

    }),
    [actions.ASSOCIATED_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'associated-landing': {
            ...state['associated-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),
    [actions.ASSOCIATED_UPDATE_STATUS_FULFILLED]: (state, payload) => ({
        ...state,
        'associated-landing': {
            ...state['associated-landing'],
            list: state['associated-landing'].list.map(
                (associated, i) => associated.id === payload.id ? { ...associated, enabled: payload.enabled } : associated //update associated status on the base of ID
            ),
        }
    }),
    [actions.ALLOWABLE_LANDING_PENDING]: (state) => ({
        ...state,
        'allowable-landing': {
            ...state['allowable-landing'],
            pending: true,
        },
    }),
    [actions.ALLOWABLE_LANDING_FULFILLED]: (state, { allowables, page, sort }) => ({
        ...state,
        'allowable-landing': {
            ...state['allowable-landing'],
            list: allowables,
            page,
            sort,
            pending: false,
        }

    }),
    [actions.ALLOWABLE_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'allowable-landing': {
            ...state['allowable-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    [actions.PRICER_PAYERS_LANDING_PENDING]: (state) => ({
        ...state,
        'pricer-payerinfo-landing': {
            ...state['pricer-payerinfo-landing'],
            pending: true,
        },
    }),
    [actions.PRICER_PAYERS_LANDING_FULFILLED]: (state, { payers, page, sort }) => ({
        ...state,
        'pricer-payerinfo-landing': {
            ...state['pricer-payerinfo-landing'],
            list: payers,
            page,
            sort,
            pending: false,
        }

    }),
    [actions.PRICER_PAYERS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'pricer-payerinfo-landing': {
            ...state['pricer-payerinfo-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),
    [actions.PRICER_PAYERS_UPDATE_STATUS_FULFILLED]: (state, payload) => ({
        ...state,
        'pricer-payerinfo-landing': {
            ...state['pricer-payerinfo-landing'],
            list: state['pricer-payerinfo-landing'].list.map(
                (payer, i) => payer.id === payload.id ? { ...payer, disabled: payload.enabled } : payer //update payer status on the base of ID
            ),
        }
    }),

    [actions.CODE_SERVICE_DESCRIPTION_LANDING_PENDING]: (state) => ({
        ...state,
        'code-service-description-landing': {
            ...state['code-service-description-landing'],
            pending: true,
        },
    }),
    [actions.CODE_SERVICE_DESCRIPTION_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'code-service-description-landing': {
            ...state['code-service-description-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.CODE_SERVICE_DESCRIPTION_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'code-service-description-landing': {
            ...state['code-service-description-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    [actions.UPLOADS_LANDING_PENDING]: (state) => ({
        ...state,
        'uploads-landing': {
            ...state['uploads-landing'],
            pending: true,
        },
    }),
    [actions.UPLOADS_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'uploads-landing': {
            ...state['uploads-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.UPLOADS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'uploads-landing': {
            ...state['uploads-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    [actions.FILE_EXECUTIONS_LANDING_PENDING]: (state) => ({
        ...state,
        'file-executions-landing': {
            ...state['file-executions-landing'],
            pending: true,
        },
    }),
    [actions.FILE_EXECUTIONS_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'file-executions-landing': {
            ...state['file-executions-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.FILE_EXECUTIONS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'file-executions-landing': {
            ...state['file-executions-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    [actions.MODEL_CLAIM_SELECTION_LANDING_PENDING]: (state) => ({
        ...state,
        'model-claim-selection-landing': {
            ...state['model-claim-selection-landing'],
            pending: true,
        },
    }),
    [actions.MODEL_CLAIM_SELECTION_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'model-claim-selection-landing': {
            ...state['model-claim-selection-landing'],
            list: results,
            page,
            sort: sort ?? [],
            pending: false,
        }
    }),
    [actions.MODEL_CLAIM_SELECTION_LANDING_INIT_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'model-claim-selection-landing': {
            ...state['model-claim-selection-landing'],
            list: results,
            page,
            sort: sort ?? [],
            pending: false,
            filter: []
        }
    }),
    [actions.MODEL_CLAIM_SELECTION_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'model-claim-selection-landing': {
            ...state['model-claim-selection-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    [actions.MODELING_EXECUTIONS_LANDING_PENDING]: (state) => ({
        ...state,
        'model-executions-landing': {
            ...state['model-executions-landing'],
            pending: true,
        },
    }),
    [actions.MODELING_EXECUTIONS_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'model-executions-landing': {
            ...state['model-executions-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.MODELING_EXECUTIONS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'model-executions-landing': {
            ...state['model-executions-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    [actions.MODELING_EXECUTIONS_RESULT_LANDING_PENDING]: (state) => ({
        ...state,
        'model-executions-result-landing': {
            ...state['model-executions-result-landing'],
            pending: true,
        },
    }),
    [actions.MODELING_EXECUTIONS_RESULT_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'model-executions-result-landing': {
            ...state['model-executions-result-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.MODELING_EXECUTIONS_RESULT_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'model-executions-result-landing': {
            ...state['model-executions-result-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    [actions.CONTRACT_MODELING_LANDING_PENDING]: (state) => ({
        ...state,
        'model-claim-landing': {
            ...state['model-claim-landing'],
            pending: true,
        },
    }),
    [actions.CONTRACT_MODELING_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'model-claim-landing': {
            ...state['model-claim-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.CONTRACT_MODELING_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'model-claim-landing': {
            ...state['model-claim-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    [actions.DRG_LANDING_PENDING]: (state) => ({
        ...state,
        'drg-landing': {
            ...state['drg-landing'],
            pending: true,
        },
    }),
    [actions.DRG_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'drg-landing': {
            ...state['drg-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.DRG_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'drg-landing': {
            ...state['drg-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    [actions.OPPS_LANDING_PENDING]: (state) => ({
        ...state,
        'opps-landing': {
            ...state['opps-landing'],
            pending: true,
        },
    }),
    [actions.OPPS_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'opps-landing': {
            ...state['opps-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.OPPS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'opps-landing': {
            ...state['opps-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    [actions.CODE_SERVICE_GET_RULES_LANDING_PENDING]: (state) => ({
        ...state,
        'code-service-rules-landing': {
            ...state['code-service-rules-landing'],
            pending: true,
        },
    }),
    [actions.CODE_SERVICE_GET_RULES_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'code-service-rules-landing': {
            ...state['code-service-rules-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.CODE_SERVICE_GET_RULES_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'code-service-rules-landing': {
            ...state['code-service-rules-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    [actions.REMIT_PAYERS_LANDING_PENDING]: (state) => ({
        ...state,
        'remit-payers-landing': {
            ...state['remit-payers-landing'],
            pending: true,
        },
    }),
    [actions.REMIT_PAYERS_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'remit-payers-landing': {
            ...state['remit-payers-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.REMIT_PAYERS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'remit-payers-landing': {
            ...state['remit-payers-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    [actions.RESET_PAGE_FILTER]: (state) => ({
        ...initialState,
    }),
    [actions.RESET_PAGE]: (state, { pageName }) => ({
        ...state,
        [pageName]: {
            ...INITIAL_TABLE_PAGED,
            filter: state[pageName].filter // this line is intentially added to persist filters on session logout
        }
    }),

    [actions.EAPG_LANDING_PENDING]: (state) => ({
        ...state,
        'eapg-landing': {
            ...state['eapg-landing'],
            pending: true,
        },
    }),
    [actions.EAPG_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'eapg-landing': {
            ...state['eapg-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.EAPG_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'eapg-landing': {
            ...state['eapg-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    [actions.APP_ID_LANDING_PENDING]: (state) => ({
        ...state,
        'app-id-landing': {
            ...state['app-id-landing'],
            pending: true,
        },
    }),
    [actions.APP_ID_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'app-id-landing': {
            ...state['app-id-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.APP_ID_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'app-id-landing': {
            ...state['app-id-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),
    [actions.ADVANCED_FILTERS_DIALOG_STATE]: (state, { open,anchorEl,filtersAnchorEl }) => ({
        ...state,
        open,
        anchorEl,
        filtersAnchorEl
    }),

    [actions.PAYERS_STATS_LANDING_PENDING]: (state) => ({
        ...state,
        'payers-stats-landing': {
            ...state['payers-stats-landing'],
            pending: true,
        },
    }),
    [actions.PAYERS_STATS_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'payers-stats-landing': {
            ...state['payers-stats-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }

    }),
    [actions.PAYERS_STATS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'payers-stats-landing': {
            ...state['payers-stats-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),
    [actions.REMIT_PAYERS_STATS_LANDING_PENDING]: (state) => ({
        ...state,
        'remit-payers-stats-landing': {
            ...state['remit-payers-stats-landing'],
            pending: true,
        },
    }),
    [actions.REMIT_PAYERS_STATS_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'remit-payers-stats-landing': {
            ...state['remit-payers-stats-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }

    }),
    [actions.REMIT_PAYERS_STATS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'remit-payers-stats-landing': {
            ...state['remit-payers-stats-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    // client files 


    [actions.CLIENT_FILES_STATS_LANDING_PENDING]: (state) => ({
        ...state,
        'client-files-stats-landing': {
            ...state['client-files-stats-landing'],
            pending: true,
        },
    }),
    [actions.CLIENT_FILES_STATS_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'client-files-stats-landing': {
            ...state['client-files-stats-landing'],
            pending: false,
            list:results,
            page,
            sort

        },
    }),

    [actions.CLIENT_FILES_STATS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'client-files-stats-landing': {
            ...state['client-files-stats-landing'],
            pending: false,
            error: true,
            errorMessage,
        }}),

        // client files 

//LOAD ERRORS

[actions.STL_LOAD_ERRORS_LANDING_PENDING]: (state) => ({
    ...state,
    'stl-load-errors-landing': {
        ...state['stl-load-errors-landing'],
        pending: true,
    },
}),
[actions.STL_LOAD_ERRORS_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
    ...state,
    'stl-load-errors-landing': {
        ...state['stl-load-errors-landing'],
        pending: false,
        list:results,
        page,
        sort

    },
}),

[actions.STL_LOAD_ERRORS_LANDING_REJECTED]: (state, errorMessage) => ({
    ...state,
    'stl-load-errors-landing': {
        ...state['stl-load-errors-landing'],
        pending: false,
        error: true,
        errorMessage,
    }}),
//LOAD ERRORS


    [actions.HOSPITAL_SERVICES_LANDING_PENDING]: (state) => ({
        ...state,
        'hospitalservices-landing': {
            ...state['hospitalservices-landing'],
            pending: true,
        },
    }),
    [actions.HOSPITAL_SERVICES_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'hospitalservices-landing': {
            ...state['hospitalservices-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),

    [actions.HOSPITAL_SERVICES_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'hospitalservices-landing': {
            ...state['hospitalservices-landing'],
            pending: false,
            error: true,
            errorMessage,
        }
    }),

    [actions.MRF_MODIFIERS_LANDING_PENDING]: (state) => ({
        ...state,
        'mrf-modifiers-landing': {
            ...state['mrf-modifiers-landing'],
            pending: true,
        },
    }),
    [actions.MRF_MODIFIERS_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'mrf-modifiers-landing': {
            ...state['mrf-modifiers-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),

    [actions.MRF_MODIFIERS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'mrf-modifiers-landing': {
            ...state['mrf-modifiers-landing'],
            pending: false,
            error: true,
            errorMessage,
        }
    }),
    [actions.PRICER_EXECUTIONS_LANDING_PENDING]: (state) => ({
        ...state,
        'pricer-executions-landing': {
            ...state['pricer-executions-landing'],
            pending: true,
        },
    }),
    [actions.PRICER_EXECUTIONS_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'pricer-executions-landing': {
            ...state['pricer-executions-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.PRICER_EXECUTIONS_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'pricer-executions-landing': {
            ...state['pricer-executions-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

    [actions.PAYER_ID_MAPPING_LANDING_PENDING]: (state) => ({
        ...state,
        'payer-id-mapping-landing': {
            ...state['payer-id-mapping-landing'],
            pending: true,
        },
    }),
    [actions.PAYER_ID_MAPPING_LANDING_FULFILLED]: (state, { results, page, sort }) => ({
        ...state,
        'payer-id-mapping-landing': {
            ...state['payer-id-mapping-landing'],
            list: results,
            page,
            sort,
            pending: false,
        }
    }),
    [actions.PAYER_ID_MAPPING_LANDING_REJECTED]: (state, errorMessage) => ({
        ...state,
        'payer-id-mapping-landing': {
            ...state['payer-id-mapping-landing'],
            pending: false,
            error: true,
            errorMessage,
        },
    }),

})