import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import AnalysisStatisticsPageComponent from './AnalysisStatisticsPage';

import { set } from '../../../store/actions/pages';
import { integerEquators, StringEquators } from '../../../store/constants/pages';
import { createCurrencyDataField, createNumberDataField, createStringDataField } from '../../../util/format';
import { createSortedDataSelector } from "../../../util/selector";
import { getAnalysisStatistics } from "../../../store/actions/statistics";

const DEFAULT_SORT = [{ id: 'state', orderBy: 'asc' }];

const selectFields: any = createSelector(
    () => [
        createCurrencyDataField('totalBilled', 'Total Billed', { sortable: true, equators: integerEquators, calculateTotal: true }),
        createCurrencyDataField('totalDenied', 'Total Denied', { sortable: true, equators: integerEquators, calculateTotal: true }),
        createCurrencyDataField('totalUnderpaid', 'Total Underpaid', { sortable: true, equators: integerEquators, calculateTotal: true }),
        createNumberDataField('totalClaims', 'Total Claims', { sortable: true, equators: integerEquators }),
        createStringDataField('state', 'State', { sortable: true, equators: StringEquators }),
    ]
);

const selectFilteredFields = createSelector(
    selectFields,
    (fields) => {
        return fields.map(field => field.id);
    }
);

const selectAnalysisStatsData = createSelector(
    (state) => state.statistics.analysisStatsList,
    (state) => state.pages['analysis-stats-landing'].filter,
    (data, filters) => {
        return data;
    }
);

const selectSort = createSelector(
    (state) => state.pages['analysis-stats-landing'].sort,
    (sort) => sort || DEFAULT_SORT,
);

const selectSortedDataSelector = createSortedDataSelector(selectFilteredFields, selectSort, selectAnalysisStatsData)

export const AnalysisStatisticsPage = connect(
    () => {
        return (state, props) => ({
            data: selectSortedDataSelector(state),
            fields: selectFields(state),
            analysisStatsPending: state.statistics.getAnalysisStats.pending,
            order: state.pages['analysis-stats-landing'].order,
            filter: state.pages['analysis-stats-landing'].filter,
            sort: selectSort(state),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            analysisStatsLanding: getAnalysisStatistics,
            setOrder: (value) => set('analysis-stats-landing', 'order', value),
            setSort: (value) => set('analysis-stats-landing', 'sort', value),
            setFilter: (value) => set(`analysis-stats-landing`, 'filter', value),
        }, dispatch)
    })
)(injectIntl(AnalysisStatisticsPageComponent));