import React, { useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    Switch,
    FormControlLabel,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MuiAlert from '@mui/material/Alert';

import { Formik } from "formik";
import { Loadable } from '../../../common';
import { Autocomplete } from '@mui/material';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '100%',
    },
    root: {
        flexGrow: 1,
    },
    checkboxlabel: {
        marginLeft: 0
    },
    switch: {
        margin: theme.spacing(1),
    },
}));

export default function PayerDialog(props) {
    const {
        open, handleClose, mode, intl, initialValues, payerCreatePending, payerUpdateBulkPending,
        payerUpdatePending, payerContracts, selectedPayers, isBulk,
        actions: { payerCreate, payerUpdate, payerBulkUpdate, payerErrorReset, setSelected }
    } = props;
    const classes = useStyles();
    let shouldReloadGrid = false;

    function handleCallback(reload) {
        setSelected([]);
        handleClose(reload);
    }

    useEffect(() => {
        return function cleanup() {
            payerErrorReset();
        };
    }, [payerErrorReset]);

    function getTitle() {
        if (mode === 'edit') {
            if (isBulk) {
                return `Edit Payer (${selectedPayers.length})`;
            }
            return 'Edit Payer';
        }
        return 'Add Payer';
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (<>
        {open && (
        <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{getTitle()}</DialogTitle>
            <DialogContent dividers>
                <Loadable loading={payerCreatePending || payerUpdatePending || payerUpdateBulkPending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors:any = {};
                            // if (!values.payerContractId) {
                            //     errors.payerContractId = intl.formatMessage({
                            //         id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            //     });
                            // }

                            if (!values.name && !isBulk) {
                                errors.name = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.type && !isBulk) {
                                errors.type = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            shouldReloadGrid = true;
                            let payload:any = {
                                active: values.enabled,
                                payerContractId: values.payerContractId?.id ?? null,
                                name: values.name,
                                type: values.type,
                            }
                            if (mode === 'create') {
                                payerCreate(payload, handleCallback, shouldReloadGrid);
                            }
                            else {
                                if (isBulk) {
                                    payload = {
                                        active: values.enabled,
                                        payerContractId: values.payerContractId.id,
                                        id: selectedPayers
                                    }
                                    payerBulkUpdate(payload, handleCallback, shouldReloadGrid);
                                } else {
                                    payload = {
                                        ...payload,
                                        id: values.id,
                                    }
                                    payerUpdate(payload, handleCallback, shouldReloadGrid);
                                }
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting
                        }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                <div className={classes.root}>
                                    <Grid container>
                                        {isBulk && (selectedPayers.length < 1) ? (
                                            <Grid container justifyContent="flex-end">
                                                <Grid item xs={12}>
                                                    <Typography>No payer selected. Please select at least one payer to update and try again.</Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                            :
                                            <>
                                                {!isBulk && (
                                                    <Grid item xs={12} md={12}>
                                                        <TextField
                                                            required
                                                            disabled={mode === 'edit'}
                                                            label="Name"
                                                            name="name"
                                                            margin="normal"
                                                            value={values.name}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            error={Boolean(touched.name && errors.name)}
                                                            helperText={touched.name && errors.name}
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid item xs={12} md={12}>
                                                    <Autocomplete
                                                        value={values.payerContractId}
                                                        disableClearable={false}
                                                        onChange={(e, value) => setFieldValue("payerContractId", value)}
                                                        isOptionEqualToValue={(option, value) => option === value}
                                                        options={payerContracts}
                                                        getOptionLabel={(option) => option.displayName}
                                                        renderInput={(params) =>
                                                            <TextField {...params}
                                                                name="payerContractId"
                                                                label="Payer Contract"
                                                                error={Boolean(touched.payerContractId && errors.payerContractId)}
                                                                helperText={touched.payerContractId && errors.payerContractId}
                                                                margin="normal" />}
                                                    />
                                                </Grid>
                                                {!isBulk && (
                                                    <Grid item xs={12} md={12}>
                                                        <TextField
                                                            required
                                                            label="Type"
                                                            name="type"
                                                            margin="normal"
                                                            value={values.type}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            error={Boolean(touched.type && errors.type)}
                                                            helperText={touched.type && errors.type}
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid className="mt-2" item xs={12} md={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                name="enabled"
                                                                checked={values.enabled}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={classes.switch}
                                                                value={values.enabled}
                                                            />
                                                        }
                                                        label="Enable"
                                                    />
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                    <hr />
                                    <Grid container className='justify-content-end'>
                                        <Button onClick={handleClose} variant="contained" className={classes.button}>
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            type="submit"
                                            disabled={payerCreatePending || payerUpdatePending || payerUpdateBulkPending || (isBulk && selectedPayers.length < 1)}
                                        >
                                            {(payerCreatePending || payerUpdatePending || payerUpdateBulkPending) ? 'Saving Changes' : 'Save'}
                                        </Button>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>
        )}
    </>);
}