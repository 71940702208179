import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import { bindActionCreators } from 'redux';

import ClaimSummaryDashboardComponent from './ClaimSummaryDashboard';
import { setMulti } from '../../store/actions/pages';

export const ClaimSummaryDashboard = injectIntl(
    connect(
        (state, props) => ({
        }),
        (dispatch) => ({
            actions: bindActionCreators({
                setMulti: obj => setMulti("claimAnalysis-landing", obj),
            }, dispatch)
        })
    )(ClaimSummaryDashboardComponent));