import { http } from './common';

const getDashboardData = async (frequency) =>
    http.get(`/api/v1/cms/rcmstatus/dashboard/${frequency}`);

const getDashboardSummary = async (frequency) =>
    http.get(`/api/v1/cms/rcmstatus/dashboard/summary/${frequency}`);



const downloadReport = async (dateFilter,reportType) => {
    return http.get(`/api/v1/cms/rcmstatus/dashboard/export/${dateFilter}/${reportType}`,null,"octet-stream");
}

const downloadFullReport = async (dateFilter) => {
    return http.get(`/api/v1/cms/rcmstatus/dashboard/export-excel/${dateFilter}`,null,"octet-stream");
}
export {
    getDashboardData,
    getDashboardSummary,
    downloadReport,
    downloadFullReport
}