import { createReducer } from '../../util';
import * as actions from '../actions/claimAnalysis';
import { StatusState } from './common';

const initialStatusState : StatusState = {
    error: false,
    errorMessage: '',
    pending: false,
}

const initialState = {
    detail: [],
    drilldown: {},
    historyData: [],
    stats: {},
    jsonView: null,
    consolidatedClaimsData: null,
    archivedClaim: null,
    claimAdjustments: [],
    serviceAdjustments: [],
    adjustment: {},
    claimStatuses: [],
    claimServiceAdjustments: {},
    drgCodes: [],
    cptCodes: [],
    revenueCodes: [],
    associatedCharges: [],
    claimServiceAdjustmentsData: [],
    dxCodes: [],
    estimateById: null,
    claimAllDetails: null,
    comments: null,
    newComment:'',
    claimAnalysisConfirmationDialog:{open:false,state:''},

    claimChargeMode: 'new',// 
    claimChargeIndex: null,// 

    claimChargeData: [], // Grid displayed under estimator
    estimatedResult: null,

    openFlagDialog: false,
    openAcknowledgeDialog: false,
    selectedClaims: [],
    flagToggle: false,
    isBulkFlagged: false,
    isBulkAcknowledge: false,
    openPlayDialog: false,
    processBulkOnDemandResponse: [],
    processedBulkClaimsDialogOpen: false,
    updateClaimStatusDialogOpen: false,
    claimStatusLogs: [],
    claimStatusHistoryDialogOpen: false,

    estimatorHistoryDetailsDialog: null, //{open:true,result:obj}
    overrideContractResponse: null,
    lookupId:"",

    get: {
        ...initialStatusState,
    },
    getConsolidateClaims: {
        ...initialStatusState,
    },
    acknowledge: {
        ...initialStatusState,
    },
    getStats: {
        ...initialStatusState
    },
    download: {
        ...initialStatusState,
    },
    flag: {
        ...initialStatusState,
    },
    claimprocess: {
        ...initialStatusState,
    },
    claimBulkProcess: {
        ...initialStatusState,
    },
    getJsonView: {
        ...initialStatusState,
    },
    getConsolidateJsonView: {
        ...initialStatusState,
    },
    deleteByPayerContract: {
        ...initialStatusState
    },
    deleteByContract: {
        ...initialStatusState
    },
    getHistory: {
        ...initialStatusState
    },
    getClaimAdjustments: {
        ...initialStatusState
    },
    getServiceAdjustments: {
        ...initialStatusState
    },
    getDRGCodes: {
        ...initialStatusState
    },
    getCPTCodes: {
        ...initialStatusState
    },
    getRevenueCodes: {
        ...initialStatusState
    },
    getAssociatedCharges: {
        ...initialStatusState
    },
    estimateClaim: {
        ...initialStatusState
    },
    calculateOOP: {
        ...initialStatusState
    },
    processClaimsFromAudit: {
        ...initialStatusState
    },
    processClaimFromAudit: {
        ...initialStatusState
    },
    getClaimServiceAdjustments: {
        ...initialStatusState
    },
    getEstimateById: {
        ...initialStatusState
    },
    editEstimateById: {
        ...initialStatusState
    },
    getDXCodes: {
        ...initialStatusState
    },
    getClaimAllDetails: {
        ...initialStatusState
    },
    getClaimStatuses: {
        ...initialStatusState
    },
    processBulkOnDemand: {
        ...initialStatusState
    },
    overrideContract: {
        ...initialStatusState
    },
    getComments: {
        ...initialStatusState
    },
    removeOverriddenContract: {
        ...initialStatusState
    },
    updateClaimStatus:{...initialStatusState},
    updateSingleClaimStatus:{...initialStatusState},
    getClaimStatusLogs: {
        ...initialStatusState
    },

    defaultCustomFilters: [
        {
            field: 'acknowledged',
            equator: 'equals',
            value: false,
        },
        {
            field: 'isPaid',
            equator: 'equals',
            value: true,
        },
        {
            field: 'isDenial',
            equator: 'equals',
            value: "both",
        },
        {
            field: 'flag',
            equator: 'equals',
            value: "both",
        }
    ],
    relativeFilterValue: '',
    highlightedRowId: null,
    count : undefined,
    executionId: undefined,
};

export default createReducer(initialState, {
    [actions.CLAIM_ANALYSIS_GET_PENDING]: (state) => ({
        ...state,
        get: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_GET_FULFILLED]: (state, detail) => ({
        ...state,
        get: {
            ...initialStatusState,
        },
        detail,
    }),
    [actions.CLAIM_ANALYSIS_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        get: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),


// consolidated claims get 
    [actions.CLAIM_ANALYSIS_CONSOLIDATED_CLAIMS_GET_PENDING]: (state) => ({
        ...state,
        getConsolidateClaims: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_CONSOLIDATED_CLAIMS_GET_FULFILLED]: (state, consolidatedClaimsData) => ({
        ...state,
        getConsolidateClaims: {
            ...initialStatusState,
        },
        consolidatedClaimsData,
    }),
    [actions.CLAIM_ANALYSIS_CONSOLIDATED_CLAIMS_GET_REJECTED]: (state, errorMessage) => ({
        ...state,
        getConsolidateClaims: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

// consolidated claims get 


    
    [actions.CLAIM_ANALYSIS_RESET_DETAIL]: (state) => ({
        ...state,
        detail: []
    }),
    [actions.CLAIM_ACKNOWLEDGE_PENDING]: (state) => ({
        ...state,
        acknowledge: {
            pending: true,
        },
    }),
    [actions.CLAIM_ACKNOWLEDGE_FULFILLED]: (state) => ({
        ...state,
        acknowledge: {
            ...initialStatusState,
        }
    }),
    [actions.CLAIM_ACKNOWLEDGE_REJECTED]: (state, errorMessage) => ({
        ...state,
        acknowledge: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLAIM_ANAYLYSIS_STATISTICS_PENDING]: (state) => ({
        ...state,
        getStats: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANAYLYSIS_STATISTICS_FULFILLED]: (state, stats) => ({
        ...state,
        stats,
        getStats: {
            ...initialStatusState,
        }
    }),
    [actions.CLAIM_ANAYLYSIS_STATISTICS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getStats: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLAIM_ANAYLYSIS_DETAIL_ARR_RESET]: (state) => ({
        ...state,
        detail: []
    }),
    [actions.CLAIM_ANAYLYSIS_DOWNLOAD_REPORT_PENDING]: (state) => ({
        ...state,
        download: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANAYLYSIS_DOWNLOAD_REPORT_FULFILLED]: (state) => ({
        ...state,
        download: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_ANAYLYSIS_DOWNLOAD_REPORT_REJECTED]: (state, errorMessage) => ({
        ...state,
        download: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLAIM_ANALYSIS_DRILLDOWN_SET]: (state, { payload }) => ({
        ...state,
        drilldown: payload,
    }),
    [actions.CLAIM_FLAG_PENDING]: (state) => ({
        ...state,
        flag: {
            pending: true,
        },
    }),
    [actions.CLAIM_FLAG_FULFILLED]: (state) => ({
        ...state,
        flag: {
            ...initialStatusState,
        }
    }),
    [actions.CLAIM_FLAG_REJECTED]: (state, errorMessage) => ({
        ...state,
        flag: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ANALYSIS_PROCESS_PENDING]: (state) => ({
        ...state,
        claimprocess: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_PROCESS_FULFILLED]: (state) => ({
        ...state,
        claimprocess: {
            ...initialStatusState,
        }
    }),
    [actions.CLAIM_ANALYSIS_PROCESS_REJECTED]: (state, errorMessage) => ({
        ...state,
        claimprocess: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLAIM_ANALYSIS_BULK_PROCESS_PENDING]: (state) => ({
        ...state,
        claimBulkProcess: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_BULK_PROCESS_FULFILLED]: (state) => ({
        ...state,
        claimBulkProcess: {
            ...initialStatusState,
        }
    }),
    [actions.CLAIM_ANALYSIS_BULK_PROCESS_REJECTED]: (state, errorMessage) => ({
        ...state,
        claimBulkProcess: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLAIM_ANALYSIS_JSON_VIEW_PENDING]: (state) => ({
        ...state,
        getJsonView: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_JSON_VIEW_FULFILLED]: (state, jsonView) => ({
        ...state,
        getJsonView: {
            ...initialStatusState,
        },
        jsonView: jsonView
    }),
    [actions.CLAIM_ANALYSIS_JSON_VIEW_REJECTED]: (state, errorMessage) => ({
        ...state,
        getJsonView: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ANALYSIS_DELETE_BY_PAYER_CONTRACT_ID_PENDING]: (state) => ({
        ...state,
        deleteByPayerContract: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_DELETE_BY_PAYER_CONTRACT_ID_FULFILLED]: (state) => ({
        ...state,
        deleteByPayerContract: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_ANALYSIS_DELETE_BY_PAYER_CONTRACT_ID_REJECTED]: (state, errorMessage) => ({
        ...state,
        deleteByPayerContract: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID_PENDING]: (state) => ({
        ...state,
        deleteByContract: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID_FULFILLED]: (state) => ({
        ...state,
        deleteByContract: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID_REJECTED]: (state, errorMessage) => ({
        ...state,
        deleteByContract: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ANALYSIS_HISTORY_PENDING]: (state) => ({
        ...state,
        getHistory: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_HISTORY_FULFILLED]: (state, historyData) => ({
        ...state,
        historyData,
        getHistory: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_ANALYSIS_HISTORY_REJECTED]: (state, errorMessage) => ({
        ...state,
        getHistory: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ANALYSIS_ADJUSTMENTS_PENDING]: (state) => ({
        ...state,
        getClaimAdjustments: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_ADJUSTMENTS_FULFILLED]: (state, claimAdjustments) => ({
        ...state,
        claimAdjustments,
        getClaimAdjustments: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_ANALYSIS_ADJUSTMENTS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getClaimAdjustments: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ANALYSIS_SERVICE_ADJUSTMENTS_PENDING]: (state) => ({
        ...state,
        getServiceAdjustments: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_SERVICE_ADJUSTMENTS_FULFILLED]: (state, serviceAdjustments) => ({
        ...state,
        serviceAdjustments,
        getServiceAdjustments: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_ANALYSIS_SERVICE_ADJUSTMENTS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getServiceAdjustments: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ANALYSIS_GET_DRGS_PENDING]: (state) => ({
        ...state,
        getDRGCodes: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_GET_DRGS_FULFILLED]: (state, drgCodes) => ({
        ...state,
        drgCodes,
        getDRGCodes: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_ANALYSIS_GET_DRGS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getDRGCodes: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLAIM_ANALYSIS_GET_CPTS_PENDING]: (state) => ({
        ...state,
        getCPTCodes: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_GET_CPTS_FULFILLED]: (state, cptCodes) => ({
        ...state,
        cptCodes,
        getCPTCodes: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_ANALYSIS_GET_CPTS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getCPTCodes: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLAIM_ANALYSIS_GET_REVENUE_CODES_PENDING]: (state) => ({
        ...state,
        getRevenueCodes: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_GET_REVENUE_CODES_FULFILLED]: (state, revenueCodes) => ({
        ...state,
        revenueCodes,
        getRevenueCodes: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_ANALYSIS_GET_REVENUE_CODES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getRevenueCodes: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLAIM_ANALYSIS_GET_ASSOCIATED_CHARGES_PENDING]: (state) => ({
        ...state,
        getAssociatedCharges: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_GET_ASSOCIATED_CHARGES_FULFILLED]: (state, associatedCharges) => ({
        ...state,
        associatedCharges,
        getAssociatedCharges: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_ANALYSIS_GET_ASSOCIATED_CHARGES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getAssociatedCharges: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLAIM_ANALYSIS_ESTIMATE_PENDING]: (state) => ({
        ...state,
        estimateClaim: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_ESTIMATE_FULFILLED]: (state, estimatedResult) => ({
        ...state,
        estimatedResult,
        estimateClaim: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_ANALYSIS_ESTIMATE_REJECTED]: (state, errorMessage) => ({
        ...state,
        estimateClaim: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ESTIMATOR_CALCULATE_OOP_PENDING]: (state) => ({
        ...state,
        calculateOOP: {
            pending: true,
        },
    }),
    [actions.CLAIM_ESTIMATOR_CALCULATE_OOP_FULFILLED]: (state, estimateById) => ({
        ...state,
        estimateById,
        calculateOOP: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_ESTIMATOR_CALCULATE_OOP_REJECTED]: (state, errorMessage) => ({
        ...state,
        calculateOOP: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ANALYSIS_AUDIT_PROCESS_PENDING]: (state) => ({
        ...state,
        processClaimsFromAudit: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_AUDIT_PROCESS_FULFILLED]: (state) => ({
        ...state,
        processClaimsFromAudit: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_ANALYSIS_AUDIT_PROCESS_REJECTED]: (state, errorMessage) => ({
        ...state,
        processClaimsFromAudit: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    
    [actions.SINGLE_CLAIM_ANALYSIS_AUDIT_PROCESS_PENDING]: (state) => ({
        ...state,
        processClaimFromAudit: {
            pending: true,
        },
    }),
    [actions.SINGLE_CLAIM_ANALYSIS_AUDIT_PROCESS_FULFILLED]: (state) => ({
        ...state,
        processClaimFromAudit: {
            ...initialStatusState,
        },
    }),
    [actions.SINGLE_CLAIM_ANALYSIS_AUDIT_PROCESS_REJECTED]: (state, errorMessage) => ({
        ...state,
        processClaimFromAudit: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ESTIMATOR_SET_CHARGE]: (state, { claimChargeData }) => ({
        ...state,
        claimChargeData,
    }),

    [actions.CLAIM_ANALYSIS_SET_ADJUSTMENT]: (state, { payload }) => ({
        ...state,
        adjustment: payload
    }),
    [actions.CLAIM_ESTIMATOR_SET_MODE]: (state, { claimChargeMode, claimChargeIndex }) => ({
        ...state,
        claimChargeMode,
        claimChargeIndex
    }),

    [actions.CLAIM_ANALYSIS_DELETE_BY_CONTRACT_ID_RESET]: (state) => ({
        ...state,
        deleteByContract: {
            ...initialStatusState,
        },
    }),

    [actions.CLAIM_ANALYSIS_RESER_JSON_VIEW]: (state) => ({
        ...state,
        jsonView: null
    }),

    [actions.CLAIM_ANALYSIS_RESET_CONSOLIDATED_CLAIMS_VIEW]: (state) => ({
        ...state,
        consolidatedClaimsData:  null
    }),
    [actions.CLAIM_ANALYSIS_RESET]: () => ({ ...initialState }),
    [actions.CLAIM_ANAYLYSIS_ERROR_RESET]: (state) => ({
        ...state,
        claimBulkProcess: {
            ...initialStatusState,
        },
        claimprocess: {
            ...initialStatusState,
        }
    }),
    [actions.CLAIM_ANAYLYSIS_SET_FLAG_DIALOG]: (state, { openFlagDialog }) => ({
        ...state,
        openFlagDialog,
    }),
    [actions.CLAIM_ANAYLYSIS_SET_ACKNOWLEDGED_DIALOG]: (state, { openAcknowledgeDialog }) => ({
        ...state,
        openAcknowledgeDialog,
    }),
    [actions.CLAIM_ANAYLYSIS_SET_FLAG_TOGGLE]: (state, { flagToggle }) => ({
        ...state,
        flagToggle,
    }),
    [actions.CLAIM_ANAYLYSIS_SET_BULK_FLAGGED]: (state, { isBulkFlagged }) => ({
        ...state,
        isBulkFlagged,
    }),
    [actions.CLAIM_ANAYLYSIS_SET_BULK_ACKNOWLEDGED]: (state, { isBulkAcknowledge }) => ({
        ...state,
        isBulkAcknowledge,
    }),
    [actions.CLAIM_ANAYLYSIS_SET_SELECTED_CLAIMS]: (state, { selectedClaims }) => ({
        ...state,
        selectedClaims,
    }),
    [actions.CLAIM_ANAYLYSIS_SET_PLAY_DIALOG]: (state, { openPlayDialog }) => ({
        ...state,
        openPlayDialog,
    }),

    [actions.CLAIM_ANAYLYSIS_SET_ARCHIVED_CLAIM]: (state, { archivedClaim }) => ({
        ...state,
        archivedClaim,
    }),
    [actions.CLAIM_ANAYLYSIS_SET_RELATIVE_FILTER]: (state, { relativeFilterValue }) => ({
        ...state,
        relativeFilterValue,
    }),
    [actions.CLAIM_ANAYLYSIS_SET_HIGHLIGHTED_ROW_ID]: (state, { highlightedRowId }) => ({
        ...state,
        highlightedRowId,
    }),


    [actions.CLAIM_ANALYSIS_SET_CLAIM_SERVICE_ADJUSTMENT]: (state, { payload }) => ({
        ...state,
        claimServiceAdjustments: payload,
    }),

    [actions.CLAIM_ANALYSIS_GET_SERVICE_ADJUSTMENTS_PENDING]: (state) => ({
        ...state,
        getClaimServiceAdjustments: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_GET_SERVICE_ADJUSTMENTS_FULFILLED]: (state, claimServiceAdjustmentsData) => ({
        ...state,
        getClaimServiceAdjustments: {
            ...initialStatusState,
        },
        claimServiceAdjustmentsData
    }),
    [actions.CLAIM_ANALYSIS_GET_SERVICE_ADJUSTMENTS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getClaimServiceAdjustments: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ESTIMATOR_GET_BY_ID_PENDING]: (state) => ({
        ...state,
        getEstimateById: {
            pending: true,
        },
    }),
    [actions.CLAIM_ESTIMATOR_GET_BY_ID_FULFILLED]: (state, estimateById) => ({
        ...state,
        getEstimateById: {
            ...initialStatusState,
        },
        estimateById
    }),
    [actions.CLAIM_ESTIMATOR_GET_BY_ID_REJECTED]: (state, errorMessage) => ({
        ...state,
        getEstimateById: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ESTIMATOR_EDIT_BY_ID_PENDING]: (state) => ({
        ...state,
        editEstimateById: {
            pending: true,
        },
    }),
    [actions.CLAIM_ESTIMATOR_EDIT_BY_ID_FULFILLED]: (state) => ({
        ...state,
        editEstimateById: {
            ...initialStatusState,
        },
    }),
    [actions.CLAIM_ESTIMATOR_EDIT_BY_ID_REJECTED]: (state, errorMessage) => ({
        ...state,
        editEstimateById: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ESTIMATOR_GET_DX_CODES_PENDING]: (state) => ({
        ...state,
        getDXCodes: {
            pending: true,
        },
    }),
    [actions.CLAIM_ESTIMATOR_GET_DX_CODES_FULFILLED]: (state, dxCodes) => ({
        ...state,
        getDXCodes: {
            ...initialStatusState,
        },
        dxCodes
    }),
    [actions.CLAIM_ESTIMATOR_GET_DX_CODES_REJECTED]: (state, errorMessage) => ({
        ...state,
        getDXCodes: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS_PENDING]: (state) => ({
        ...state,
        getClaimAllDetails: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS_FULFILLED]: (state, claimAllDetails) => ({
        ...state,
        getClaimAllDetails: {
            ...initialStatusState,
        },
        claimAllDetails
    }),
    [actions.CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getClaimAllDetails: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ANALYSIS_BULK_ONDEMAND_PENDING]: (state) => ({
        ...state,
        processBulkOnDemand: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_BULK_ONDEMAND_FULFILLED]: (state, processBulkOnDemandResponse) => ({
        ...state,
        processBulkOnDemand: {
            ...initialStatusState,
        },
        processBulkOnDemandResponse
    }),
    [actions.CLAIM_ANALYSIS_BULK_ONDEMAND_REJECTED]: (state, errorMessage) => ({
        ...state,
        processBulkOnDemand: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),
    [actions.CLAIM_ANALYSIS_OVERRIDE_CONTRACT_PENDING]: (state) => ({
        ...state,
        overrideContract: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_OVERRIDE_CONTRACT_FULFILLED]: (state, overrideContractResponse) => ({
        ...state,
        overrideContract: {
            ...initialStatusState,
        },
        overrideContractResponse
    }),
    [actions.CLAIM_ANALYSIS_OVERRIDE_CONTRACT_REJECTED]: (state, errorMessage) => ({
        ...state,
        overrideContract: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ANALYSIS_SET_COMMENTS]: (state,{newComment}) => ({
        ...state,
        newComment
    }),

    [actions.CLAIM_ANALYSIS_SET_COMMENTS_CONFIRMATION_DIALOG]: (state,claimAnalysisConfirmationDialog) => ({
        ...state,
        claimAnalysisConfirmationDialog
    }),

    [actions.CLAIM_ANALYSIS_GET_COMMENTS_PENDING]: (state) => ({
        ...state,
        getComments: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_GET_COMMENTS_FULFILLED]: (state, comments) => ({
        ...state,
        getComments: {
            ...initialStatusState,
        },
        comments
    }),
    [actions.CLAIM_ANALYSIS_GET_COMMENTS_REJECTED]: (state, errorMessage) => ({
        ...state,
        getComments: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ANAYLYSIS_PROCESSED_CLAIMS_DIALOG]: (state, { processedBulkClaimsDialogOpen }) => ({
        ...state,
        processedBulkClaimsDialogOpen
    }),

    [actions.CLAIM_ANAYLYSIS_UPDATE_STATUS_DIALOG]: (state, { updateClaimStatusDialogOpen }) => (
                 {
        ...state,
        updateClaimStatusDialogOpen
    }),
    [actions.CLAIM_ANALYSIS_GET_CLAIM_ALL_DETAILS_RESET]: (state) => ({
        ...state,
        claimAllDetails: null
    }),

    [actions.CLAIM_ANAYLYSIS_AUDIT_ERROR_RESET]: (state) => ({
        ...state,
        processClaimsFromAudit: {
            ...initialStatusState
        },
    }),

    [actions.SINGLE_CLAIM_ANAYLYSIS_AUDIT_ERROR_RESET]: (state) => ({
        ...state,
        processClaimFromAudit: {
            ...initialStatusState
        },
    }),

    [actions.ESTIMATOR_HISTORY_SET_DETAILS_DIALOG]: (state, { estimatorHistoryDetailsDialog }) => ({
        ...state,
        estimatorHistoryDetailsDialog
    }),
    [actions.CLAIM_ANALYSIS_RESET_LINE_ITEM]: (state) => ({
        ...state,
        detail: []
    }),

    [actions.CLAIM_ANALYSIS_REMOVE_OVERRIDDEN_CONTRACT_PENDING]: (state) => ({
        ...state,
        removeOverriddenContract: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_REMOVE_OVERRIDDEN_CONTRACT_FULFILLED]: (state) => ({
        ...state,
        removeOverriddenContract: {
            ...initialStatusState,
        }
    }),
    [actions.CLAIM_ANALYSIS_REMOVE_OVERRIDDEN_CONTRACT_REJECTED]: (state, errorMessage) => ({
        ...state,
        removeOverriddenContract: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ANALYSIS_GET_STATUS_LIST_PENDING]: (state) => ({
        ...state,
        getClaimStatuses: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_GET_STATUS_LIST_FULFILLED]: (state, claimStatuses) => ({
        ...state,
        getClaimStatuses: {
            ...initialStatusState,
        },
        claimStatuses
    }),
    [actions.CLAIM_ANALYSIS_GET_STATUS_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        getClaimStatuses: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    
    [actions.UPDATE_CLAIM_ANALYSIS_STATUS_LIST_PENDING]: (state) => ({
        ...state,
        updateClaimStatus: {
            pending: true,
        },
    }),
    [actions.UPDATE_CLAIM_ANALYSIS_STATUS_LIST_FULFILLED]: (state, claimStatuses) => ({
        ...state,
        updateClaimStatus: {
            ...initialStatusState,
        }      
    }),
    [actions.UPDATE_CLAIM_ANALYSIS_STATUS_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateClaimStatus: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }      
    }),



    [actions.UPDATE_SINGLE_CLAIM_ANALYSIS_STATUS_PENDING]: (state) => ({
        ...state,
        updateSingleClaimStatus: {
            pending: true,
        },
    }),
    [actions.UPDATE_SINGLE_CLAIM_ANALYSIS_STATUS_FULFILLED]: (state, claimStatuses) => ({
        ...state,
        updateSingleClaimStatus: {
            ...initialStatusState,
        }      
    }),
    [actions.UPDATE_SINGLE_CLAIM_ANALYSIS_STATUS_REJECTED]: (state, errorMessage) => ({
        ...state,
        updateSingleClaimStatus: {
            ...initialStatusState,
            error: true,
            errorMessage,
        }      
    }),

    [actions.CLAIM_ANALYSIS_STATUS_LOGS_LIST_PENDING]: (state) => ({
        ...state,
        getClaimStatusLogs: {
            pending: true,
        },
    }),
    [actions.CLAIM_ANALYSIS_STATUS_LOGS_LIST_FULFILLED]: (state, claimStatusLogs) => ({
        ...state,
        getClaimStatusLogs: {
            ...initialStatusState,
        },
        claimStatusLogs,
    }),
    [actions.CLAIM_ANALYSIS_STATUS_LOGS_LIST_REJECTED]: (state, errorMessage) => ({
        ...state,
        getClaimStatusLogs: {
            ...initialStatusState,
            error: true,
            errorMessage,
        },
    }),

    [actions.CLAIM_ANALYSIS_STATUS_HISTORY_DIALOG]: (state, { claimStatusHistoryDialogOpen }) => ({
        ...state,
        claimStatusHistoryDialogOpen
    }),

    [actions.CLAIM_LOOKUP_VIEW_ID]: (state,lookupId) => ({
        ...state,
        lookupId
    }),

});
