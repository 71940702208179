import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import SettingsEditComponent from './SettingsEditPage';
import { update } from '../../../../store/actions/settings';
import { set,setMulti } from '../../../../store/actions/pages';

export const SettingsEditPage = connect(
    () => {
        return (state, props) => ({
            settingUpdatePending: state.settings.update.pending,
        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            updateSetting: update,
            clearClaimCustomFilter: (value) => set("claimAnalysis-landing", 'customFilter', []),
            setClaimAnalysisFiltersRefetch: obj => setMulti("claimAnalysis-landing", obj),         

        }, dispatch)
    })
)(injectIntl(SettingsEditComponent));