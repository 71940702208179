import { Edit as EditIcon } from '@mui/icons-material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Button, Dialog, DialogContent, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { injectIntl } from "react-intl";
import { useDispatch } from 'react-redux';
import { useFitHeight } from '../../../../_metronic/layout/Layout';
import { TableLevelAction } from '../../../common';
import { Announcement } from '../../../common/Announcement';
import { pageStore, useGlobalStore, XDataGrid } from '../../../common';
import {
    Portlet, PortletBody,
    PortletHeader
} from "../../../partials/content/Portlet";
import { get } from '../../../store/actions/brand';
import { download, setChargeMasterEditDialog, uploadChargeMaster } from '../../../store/actions/chargeMaster';
import { chargeMasterLanding, resetPage, set } from '../../../store/actions/pages';
import { integerEquators, StringEquators } from '../../../store/constants/pages';
import { createCurrencyDataField, createStringDataField, createStringFieldFromMultiKeys } from '../../../util/format';
import { errorToastify } from '../../../util/toast';
import { ChargeMasterDialog } from './ChargeMasterDialog';

const DEFAULT_SORT = [{ id: 'code', direction: 'asc' }];
const DEFAULT_HIDDEN_FIELDS = ['drugTypeOfMeasurement', 'drugUnitOfMeasurement', 'ndc'];    

const { usePageStore, useGridFilters } = pageStore('charge-master-landing', {
    sort: [{ id: 'code', direction: 'asc' }]
})

function ChargeMasterPage({ history, intl }) {
    useFitHeight();

    const dispatch = useDispatch();

    const chargeMasterData = usePageStore( s => {
        return s.list;
    });
    const chargeMasterUploadPending = useGlobalStore( state => state.chargeMaster.upload.pending);
    const chargeMasterDownloadTemplatePending = useGlobalStore(state => state.chargeMaster.download.pending);
    const pending = usePageStore( s => s.pending);

    const brandId = useGlobalStore( s => s.brand.data?.brandId );

    const [refresh, setRefresh] = useState(Promise.resolve());
    const [isPromptDialogOpen, setPromptDialog] = useState(false);

    useEffect(() => {
        const pageName = { name: 'charge-master-landing' };
        dispatch(set(pageName.name, 'waitForCriteriaFilter', false));
        return () => {
            dispatch(resetPage(pageName.name));
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(get());
    }, [dispatch]);

    function handleRefresh() {
        dispatch(set('charge-master-landing', 'list', []));
        setRefresh(Promise.resolve());
    }

    function handlePromptDialogClose() {
        setPromptDialog(false);
    }

    function handleRedirectToPricerSetup() {
        history.push('/pricer/configuration');
    }

    return (
        <>
            <Announcement text='This page (Charge Master) is a dependency for both Pricer and Estimator products' backgroudColor={undefined} />
            <Helmet title="Charge Master" />
            <Portlet>
                <PortletHeader
                    title={'Charge Master'}
                    name="charge-master-landing"
                    showSearchFilters={true}
                    showSearchFiltersDropdown={false}
                    handleRefresh={handleRefresh}
                />
                <PortletBody>
                    <Stack direction="column" spacing={1} sx={{ height: '100%' }}>
                        <GridLevelActions 
                            handleRefresh={handleRefresh}
                            brandId={brandId}
                            dispatch={dispatch}
                            loading={chargeMasterDownloadTemplatePending || chargeMasterUploadPending}
                            setPromptDialog={setPromptDialog}
                        />

                        <ChargeMasterDataGrid 
                            loading={chargeMasterDownloadTemplatePending || chargeMasterUploadPending || pending}
                            data={chargeMasterData}
                            refresh={refresh}
                            dispatch={dispatch}
                        />     
                    </Stack>               
                </PortletBody>
            </Portlet>

            <ChargeMasterDialog handleRefresh={handleRefresh} />

            <Dialog
                fullWidth
                scroll="paper"
                maxWidth="sm"
                open={isPromptDialogOpen}
                aria-labelledby="form-dialog-title">
                <DialogContent className='p-4'>
                    <Grid container className='pt-2'>
                        <Grid>
                            <p>
                                Charge Master has been uploaded. You need to reprocess negotiated rates. Click OK to reprocess now.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container className='justify-content-end'>
                        <Button onClick={handlePromptDialogClose} variant="contained" className="mr-3">
                            Cancel
                        </Button>
                        <Button onClick={handleRedirectToPricerSetup} variant="contained">
                            OK
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    ); 
}

const GridLevelActions = ({ handleRefresh, brandId, dispatch, loading, setPromptDialog }) => {
    function downloadTemplateCallback({ file, fileName }) {
        const displayName = brandId ?? fileName;
        let url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${displayName.replace(/\s/g, '')}_ChargeMaster.csv`;
        a.click();
    }

    const uploadChargeMasterRef = useRef(null);

    function handleFileUpload() {
        uploadChargeMasterRef.current.click();
    }

    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        if (event.target.files.length > 0 && event.target.files[0].type === 'text/csv') {
            setFile(event.target.files[0]);
            dispatch(uploadChargeMaster(event.target.files[0], handleFileUploadCallback, errorCallback));
        } else if (event.target.files.length > 0) {
            errorToastify('Invalid format, please make sure the file is in CSV format.');
        }
    };

    const handleFileUploadCallback = () => {
        setPromptDialog(true);
        setFile(null);
        handleRefresh();
        (document.getElementById('charge-master-upload-input') as HTMLInputElement).value = '';
    };

    const errorCallback = () => {
        setFile(null);
        (document.getElementById('charge-master-upload-input') as HTMLInputElement).value = '';
    };

    function handleAddCharge() {
        dispatch(setChargeMasterEditDialog({ open: true, charge: null, mode: 'create' }));
    }

    return (
        <TableLevelAction 
            handleRefresh={handleRefresh}
            actions={
                () =>
                    <>
                        <Grid item>
                            <Tooltip title="Download Template">
                                <IconButton aria-label="download" size='small'  
                                    onClick={ () =>
                                        dispatch(download(downloadTemplateCallback))
                                    } 
                                    disabled={ loading }
                                >
                                    <GetAppIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Upload Charge Master">
                                <IconButton aria-label="upload" size='small' onClick={handleFileUpload} disabled={ loading }>
                                    <i className="fas fa-upload"></i>
                                    <input
                                        id='charge-master-upload-input'
                                        onInput={handleFileChange}
                                        ref={uploadChargeMasterRef}
                                        type="file"
                                        hidden
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </>
            }
            onAdd={handleAddCharge}
            loading={ loading }
        />          
    )
}

const ChargeMasterDataGrid = ({ refresh, data, loading, dispatch }) => {
    const fields = useMemo( () => [
        createStringDataField('facilityName', 'Facility Name', { sortable: true, equators: StringEquators }),
        createStringDataField('departmentName', 'Department Name', { sortable: true, equators: StringEquators }),
        createStringDataField('code', 'Charge Code', { sortable: true, default: true, equators: StringEquators }),
        createStringDataField('revenueCode', 'Rev Code', { sortable: true, equators: StringEquators }),
        createStringDataField('drgCode', 'DRG Code', { sortable: true, equators: StringEquators }),
        createStringDataField('service', 'Service', { sortable: true, equators: StringEquators }),
        createStringDataField('cpT_HCPCS', 'CPT/HPCS', { sortable: true, equators: StringEquators }),
        createStringDataField('description', 'Description', { sortable: true, equators: StringEquators }),
        createStringFieldFromMultiKeys(['mod1', 'mod2', 'mod3', 'mod4'], 'Modifiers', { hideFromFilter: true, returnWholeRow: true, commaSeparated: true }),
        createStringDataField('drugTypeOfMeasurement', 'Drug Type of Measurement', { sortable: true, equators: StringEquators }),
        createStringDataField('drugUnitOfMeasurement', 'Drug Unit of Measurement', { sortable: true, equators: StringEquators }),
        createStringDataField('ndc', 'NDC', { sortable: true, equators: StringEquators }),
        createStringDataField('gl', 'GL', { sortable: true, equators: StringEquators }),
        createCurrencyDataField('amount', 'Charge', { sortable: true, equators: integerEquators }),
    ], []);

    const tableFilter = useGridFilters();

    const chargeMasterActions = useCallback( props => [
        <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={ () => 
                dispatch( 
                    setChargeMasterEditDialog({ 
                        open: true, 
                        charge: props.row, 
                        mode: 'edit' 
                    })
                )
            }
        />
    ], []);

    return (
        <XDataGrid
            name="charge-master-landing"
            fields={fields}
            loading={loading}
            data={data}
            paginationFilters={tableFilter}
            onLoad={(sort, filter, pagination) =>
                dispatch(chargeMasterLanding(pagination, sort, filter))
            }
            refresh={refresh}
            defaultSort={DEFAULT_SORT}
            criteriasSaveEnabled={true}
            actions={ chargeMasterActions }
            showSearchFilters={true}
            defaultHiddenFields={DEFAULT_HIDDEN_FIELDS}
        />
    )
}

export default injectIntl(ChargeMasterPage);

