import { ROLE_SUPERADMIN, ROLE_PARTNER_ADMIN,ROLE_ADMIN } from '../../constants/roles';
import { createHasCMSClaimAnalysisAccess, createHasCMSAccess, selectSessionHasClaimProcessorAccess,selectHasRCMPageAccess  } from '../session';

export const selectCanAccessClaimAnalysis = createHasCMSClaimAnalysisAccess([ROLE_SUPERADMIN]);
export const selectCanAccessCMS = createHasCMSAccess([ROLE_SUPERADMIN]);
export const selectCanAccessCmsModelling = createHasCMSAccess([ROLE_SUPERADMIN,ROLE_PARTNER_ADMIN,ROLE_ADMIN]);
export const selectCanAccessClaimAudit = selectSessionHasClaimProcessorAccess;
export const selectCanAccessExecutionLogs = selectSessionHasClaimProcessorAccess;
export const selectCanAccessRCMStatus = selectHasRCMPageAccess;





