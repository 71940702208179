import { Box, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useMemo } from "react";
import { useData } from "@pure-ptr/react";
import { pageStore, renderHoverLink, useGlobalStore, XDataGrid } from "../../common";
import { CONTRACTED_SERVICE_INPATIENT, CONTRACTED_SERVICE_OUTPATIENT, CONTRACTED_SERVICE_PROFESSIONAL } from "../../store/constants/contract";
import { StringEquators, integerEquators, dateEquators, BooleanEquators } from "../../store/constants/pages";
import { asDate } from "../../util/date";
import { createClaimAnalysisPatientNameField,createClaimAnalysisSubscriberNameField, createClaimAnalysisPhysicianNameField, createClaimAnalysisStatusField, createClaimNumberFieldWithCopyButton, createCurrencyDataField, createDateField, createIconDataField, createNumberDataField, createPlainDateField, createStringDataField } from "../../util/format";
import { getPlaceOfServiceCodes } from "../../store/api/claimAnalysis";

export const ClaimAnalysisDataGrid = ({ paginationFilters, selectedClaimsIdsPtr, loading, data, handleSingleFlag, handleAcknowledge,showSearchFilters,
    onLoad, refresh,defaultCustomFilter
 }) => {
    
    const columnDefs = useColumnDefinitions();

    const actions = params => [
        <GridActionsCellItem
            icon={
            <Tooltip title={params.row.flag ? 'Unfollow' : 'Follow'}>
                {params.row.flag ? (
                    <i className="fa fa-flag text-danger"></i>
                ) : (
                    <i className="fa fa-flag text-secondary"></i>
                )}
            </Tooltip>
            }
            label="Follow"
            onClick={() => handleSingleFlag(!params.row.flag, params.row)}
        />,

        <GridActionsCellItem
            icon={
            <Tooltip title={params.row.acknowledged ? 'Unacknowledge' : 'Acknowlege'}>
                {params.row.acknowledged ? (
                    <i className="fas fa-check-circle text-success"></i>
                ) : (
                    <i className="fas fa-check-circle text-secondary"></i>
                )}
            </Tooltip>
            }
            label="Acknowledge"
            onClick={() => handleAcknowledge(params.row)}
        />,
    ];

    return <>
        <XDataGrid
            fields={columnDefs}
            actions={actions}

            data={data}
            loading={loading}

            selectedIdsPtr={selectedClaimsIdsPtr}
            showSearchFilters={showSearchFilters}
            paginationFilters={ paginationFilters }

            onLoad={onLoad}
            refresh={refresh}
            defaultCustomFilter={defaultCustomFilter}

            name="claimAnalysis-landing"
            defaultSort={DEFAULT_SORT}
            defaultHiddenFields={DEFAULT_HIDDEN_FIELDS}
            criteriasSaveEnabled={true}
        />
    </>
}

const DEFAULT_SORT = [{ id: 'variance', direction: 'desc' }];
const DEFAULT_HIDDEN_FIELDS = ['payer.name', 'updatedAt', 'recoveredAmount', 'physicianLastname', 'age','patientMrn','patientGender','SnResponsecontactlastororganizationname','subscriberRelationship','subscriberPolicyNumber','subscriberGroupName','remitTraceNumber','snResponsecontactidentifier'];
const { usePageStore } = pageStore('claimAnalysis-landing');

const useColumnDefinitions = () => {
    const tinyContracts = useGlobalStore( x => x.contracts.tiny );
    const recoveredAmountFlag = useGlobalStore( x => x.settings.recoveredAmountFlag );
    const claimStatuses = useGlobalStore( x => x.claimAnalysis.claimStatuses ?? [] );

    const { data:placeOfServiceCodes, isPending, error } = useData( async () => {        
        const placeOfServiceCodes = await getPlaceOfServiceCodes();
        return placeOfServiceCodes
    }, []);
    
    const npiRoles = useGlobalStore( x => 
        x.npiRoles.data?.map(
            item => ({ 
                label: item?.name, 
                value: item?.name, 
                name: item?.name 
            })
        )
    );
    
    const paidStatus = usePageStore( x => 
            x.customFilter?.find((item) => item?.field === "isPaid")
        ),
        isUnpaid = paidStatus?.value === "false";

    return useMemo(() => {
        const fields = [
            createClaimNumberFieldWithCopyButton('claimnumber', 'Claim Number', { returnWholeRow: true, sortable: true, equators: StringEquators, group: 'Claim Information', positionFixed: true, reorder: false, customCell: true, hideable: false  }),
            createStringDataField('renderingnpi', 'NPI Number', { sortable: true, equators: StringEquators, group: 'Claim Information', hideFromGrid: true, reorder: false }),
            createNumberDataField('age', 'Patient Age', { sortable: true, equators: integerEquators, group: 'Claim Information' }),

            createClaimAnalysisPatientNameField('patientLastname', 'Patient Name', { sortable: true, equators: StringEquators, returnWholeRow: true, hideFromFilter: true, width: '100px' }),
            createClaimAnalysisPhysicianNameField('physicianLastname', 'Physician Name', { sortable: true, equators: StringEquators, returnWholeRow: true, hideFromFilter: true, width: '100px' }),

            createStringDataField('patientFirstname', 'Patient First Name', { sortable: true, equators: StringEquators, group: 'Claim Information', hideFromGrid: true, reorder: false }),
            createStringDataField('patientLastname', 'Patient Last Name', { sortable: true, equators: StringEquators, group: 'Claim Information', hideFromGrid: true, reorder: false }),
            createStringDataField('physicianFirstname', 'Physician First Name', { sortable: true, equators: StringEquators, group: 'Claim Information', hideFromGrid: true, reorder: false }),
            createStringDataField('physicianLastname', 'Physician Last Name', { sortable: true, equators: StringEquators, group: 'Claim Information', hideFromGrid: true, reorder: false }),
            createStringDataField('rule.name', 'Rule Name', { sortable: true, equators: StringEquators, secondLevel: 'name', group: 'Charge Information', hideFromGrid: true, reorder: false }),
            createStringDataField('contract.name', 'Contract Name', { 
                sortable: true, 
                equators: StringEquators, 
                secondLevel: 'name', 
                onHover: true, 
                group: 'Claim Information', 
                isDropdown: true, 
                dropdownValues: tinyContracts.map(contract => contract.name), 
                width: '100px',

                renderCell: renderHoverLink(
                    row => [
                        {
                            label: "Name",
                            data: row?.contract?.name,
                        }, {
                            label: "Effective Date",
                            data: asDate(row?.contract?.effectiveDate),
                        }, {
                            label: "Termination Date",
                            data: asDate(row?.contract?.terminationDate),
                        }, {
                            type: "LINK",
                            label: "More Details",
                            data: `/cms/contract/view/${row?.contract?.id}`,
                        },
                    ]
                )
            }),
            
            
            createStringDataField('payer.name', 'Payer Name', { sortable: true, equators: StringEquators, secondLevel: 'name', group: 'Claim Information', width: '100px' }),
            createPlainDateField('servicedate', 'Service Date', { sortable: true, equators: dateEquators, group: 'Claim Information', whiteSpace: 'pre' }),
            createPlainDateField('remitDate', 'Remit Date', { sortable: true, equators: dateEquators, group: 'Claim Information', whiteSpace: 'pre', hideFromFilter: isUnpaid }),
            createDateField('followupAlert', 'Follow Up Date', { hidden: true, equators: dateEquators, group: 'Claim Information' }),
            createStringDataField('visit', 'Visit', { sortable: true, equators: StringEquators, group: 'Claim Information', isDropdown: true, dropdownValues: visitTypes as any }),
            // RCM Fields 
            createStringDataField('patientMrn', 'Patient MRN', { sortable: true, equators: StringEquators, group: 'Claim Information' }),
            createStringDataField('patientGender', 'Patient Gender', { sortable: true, equators: StringEquators, group: 'Claim Information' }),

            createClaimAnalysisSubscriberNameField('SnResponsecontactlastororganizationname', 'Subscriber Full Name', { sortable: true, equators: StringEquators, returnWholeRow: true, hideFromFilter: true, width: '100px' }),

            createStringDataField('subscriberRelationship', 'Subscriber Relationship', { sortable: true, equators: StringEquators, group: 'Claim Information' }),
            createStringDataField('subscriberPolicyNumber', 'Subscriber Policy Number', { sortable: true, equators: StringEquators, group: 'Claim Information' }),
            createStringDataField('subscriberGroupName', 'Subscriber Group Name', { sortable: true, equators: StringEquators, group: 'Claim Information' }),
        
            createStringDataField('remitTraceNumber', 'Remit Trace Number', { sortable: true, equators: StringEquators, group: 'Claim Information' }),
            createStringDataField('snResponsecontactidentifier', 'MemberId', { sortable: true, equators: StringEquators, group: 'Claim Information' }),
            
            createStringDataField('snResponsecontactfirstname', 'Subscriber First Name', { sortable: true, equators: StringEquators, group: 'Claim Information', hideFromGrid: true, reorder: false }),
            createStringDataField('SnResponsecontactlastororganizationname', 'Subscriber Last Name', { sortable: true, equators: StringEquators, group: 'Claim Information', hideFromGrid: true, reorder: false }),
            // RCM Fields 
            createStringDataField('npirolename', 'NPI Roles', { equators: StringEquators, hidden: true, isStrictDropdown: true, dropdownValues: npiRoles, group: 'Claim Information' }),
            createCurrencyDataField('chargedAmount', 'Charged Amount', { sortable: true, equators: integerEquators, group: 'Claim Information', hideFromFilter: isUnpaid }),
            createCurrencyDataField('expectedPay', 'Expected Pay', { sortable: true, equators: integerEquators, group: 'Claim Information' }),
            createCurrencyDataField('actualPay', 'Allowable', { sortable: true, equators: integerEquators, group: 'Claim Information', hideFromFilter: isUnpaid }),
            createCurrencyDataField('variance', 'Variance', { sortable: true, equators: integerEquators, labelStyle: 'label label-lg label-inline', group: 'Claim Information', hideFromFilter: isUnpaid }),
            createStringDataField('drg', 'Drg', { equators: StringEquators, hidden: true, group: 'Claim Information' }),
            createStringDataField('revcode', 'Revenue Code', { equators: StringEquators, hidden: true, group: 'Charge Information' }),
            createStringDataField('pxcode', 'Procedure Code', { equators: StringEquators, hidden: true, group: 'Charge Information' }),
            createStringDataField('reasoncode', 'CAS Code', { equators: StringEquators, hidden: true, group: 'Charge Information', hideFromFilter: isUnpaid }),
            createClaimAnalysisStatusField('status', 'Status', { sortable: false, hideFromFilter:true, returnWholeRow: true, equators: StringEquators, group: 'Claim Information' }),
            createStringDataField('claimStatusId', 'Status',{ equators: BooleanEquators, hidden: true, isStrictDropdown:true, dropdownValues: claimStatuses?.map((item)=> ({label:item.name,value:item.id,name:item.name})), group: 'Claim Information'}),
            createStringDataField('placeOfServiceCode', 'Place Of Service',{ equators: BooleanEquators, hidden: true, isStrictDropdown:true, dropdownValues: placeOfServiceCodes?.map((item)=> ({label:item.name,value:item.code,name:item.code})), group: 'Claim Information'}),
            createStringDataField('billingProviderNpi', 'Billing Provider NPI', { equators: StringEquators, hidden: true, group: 'Claim Information' }),
            createDateField('updatedAt', 'Updated At', { whiteSpace: 'pre', sortable: true, equators: dateEquators, group: 'Charge Information' }),
            createIconDataField('isDenial', 'Denial', { 
                equators: BooleanEquators, 
                isStrictDropdown: true, 
                dropdownValues: denailDropdownValues, 
                icon: "fas fa-times-circle", 
                checkedClass: 'text-danger', 
                group: 'Claim Information', 
                hideFromFilter: isUnpaid,

                renderCell: (params) =>
                    <Box className={`fas fa-times-circle ${ params.value ? 'text-danger' : 'text-secondary' } ml-2 null`} />
            })
        ];

        if (recoveredAmountFlag) {
            const insertIndex = fields.findIndex(field => field.id === 'variance') + 1;
            fields.splice(insertIndex, 0,
                createCurrencyDataField('recoveredAmount', 'Recovered Amount', { sortable: true, equators: integerEquators, labelStyle: 'label label-lg label-inline', group: 'Claim Information', hideFromFilter: isUnpaid })
            );
        }

        return fields;
    }
    , [isUnpaid, claimStatuses, npiRoles, recoveredAmountFlag, tinyContracts ]);
};

const visitTypes = [
    CONTRACTED_SERVICE_INPATIENT,
    CONTRACTED_SERVICE_OUTPATIENT,
    CONTRACTED_SERVICE_PROFESSIONAL
];

const denailDropdownValues = [{
    label: 'Yes',
    value: 'true'
}, {
    label: 'No',
    value: 'false'
}]