import React, { useEffect } from 'react';
import { getPaginationFilter ,getTableSearchFilter} from '../../util/pagination';
import { DataTableHeader } from '../dataTableHeader';
import { Field } from '../dataTableHeader/DataTableHeader';

interface AdvancedTableFiltersProps {
    fields: Field[];
    pageName: string;
    onLoad: (sort: any, filter: any, pagination: any) => void;
    refresh: Promise<void> | null;
    hasCustomFilter: boolean;
    pending: boolean;
    intl: any;
    filter: any[];
    timeFilterField: string | undefined;
    timeFilter: any;
    customFilter: any[];
    sort: any;
    pagination: any;
    criteria: any;
    showSearchFilters: boolean;
    criteriasSaveEnabled: boolean;
    waitForCriteriaFilter: boolean;
    filterData: any; 
    userSessionId?:any;
    actions: {
        setFilter: (filter: any[]) => void;
        setPage: (page: any) => void;
        setCustomFilter: (filter: any[]) => void;
        saveCriteriaFilter: (criteria: any, callback: () => void) => void;
        updateCriteriaFilter: (criteria: any, callback: () => void) => void;
        setSelectedFilter: (filter: any | null) => void;
        searchGetByName: (name: { name: string },callback?: () => void) => void;
        setListData: (data: any[]) => void;
    };
}

export default function AdvancedTableFilters({
    fields, pageName, onLoad, refresh, hasCustomFilter,
    pending, intl,
    filter, timeFilterField, timeFilter, customFilter, sort, pagination,
    criteria, showSearchFilters,
    criteriasSaveEnabled, waitForCriteriaFilter, filterData,userSessionId,
    actions: { setFilter, setPage, setCustomFilter, saveCriteriaFilter, updateCriteriaFilter,
        setSelectedFilter, searchGetByName, setListData }
}: AdvancedTableFiltersProps) {
    const isSummary= window.location.href.includes("fromSummary")
    useEffect(() => {
        if (showSearchFilters) {
            if (waitForCriteriaFilter) {
                return
            }
        }
        if (!refresh) {
            handleRefresh();
            return;
        }
        refresh.then(handleRefresh as any);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh, waitForCriteriaFilter]);

    useEffect(() => {
        sessionStorage.removeItem("duplicated");
    }, [pageName]);

    useEffect(() => {
        var navigationType = (window.performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming)
            .type;
            let filters = sessionStorage.getItem("pages") as any;
            filters = JSON.parse(filters);
        if (
            (navigationType === "back_forward" || 
                navigationType === "reload") &&
            !sessionStorage.getItem("duplicated") && !isSummary //Do not cache if coming from summary Both summary & claim analysis work adjacently
        ) {
            //Handling the persist of filters in both reload and duplication case
            if (filters) {
                setPage(filters[pageName]);      
            }
            sessionStorage.setItem("duplicated", "true");
        }
        sessionStorage.setItem("pages", JSON.stringify({...filters, [pageName]: {...filterData,searchTerm:undefined} }));
        
    }, [filterData]);


    function handleLoad( { newSort, newFilter, newTimeFilter, newPagination } : {
        newSort?: any, newFilter?: any, newTimeFilter?: any, newPagination?: any
    } = {}) {
        const paginationFilters = getPaginationFilter( newFilter || filter, timeFilterField, newTimeFilter || timeFilter, customFilter.filter(item => item.value !== 'both'));
         if (!hasCustomFilter) {
            setCustomFilter(customFilter);
        }
        const quickSearch = getTableSearchFilter(filterData?.searchTerm)
    
        onLoad(
            newSort || sort,
            filterData?.searchTerm?.length > 0
                ? filterData?.ignoreAcknowledgement
                    ? quickSearch
                    : {
                          ...( paginationFilters ?? [] ),
                          operands: [
                              ...( paginationFilters?.operands ?? []),
                              ...quickSearch?.operands,
                          ],
                      }
                : paginationFilters,
            newPagination || pagination
        );
    }



    function handleRefresh(hardRefresh?: boolean) {
       setListData([]);
       if (!pending || hardRefresh || sessionStorage.getItem('duplicated')) {
            handleLoad({
                newPagination: pagination,
                newSort: sort,
            });
        }
    }


    function handleFilter(newFilter,isAddFilter) {
        if (!pending) {
            if (!isAddFilter) { setListData([]); }
            
            const mergedFilter =
                !newFilter?.equator && !newFilter?.field && !newFilter?.value
                    ? [...filter]
                    : [...filter, newFilter];
                
            setFilter(mergedFilter);
            if(!isAddFilter)
            {handleLoad({
                newFilter: mergedFilter,
                newPagination: {
                    ...pagination,
                    currentPage: 1,
                }
            });}
        }
    }

    function handleDeleteFilter(deletedFilter, index) {
        setListData([]);
        filter.splice(index, 1);
        setFilter(filter);

        handleLoad({
            newFilter: filter,
        });

        // // clear groups when filters are none
        // if(filter.length === 0) {
        //     resetGroups();
        // }
    }

    function handleUpdateFilter(newFilterObj, index) {
        if (index !== null) {
            setListData([]);
            filter[index] = newFilterObj;
            setFilter(filter);
            handleLoad({
                newFilter: filter,
            });
        }
    }

    function handleFilterClear() {
        // if (filter && filter.length > 0) { //Even if crieria has no filters we should still be able to clear
            setListData([]);
            setFilter([]);
            setSelectedFilter(null);
            handleLoad({
                newFilter: [],
            });
            
        if (pageName === "claimAnalysis-landing") {
            localStorage.setItem("isCriteriaCleared", "true")

        }
        // }
    }


    function refreshPageCriterias() {
        const pName = {
            name: pageName,
        }
        searchGetByName(pName,handleRefresh);
    }

    function SaveFilterCallback(handleClose) {
        handleClose();
        refreshPageCriterias();

    }

    function handleFilterSave(filterCriteria, handleClose, existingCriteria) {
        let searchCriteria = {
            ...filterCriteria,
            name: pageName,
        }
        if (existingCriteria && existingCriteria.id) {
            updateCriteriaFilter(searchCriteria, () => {
                SaveFilterCallback(handleClose)
            });
        } else {
            
            saveCriteriaFilter(searchCriteria, () => {
                SaveFilterCallback(handleClose)
            });
        }
    }

    return (
            <DataTableHeader
                fields={fields}
                intl={intl}
                filter={filter}
                customFilter={customFilter}
                onFilterChange={handleFilter}
                onFilterDelete={handleDeleteFilter}
                onFilterClear={handleFilterClear}
                onFilterSave={handleFilterSave}
                onFilterUpdate={handleUpdateFilter}
                criteria={criteria}
                saveEnabled={criteriasSaveEnabled}
                pending={pending}
                pageName={pageName}
                userSessionId={userSessionId}
            />
    );
};                   