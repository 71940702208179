import { Button, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { Helmet } from "react-helmet";
import { toAbsoluteUrl } from "../../../../_metronic";
import { Portlet, PortletBody } from "../../../partials/content/Portlet";

const useStyles = makeStyles(theme => ({
  rowGrid: {
    gap: '25px',
    paddingTop: '20px'
  },
  unauthorizedIcon: {
    height: '150px'
  },
  textGrid: {
    paddingTop: '45px',
    flex: '1 1 0%',
    maxWidth: 'max-content'
  },
  portlet: {
    height: 'calc(100% - 12px)'
  },
  heading: {
    fontSize: "20px",
    fontWeight: 'bold'
  },
  text: {
    fontSize: "15px"
  }
}));

export default function UnauthorizedPage({ user, history,selectedClient,isClient=false,intl }) {

  const classes = useStyles();

  const handleRedirect = () => {
    history.goBack();
  }

  return (<>
    <Helmet title="Unauthorized" />
    <Portlet className={classes.portlet}>
      <PortletBody>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={classes.rowGrid}>
              <Grid item>
                <img className={classes.unauthorizedIcon} alt="Unauthorized" src={`${toAbsoluteUrl("/media/error/unauthorized.png")}`} />
              </Grid>
              <Grid item className={classes.textGrid}>
                <span className={classes.heading}>Access Denied </span>
                <Grid>
                  <span className={classes.text}>{isClient  ? 'Client':'User'} '{isClient ?selectedClient: user?.name}' does not have the required permission to access this resource.</span>
                </Grid>
                <Grid className="text-right pt-2">
                  {!isClient && <Button onClick={handleRedirect} variant='contained'>
                    Back
                  </Button>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PortletBody>
    </Portlet>
  </>);
}
