/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from 'react-ga';
import storeCofiguration from "./app/store";
import { fromLocalStorage, toLocalStorage } from "./app/util/storage";
import App from "./App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import { SESSION_CONSTRUCT, SESSION_LOGIN_FULFILLED } from "./app/store/actions/session";
import { ENV_PRODUCTION } from './app/store/constants/env';
import * as serviceWorker from './serviceWorker';
import './app/util/faro'
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_KEY);

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

let authToken = fromLocalStorage('authToken', null);

if (authToken) {
  const { store } = storeCofiguration;
  store.dispatch({ type: SESSION_LOGIN_FULFILLED, payload: { authToken } });
  store.dispatch({ type: SESSION_CONSTRUCT, payload: {} });
}
else {
  toLocalStorage('authToken', null);
  authToken = undefined;
}

if (process.env.NODE_ENV === ENV_PRODUCTION) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_CODE, { gaOptions: { userId: 'Unknown User' } });
}

const Layout = React.lazy(() => import("./_metronic/layout/Layout"));

ReactDOM.render(
  <App
    store={storeCofiguration.store}
    Layout={Layout}
    persistor={storeCofiguration.persistor}
    basename={PUBLIC_URL}
  />,
  document.getElementById("root")
);

serviceWorker.unregister();