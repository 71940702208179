import React, { useState } from 'react';
import { Grid, Button, TextField, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Edit as EditIcon } from '@mui/icons-material';
import { SEARCH_PERIOD_ID, SETTING_DATA_TYPE_DECIMAL, SETTING_DATA_TYPE_STRING } from '../../../../store/constants/settings';
import { useEffect } from 'react';
import { searchPeriodsMap } from '../../../../store/constants/date';


const booleanStrings={true:'Yes',True:'Yes',false:'No',False:'No'};

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    button: {
        padding: 0,
        minWidth: 'auto',
        marginLeft: '15px'
    },
    w90: {
        width: '90%'
    }
}));

export default function SettingsEditPage(props) {

    const {
        setting,
        settingUpdatePending,
        actions: { updateSetting, clearClaimCustomFilter,setClaimAnalysisFiltersRefetch }
    } = props;

    const classes = useStyles();
    const [settingValue, setSettingValue] = useState('');
    const [minValue, setMinValue] = useState('');
    const [maxValue, setMaxValue] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [isValueChanged, setIsValueChanged] = useState(false);

    useEffect(() => {
        if (setting?.isRange) {
            const splitedValue = setting.value.split(';');
            setMinValue(splitedValue[0] ?? '');
            setMaxValue(splitedValue[1] ?? '');
        }
    }, [setting]);

    const valueChangeHandler = (e) => {
        setSettingValue(e.target.value);
        setIsValueChanged(true);
    }

    const handleMinChange = (e) => {
        setIsValueChanged(true);
        const temp = parseFloat(e.target.value);
        if (isNaN(temp)) {
            setMinValue('');
        } else if (temp < 1) {
            setMinValue(temp)
        }
    }

    const handleMaxChange = (e) => {
        setIsValueChanged(true);
        const temp = parseFloat(e.target.value);
        if (isNaN(temp)) {
            setMaxValue('');
        } else if (temp >= 0) {
            setMaxValue(temp)
        }
    }

    const submitHandler = (e) => {
        const { handleCallBack } = props
        e.preventDefault();
        setClaimAnalysisFiltersRefetch({waitForCriteriaFilter:true,isSearchApiCompleted:false})
        clearClaimCustomFilter();

        let updatedValue = settingValue;
        if (setting?.isRange) {
            updatedValue = `${minValue};${maxValue}`;
        }
        updateSetting(setting.id, updatedValue, setting.parentId, handleCallBack)
    }

    const handleModeChange = (bool) => {
        if (bool) {
            setSettingValue((setting?.id === SEARCH_PERIOD_ID) ? setting.value : setting.value.toLowerCase());
        }
        setIsEdit(bool);
    }


    function renderSelectOptionField() {
        return <TextField
            select
            fullWidth
            label={setting?.name}
            className={classes.formControl}
            value={settingValue}
            onChange={valueChangeHandler}
            SelectProps={{
                MenuProps: { disablePortal: true }
            }}
        >
            {(JSON.parse(setting?.options)).map(item => <MenuItem key={item.id} value={(setting?.id === SEARCH_PERIOD_ID) ? item.id : item.id.toLowerCase()}>{item.value}</MenuItem>)}
        </TextField >
    }

    function renderRangeField() {
        return <Grid container>
            <Grid item xs={6}>
                <TextField
                    type={setting?.dataType === SETTING_DATA_TYPE_DECIMAL ?
                        'number' : setting?.dataType === SETTING_DATA_TYPE_STRING ?
                            'text' :
                            'date'
                    }
                    className={classes.w90}
                    required
                    label="Enter Min value"
                    name="settingValue"
                    value={minValue}
                    onChange={handleMinChange}
                />
            </Grid>
            <Grid item xs={6} className='text-right'>
                <TextField
                    type={setting?.dataType === SETTING_DATA_TYPE_DECIMAL ?
                        'number' : setting?.dataType === SETTING_DATA_TYPE_STRING ?
                            'text' :
                            'date'
                    }
                    className={classes.w90}
                    required
                    label="Enter Max value"
                    name="settingValue"
                    value={maxValue}
                    onChange={handleMaxChange}
                />
            </Grid>
        </Grid>
    }

    function renderSimpleField() {
        return <TextField
            type={setting?.dataType === SETTING_DATA_TYPE_DECIMAL ?
                'number' : setting?.dataType === SETTING_DATA_TYPE_STRING ?
                    'text' :
                    'date'
            }
            fullWidth
            required
            label="Enter new value"
            name="settingValue"
            value={settingValue}
            onChange={valueChangeHandler}
        />
    }

    function renderValueField() {
        if (setting?.isRange) {
            return renderRangeField();
        } else if (setting?.options && setting?.options.length > 0) {
            return renderSelectOptionField();
        } else {
            return renderSimpleField();
        }
    }

    function renderValueText(value) {
        if (setting?.id === 4) {
            return value === 'remitdate' ? 'Remit Date' : 'Service Date';
        } else if (setting?.id === SEARCH_PERIOD_ID) {
            return searchPeriodsMap[value];
        }
          else if(value in booleanStrings){
            return booleanStrings[value]
        }
        else {
            return value;
        }
    }

    function checkDisable() {
        if (setting?.isRange) {
            return settingUpdatePending || !isValueChanged || minValue.length === 0 || maxValue.length === 0;
        }
        return settingUpdatePending || !isValueChanged || settingValue.length === 0;
    }

    return (<>
        {isEdit ?
            <Grid item xs={12}>
                <form onSubmit={submitHandler}>

                    {renderValueField()}

                    <Grid container justifyContent='flex-end' className='pt-2'>
                        <Button
                            size="small"
                            onClick={() => handleModeChange(false)}
                            type="button"
                            variant="contained">
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            type="submit"
                            className='ml-2'
                            disabled={checkDisable()}
                        >
                            {(settingUpdatePending) ? 'Saving..' : 'Save'}
                        </Button>
                    </Grid>
                </form>
            </Grid >
            :
            <>

                <span className="ml-3">{renderValueText(setting.value)}</span>
                <Button onClick={e => handleModeChange(true)} className={classes.button}>
                    <EditIcon className="text-primary" />
                </Button>
            </>
        }
    </>);
}