import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from '../actions/contractsv2';
import * as API from '../api/contractsv2';
import { sessionErrorHandling } from './session';

function* getContractsV2(action) {
    try {
        const { directoryId,isFinalized } = action.payload;
        yield put({ type: actions.CONTRACTSV2_GET_PENDING });
        const payload = yield call(API.getContractsV2, directoryId,isFinalized);
        yield put({ type: actions.CONTRACTSV2_GET_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTSV2_GET_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}


function* getHierarchy(action) {
    try {
        yield put({ type: actions.CONTRACTSV2_HIERARCHY_PENDING });
        const payload = yield call(API.getHeirarchy);
        yield put({ type: actions.CONTRACTSV2_HIERARCHY_FULFILLED, payload });
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTSV2_HIERARCHY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* createDirectory(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.CONTRACTSV2_CREATE_DIRECTORY_PENDING });
        const payload = yield call(API.createDirectory, options);
        yield put({ type: actions.CONTRACTSV2_CREATE_DIRECTORY_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTSV2_CREATE_DIRECTORY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}
function* updateDirectory(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.CONTRACTSV2_UPDATE_DIRECTORY_PENDING });
        const payload = yield call(API.updateDirectory, options);
        yield put({ type: actions.CONTRACTSV2_UPDATE_DIRECTORY_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTSV2_UPDATE_DIRECTORY_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* getContractsDirectoryDependencies(action) {
    try {
        const { directoryId } = action.payload;
        yield put({ type: actions.CONTRACTSV2_DIRECTORY_DELETE_DEPENDENCIES_PENDING });
        const payload = yield call(API.getContractsV2DirectoryDependencies, directoryId);
        yield put({ type: actions.CONTRACTSV2_DIRECTORY_DELETE_DEPENDENCIES_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTSV2_DIRECTORY_DELETE_DEPENDENCIES_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* deleteContractsDirectory(action) {
    try {
        yield put({ type: actions.CONTRACTSV2_DIRECTORY_DELETE_PENDING });
        const {
            directoryId,
            callback,
        } = action.payload;
        yield call(API.deleteContractsV2ScheduleDirectory, directoryId);
        yield put({ type: actions.CONTRACTSV2_DIRECTORY_DELETE_FULFILLED });
        if (callback) {
            callback();
        }
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTSV2_DIRECTORY_DELETE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}

function* moveDirectoryOrContracts(action) {
    try {
        const { options, callback } = action.payload;
        yield put({ type: actions.CONTRACTSV2_MOVE_PENDING });
        const payload = yield call(API.moveDirectoryOrContracts, options);
        yield put({ type: actions.CONTRACTSV2_MOVE_FULFILLED, payload });
        if (callback) {
            callback();
        }
    }
    catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTSV2_MOVE_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}



function* contractsSearch(action) {
    try {
        const { options } = action.payload;
        yield put({ type: actions.CONTRACTSV2_SEARCH_PENDING });
        const payload = yield call(API.searchContracts, options);
        yield put({ type: actions.CONTRACTSV2_SEARCH_FULFILLED, payload });
    } catch (error) {
        const { error: errorMessage } = (error && error.payload) || { error: '' };
        yield put({ type: actions.CONTRACTSV2_SEARCH_REJECTED, payload: errorMessage });
        yield call(sessionErrorHandling, error);
    }
}


export default function* () {
    yield takeEvery(actions.CONTRACTSV2_GET, getContractsV2);
    yield takeEvery(actions.CONTRACTSV2_HIERARCHY, getHierarchy);
    yield takeEvery(actions.CONTRACTSV2_CREATE_DIRECTORY, createDirectory);
    yield takeEvery(actions.CONTRACTSV2_UPDATE_DIRECTORY, updateDirectory);
    yield takeEvery(actions.CONTRACTSV2_DIRECTORY_DELETE_DEPENDENCIES, getContractsDirectoryDependencies);
    yield takeEvery(actions.CONTRACTSV2_DIRECTORY_DELETE, deleteContractsDirectory);
    yield takeEvery(actions.CONTRACTSV2_MOVE, moveDirectoryOrContracts);
    yield takeEvery(actions.CONTRACTSV2_SEARCH, contractsSearch);
  
}