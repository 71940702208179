import {
    METHOD_GET,
    SERVER_URL,
    METHOD_POST
} from '../constants/api';
import { headersAuthReceiveJson, headersAuthSendReceiveJson } from '../headers';
import { apiHandleResponse } from '../../util/api';

// mode = daily/monthly
const getStatistics = (frequency, clientId, state) => {
    let url = `${SERVER_URL}/api/v2/statistics/?frequency=${frequency}`;
    if (clientId) {
        url = `${SERVER_URL}/api/v2/statistics/?frequency=${frequency}&clientId=${clientId}`;
    }
    if (state) {
        url = `${url}&state=${state}`
    }
    return fetch(url, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getFiles = () => {
    return fetch(`${SERVER_URL}/api/v1/statistics/files/date`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getRemitsAndPayersStatistics = (frequency,schema) => {
    return fetch(`${SERVER_URL}/api/v2/statistics/claimsandremits?frequency=${frequency}&schema=${schema}`, {
        method: METHOD_GET,
        headers: headersAuthReceiveJson(),
    }).then(apiHandleResponse);
};

const getAnalysisStats = (options) => {
    return fetch(`${SERVER_URL}/api/v2/statistics/analysis`, {
        method: METHOD_POST,
        headers: headersAuthSendReceiveJson(),
        body: JSON.stringify(options),
    }).then(apiHandleResponse);
};

export {
    getStatistics,
    getFiles,
    getRemitsAndPayersStatistics,
    getAnalysisStats
};