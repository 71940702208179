import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { injectIntl } from "react-intl";
import { setOpenAdvanceFiltersDialog } from '../../store/actions/pages';
import DataTableHeaderComponent, { Field } from './DataTableHeader';

export const DataTableHeader : React.FC<{
    fields: Field[];
    intl: any;
    onFilterChange: (newFilter: any, isAddFilter: boolean) => void;
    onFilterDelete: (f: any, index: number) => void;
    pending: boolean;
    customFilter: any[];
    pageName: string;
    onFilterClear: () => void;
    userSessionId?:string;
    filter: any[];
    onFilterSave: (criteria: any, handleClose: () => void, existingCriteria: any) => void;
    filterSaveState?: any;
    criteria: any;
    saveEnabled: boolean; 
    onFilterUpdate: (newFilterObj: any, updatingFieldIndex: number | null) => void;
}> = connect(
    () => {
        const selectSignificance = createSelector(
            (state, props) => props.pageName,
            (state) => state.pages,
            (name, pages) => {
                if (name === 'claimAnalysis-landing') {
                    return pages[name].significance;
                }
                return false
            }
        );

        return (state:any, props) => ({
            significance: selectSignificance(state, props),
            openFiltersPanel: state.pages.open,
            anchorEl: state.pages.anchorEl,
            filtersAnchorEl: state.pages.filtersAnchorEl,

        });
    },
    (dispatch, props) => ({
        actions: bindActionCreators({
            setOpenAdvanceFiltersDialog
        }, dispatch)
    })
)(injectIntl(DataTableHeaderComponent as any));