import { GridColDef, GridPreProcessEditCellProps, GridValueGetter } from "@mui/x-data-grid-pro";
import { BooleanEquators, dateEquators, integerEquators, StringEquators } from "../../store/constants/pages";
import { Field } from "../dataTableHeader/DataTableHeader";

type ExtendColumnDef = Partial<GridColDef> & { renderString: 'renderCell' | 'valueFormatter' | 'valueGetter' | false, valueOptions?: any, valueSetter?: ( value : any ) => any };

export interface HoverColumn {
    label: string;
    data: string;
    type?: string;
}

export function translateLegacyFields( fields : any[] ) : GridColDef[]{
    const unknown = fields.filter( field => !field.xDataField );

    if( unknown.length > 0 ){
        console.error( 'Can\'t translate legacy fields:', unknown );
    }

    return fields
        .filter( x => x.xDataField && !x.options.hideFromGrid && !x.options.hidden )
        .map( x => x.xDataField );
}

export function transformOptions( columnDef : Field, extend : ExtendColumnDef ) : Field {
    columnDef.xDataField = {
        field: columnDef.id,
        headerName: columnDef.label
    }

    const { xDataField } = columnDef;

    // If no type is provided, infer it from the equators
    const { equators } = columnDef.options;
    xDataField.type = extend?.type ?? (
        equators === StringEquators ? 'string' :
        equators === integerEquators ? 'number' :
        equators === BooleanEquators ? 'boolean' :
        equators === dateEquators ? 'date' : 
        'string'
    )

    // Handle nested fields a.b.c
    xDataField.valueGetter = createValueGetter( 
        xDataField.field, 
        extend.renderString === 'valueGetter' ? 
            ( value, row ) => columnDef.renderString( columnDef.options.returnWholeRow? row : value ) :
            extend?.valueGetter 
    );

    xDataField.valueSetter = (a_value, row) => {
        const value = extend.valueSetter ? extend.valueSetter( a_value ) : a_value;
        const attrs = xDataField.field.split('.'),
            simpleField = attrs.length === 1;

        if( simpleField ){
            return { ...row, [xDataField.field] : value };
        }
        else {
            // purely functional way to update nested fields
            const update = ( obj, attrs, value ) => {
                if( attrs.length === 1 ){
                    return { ...obj, [attrs[0]] : value };
                }
                else {
                    return { ...obj, [attrs[0]] : update( obj[attrs[0]], attrs.slice(1), value ) };
                }
            }

            return update( row, attrs, value );
        }
    }

    if( columnDef.renderString ){
        if( extend.renderString === undefined || extend.renderString === 'renderCell' ){
            xDataField.renderCell = ( params ) => columnDef.renderString( columnDef.options.returnWholeRow? params.row : params.value );
        }
        else if( extend.renderString === 'valueFormatter'){
            xDataField.valueFormatter = ( value, row ) => columnDef.renderString( columnDef.options.returnWholeRow? row : value );
        }
    }

    if( extend.renderCell || columnDef.options.renderCell ){
        xDataField.renderCell = columnDef.options.renderCell ?? extend.renderCell;
    }

    if( !xDataField.renderCell && xDataField.type === 'number' ){
        if( !columnDef.options.totals ){
            xDataField.renderCell = ( params ) => params.row.id === 'totals' ? '' : params.formattedValue;
        }
        else {
            xDataField.renderCell = ( params ) => 
                params.row.id === 'totals' ? (
                    params.value ? params.formattedValue : '-'
                ) : params.formattedValue;
        }
    }

    if( extend.valueFormatter ){
        xDataField.valueFormatter = extend.valueFormatter;
    }

    if( columnDef.options.width ){
        const width = columnDef.options.width;
        
        if( !isNaN(width) ){
            xDataField.width = width;
        }
    }

    if( columnDef.options.validate ){
        xDataField.preProcessEditCellProps = (params: GridPreProcessEditCellProps) => {
            const error = columnDef.options.validate( params.props.value );
            return { ...params.props, error };
        }
    }

    if( typeof columnDef.options.editable === 'boolean' ){
        xDataField.editable = columnDef.options.editable ;
    }

    xDataField.sortable = columnDef.options.sortable ?? true;
    xDataField.disableReorder = !columnDef.options.reorder;
    xDataField.valueOptions = extend.valueOptions;

    xDataField.hideable = columnDef.options.hideable;

    return columnDef as any;
}

function createValueGetter( field : string, getter? : GridValueGetter ) : GridValueGetter {
    const attrs = field.split('.'),
        simpleField = attrs.length === 1;

    if( simpleField ){
        return getter || (( value, row ) => row[field] );
    }

    return getter ? 
        ( value, row ) => (getter as any )( attrs.reduce( ( acc, x ) => acc?.[x], row)) :
        ( value, row ) => attrs.reduce( ( acc, x ) => acc?.[x], row);
}

 