import { PureObject } from '@pure-ptr/core';
import { apiHandleOctetResponse, apiHandleResponse } from '../../util/api';
import {
    METHOD_DELETE,
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT,
    SERVER_URL
} from '../constants/api';
import { headersAuthReceiveJson, headersAuthSendReceiveJson, headersAuthSendReceiveOctet } from '../headers';

export const http = {
    get: async (url : string, query? : Record<string,string>, contentType : 'octet-stream' | 'json' = 'json'  ) => {
        const queryString = query ? '?' + new URLSearchParams(query).toString() : '';

        const response = await fetch( SERVER_URL + url + queryString, {
            method: METHOD_GET,
            headers:contentType === 'octet-stream' ? headersAuthSendReceiveOctet() : headersAuthReceiveJson(),
        });
        return contentType === 'octet-stream' ? apiHandleOctetResponse(response) : apiHandleResponse(response);
    },

    post: async (url : string, payload : any, contentType : 'octet-stream' | 'json' = 'json' ) => {
        const response = await fetch( SERVER_URL + url, {
            method: METHOD_POST,
            headers: contentType === 'octet-stream' ? headersAuthSendReceiveOctet() : headersAuthSendReceiveJson(),
            body: JSON.stringify(payload),
        });
        return contentType === 'octet-stream' ? apiHandleOctetResponse(response) : apiHandleResponse(response);
    },

    put: async (url : string, payload : any ) => {
        const response = await fetch( SERVER_URL + url, {
            method: METHOD_PUT,
            headers: headersAuthSendReceiveJson(),
            body: JSON.stringify(payload),
        });
        return apiHandleResponse(response);
    },

    del : async (url : string ) => {
        const response = await fetch( SERVER_URL + url, {
            method: METHOD_DELETE,
            headers: headersAuthReceiveJson(),
        });
        return apiHandleResponse(response);
    }
}

export function restEndpoint<T extends typeof PureObject & { parse? : (x:any) => any }>( Ctor : T, root : string ){
    return {
        find : async ( query? : Record<string,string> ) => 
            Ctor.array( await http.get( root, query ), Ctor.parse ),

        post : async ( payload : InstanceType<T> ) => {
            const response = await http.post( root, payload );
            return payload.withChanges( Ctor?.parse( response ) ?? response );
        },
            
        put : async ( id : string | number, payload : InstanceType<T>  ) => {
            const response = await http.put( root + '/' + id , payload );
            return payload.withChanges( Ctor?.parse( response ) ?? response );
        },

        get : async ( id : string | number ) => {
            const response = await http.get( root + '/' + id );
            return Ctor.object( Ctor?.parse( response ), Ctor.parse );
        },

        del : async ( id : string | number ) => http.del( root + '/' + id ),
    }
}