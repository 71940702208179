import React, { useState } from 'react';
import { IconButton, Grid, Card, CardContent, Typography, CircularProgress, Table, TableBody, TableRow, TableCell, TableHead, Link, TableFooter, Box, Collapse, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Helmet from 'react-helmet';
import { useData, useStatePtr } from "@pure-ptr/react";

import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar,
} from "../../partials/content/Portlet";
import { ContentRow } from '../../common';
import { getCasCodesSummary, getClaimsSummary, getUnderpaymentsSummary } from "../../store/api/summaryDashboard";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { asDollars } from '../../util/format';
import { DateRangePicker } from '../../common/dateRangeControl';
import { getLast30Days } from '../../util/date';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    borderNone: {
        border: "none",
    },
    greenBg: {
        backgroundColor: "#CDE8D7",
        padding: "10px"
    },
    blueBg: {
        backgroundColor: "#CCE7F6",
        padding: "10px"
    },
    redBg: {
        backgroundColor: "#FFC1C1",
        padding: "10px"
    },
    card: {
        borderRadius: 8,
        "& .MuiCardContent-root:last-child" : {
            paddingBottom: 0,
        }
    },
    cardContent: {
        padding: 0,
        "& root:last-child" : {
            paddingBottom: 0,
        }
    },
    greenHeaderRow: {
        "& .MuiTableCell-root" : {
            fontWeight: 600,
            height: 40,
            backgroundColor: "#D9F6E3",
        }
    },

    totalsRow: {
        "& .MuiTableCell-root" : {
            fontWeight: 800,
            borderStyle: 'double',
            borderWidth: '4px 0 0 0',
            borderColor: 'gray',
        }
    },
    link: {
        fontWeight: 800, 
        color: "#1E88E5",
        textDecoration: "underline",
        cursor: "pointer"
    }
}));

export default function ClaimSummaryDashboard({ history, actions: { setMulti } }) {

    const classes = useStyles();

    const searchPeriods = [
        { id: "Last7Days", value: "Last 7 Days" },
        { id: "Last30Days", value: "Last 30 Days" },
        { id: "Last90Days", value: "Last 90 Days" },
        { id: "Last6Months", value: "Last 6 Months" },
        { id: "Last12Months", value: "Last 12 Months" }
    ];
    const dateRangePtr = useStatePtr( getLast30Days );
    const [open, setOpen] = useState({});

    const { data: casData, isPending: casLoading, error: casError } = useData( async () => {        
        const result = await getCasCodesSummary(dateRangePtr.value.relativeFilter.toLowerCase());
        return result
    }, [dateRangePtr.value]);

    const { data: claimsData, isPending: claimsLoading, error: claimsError } = useData( async () => {        
        const result = await getClaimsSummary(dateRangePtr.value.relativeFilter.toLowerCase());
        return result
    }, [dateRangePtr.value]);

    const { data: underpaymentsData, isPending: underpaymentsLoading, error: underpaymentsError } = useData( async () => {        
        const result = await getUnderpaymentsSummary(dateRangePtr.value.relativeFilter.toLowerCase());
        return result
    }, [dateRangePtr.value]);

    const handleUnderpaymentsRowClick = (contractName) => {
        setOpen((prevState) => ({
            ...prevState, 
            [contractName]: !prevState[contractName],
        }));
    }

    const getTotalCasCodes = () => {
        let total = casData?.slice(0, 10).reduce((sum, item) => sum + (item.amount || 0), 0); 
        return total;
    }

    const handleCasCodeAmountClick = (ids) => {
        sessionStorage.removeItem("duplicated");
        let filters = [
            {
                actualFilter: true, 
                equator: "equals", 
                field: "reasoncode", 
                value: ids
            },
            {
                actualFilter: true, 
                equator: "not_equal_to", 
                field: "variance", 
                value: 0
            }
        ]
        setMulti({
            filter: filters,
            selectedFilter: null,
            customFilter: [
                {
                    customFilter: true, 
                    equator: "greater_than_or_equals_to", 
                    field: "remitdate", 
                    value: dateRangePtr?.value?.startdate,
                    relativeFilter:dateRangePtr?.value?.relativeFilter

                },
                {
                    customFilter: true, 
                    equator: "less_than_or_equals_to", 
                    field: "remitdate", 
                    value: dateRangePtr?.value?.enddate,
                    relativeFilter:dateRangePtr?.value?.relativeFilter
                }
            ],
            list: [],
            significance: false,
            searchTerm:"",
            ignoreAcknowledgement:false,
            showIgnoredFilterDialog:false
        });
        history.push("/cms/claimAnalysis/fromSummary");
    }

    const handleClaimSummaryAmountClick = (id) => {
        history.push(`/cms/claimAnalysis/view/${id}`);
    }

    const handleUnderpaymentsAmountClick = (contractName, isDenial, billingProviderNpi = null) => {
        sessionStorage.removeItem("duplicated");
        let filters = [
            {
                actualFilter: true, 
                equator: "not_equal_to", 
                field: "variance", 
                value: 0
            },
            {
                actualFilter: true, 
                equator: "equals", 
                field: "contract.name", 
                value: contractName
            }
        ];
        if(billingProviderNpi) {
            filters.push({
                actualFilter: true, 
                equator: "equals", 
                field: "billingProviderNpi", 
                value: billingProviderNpi
            });
        }
        setMulti({
            filter: filters,
            selectedFilter: null,
            customFilter: [
                {
                    customFilter: true, 
                    equator: "greater_than_or_equals_to", 
                    field: "remitdate", 
                    value: dateRangePtr?.value?.startdate,
                    relativeFilter:dateRangePtr?.value?.relativeFilter
                },
                {
                    customFilter: true, 
                    equator: "less_than_or_equals_to", 
                    field: "remitdate", 
                    value: dateRangePtr?.value?.enddate,
                    relativeFilter:dateRangePtr?.value?.relativeFilter
                },
                {
                    customFilter: true, 
                    equator: "equals", 
                    field: "isDenial", 
                    value: isDenial
                }
            ],
            list: [],
            significance: false,
            searchTerm:"",
            ignoreAcknowledgement:false,
            showIgnoredFilterDialog:false
        });
        history.push("/cms/claimAnalysis/fromSummary");
    }

    return (
        <>
            <Helmet title="Summary Dashboard" />
            <Portlet>
            
            <PortletHeader
                toolbar={
                    <PortletHeaderToolbar fullWidth>
                        <ContentRow spacing={2}
                        >
                            <Box display="flex" width={200}>
                                <DateRangePicker
                                    disabled={false}
                                    valuePtr={dateRangePtr} 
                                    label="Date Range"
                                    enabledSwitch={false} 
                                    relativeFilterOptions={searchPeriods}
                                    disableDateSection = {true}
                                />
                            </Box>
                        </ContentRow>
                    </PortletHeaderToolbar>
                }
            />
                <PortletBody>
                <Grid container spacing={2} style={{ padding: 16 }}>

                    <Grid item xs={12} md={7}>
                        <Grid container>
                            {/* Top 5 Claims */}
                            <Grid item xs={12} md={12}>
                            <Card className={classes.card}>
                                    <CardContent className={classes.cardContent}>
                                        <Typography className={classes.redBg} variant="h6" gutterBottom>
                                        Top 5 Claims
                                        </Typography>
                                        {claimsLoading ? (
                                        <CircularProgress />
                                        ) : (
                                        <Table>
                                            <TableHead>
                                            <TableRow key='claimsHeader'>
                                                <TableCell>Variance</TableCell>
                                                <TableCell>Contract</TableCell>
                                                <TableCell>Claim Number</TableCell>
                                                <TableCell>Type</TableCell>
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {claimsData?.slice(0, 5)?.map((claim) => (
                                                <TableRow key={claim.claimNumber}>
                                                <TableCell><Typography onClick={()=> handleClaimSummaryAmountClick(claim.id)} variant="body2" className={classes.link}>{asDollars(claim.variance)}</Typography></TableCell>
                                                <TableCell>{claim.contractName}</TableCell>
                                                <TableCell>{claim.claimNumber}</TableCell>
                                                <TableCell>{claim.type}</TableCell>
                                                </TableRow>
                                            ))}
                                            </TableBody>
                                        </Table>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>

                            {/* Underpayments */}
                            <Grid item xs={12} md={12} sx={{ marginTop: "1.5rem" }}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <Typography className={classes.greenBg} variant="h6" gutterBottom>
                                    Underpayments
                                    </Typography>
                                    {underpaymentsLoading ? (
                                    <CircularProgress />
                                    ) : (
                                    <Table>
                                        <TableHead>
                                        <TableRow key='underpaymentsHeader'>
                                            <TableCell>Contract</TableCell>
                                            <TableCell>Underpayments</TableCell>
                                            <TableCell>Denials</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {underpaymentsData?.slice(0, 10)?.map((entry) => (
                                            <>
                                            <TableRow key={entry.contractName} className={entry.underpaymentsByFacilities?.length > 0 ? classes.greenHeaderRow : ''}>
                                                <TableCell>
                                                    {entry.underpaymentsByFacilities?.length > 0 && (
                                                        <IconButton sx={{padding: "0 10px 0 0"}} onClick={() => handleUnderpaymentsRowClick(entry.contractName)}>
                                                            {open[entry.contractName] ? <ArrowDropDownIcon /> : <ArrowRightIcon />  }
                                                        </IconButton>
                                                    )}
                                                    {entry.contractName}
                                                </TableCell>
                                                <TableCell><Typography onClick={()=> handleUnderpaymentsAmountClick(entry.contractName,false)} variant="body2" className={classes.link}>{asDollars(entry.totalUnderPayments)}</Typography></TableCell>
                                                <TableCell><Typography onClick={()=> handleUnderpaymentsAmountClick(entry.contractName,true)} variant="body2" className={classes.link}>{asDollars(entry.totalDenials)}</Typography></TableCell>
                                            </TableRow>
                                            {entry.underpaymentsByFacilities?.length > 0 && (
                                                <TableRow key={`${entry.contractName}-childRow`}>
                                                    <TableCell colSpan={3} sx={{padding: "0px", border: 0 }}>
                                                        <Collapse in={open[entry.contractName]} timeout="auto" unmountOnExit>
                                                        <Table sx={{width: "87%", margin: "0px 50px"}}>
                                                            <TableBody>
                                                            {entry.underpaymentsByFacilities?.map((facility) => (
                                                                <TableRow key={facility.facilityName}>
                                                                    <TableCell>{facility.facilityName}</TableCell>
                                                                    <TableCell><Typography onClick={()=> handleUnderpaymentsAmountClick(entry.contractName,false, facility.billingNpi)} variant="body2" className={classes.link}>{asDollars(facility.totalUnderPayments)}</Typography></TableCell>
                                                                    <TableCell><Typography onClick={()=> handleUnderpaymentsAmountClick(entry.contractName,true, facility.billingNpi)} variant="body2" className={classes.link}>{asDollars(facility.totalDenials)}</Typography></TableCell>
                                                                </TableRow>
                                                            ))}
                                                            </TableBody>
                                                        </Table>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            
                                            </>
                                        ))}
                                        </TableBody>
                                    </Table>
                                    )}
                                </CardContent>
                                </Card>
                            </Grid>

                        </Grid>
                    </Grid>

                    {/* Top 10 CAS Codes */}
                    <Grid item xs={12} md={5}>
                        <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            <Typography className={classes.blueBg} variant="h6" gutterBottom>
                            Top 10 CAS Codes
                            </Typography>
                            {casLoading ? (
                            <CircularProgress />
                            ) : (
                            <Table>
                                <TableBody sx={{paddingLeft: "10px"}}>
                                {casData?.slice(0, 10)?.map((cas) => (
                                    <TableRow key={cas.casCode}>
                                    <TableCell>
                                        <Tooltip title={cas.fullDesc}>
                                            <Typography variant='body2' ><strong>{cas.casCode}</strong> - {cas.shortDesc}</Typography>
                                        </Tooltip>
                                        <Typography onClick={()=> handleCasCodeAmountClick(cas.casCode)} variant="subtitle1" className={classes.link} sx={{ display: "block", paddingTop: "0.5rem" }}>{`${asDollars(cas.amount)} (${cas.claimsCount} claims)`}</Typography>
                                    </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                                <TableFooter className={classes.blueBg}>
                                    <TableRow key={`totalCasCodes`}>
                                        <TableCell colSpan={2} sx={{ fontWeight: 800, padding: "10px" }}>
                                            <Typography variant='h6'gutterBottom>Total: {asDollars(getTotalCasCodes())}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                            )}
                        </CardContent>
                        </Card>
                    </Grid>

                </Grid>
                </PortletBody>
            </Portlet>
            
        </>
    );
}
