import React, { useEffect, useState } from "react";
import { Portlet } from "../../partials/content/Portlet";
import {
    Grid,
    IconButton,
    Tooltip,
    DialogTitle,
    DialogContent,
    Box,
    Typography,
    Divider,
    Toolbar,
    Tabs,
    Tab,
    FormControlLabel,
    Switch,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Loadable, VanillaJSONEditor } from "../../common";
import SearchIcon from "@mui/icons-material/Search";
import { Card as BCard } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    grid: { display: "grid", alignItems: "center" },
    width: {
        width: "auto",
        overflowX: "auto",
    },
    clearButton: {
        color: "#d9534f",
    },
    summaryView: {
        marginTop: "20px",
        marginRight: "10px",
        maxHeight: "700px",
        overflowY: "auto",
        border: "1px solid #ebedf2",
        borderRadius: "0.25rem",
    },
    helper: { fontSize: "10px", padding: "3px 0 0 5px" },
    summaryViewList: {
        paddingInlineStart: "15px",
        paddingTop: "20px",
        listStyleType: "none",
    },
    summaryViewListItem: {
        textDecoration: "underline",
        color: "#263AC1",
        marginBottom: "15px",
        cursor: "pointer",
    },
    active: {
        color: "blueviolet",
    },
    summaryViewHeader: {
        height: "52px",
        display: "grid",
        alignItems: "center",
    },
    switch: {
        margin: 0,
    },
}));

export default function ClaimLookupPage({
    claimNumber,
    getJsonPending = false,
    getconsolidatedClaimsPending = false,
    claimJson,
    searchedClaim,
    consolidatedClaimsData = [],
    actions: {
        getClaimJSONView,
        resetJSONView,
        getConsolidatedClaims,
        resetConsolidatedClaimsView,
        setSearchClaim
    },
}) {
    const classes = useStyles();
    const [mode, setMode] = useState("claims");
    const [hideEmptyColumns, setHideEmptyColumns] = useState(false);
    const [currentSummaryId, setCurrentSummaryId] = useState(null);
    const [tempClaimId, setTempClaimId] = useState("");


    useEffect(() => {
        if (claimNumber) {
            setSearchClaim(claimNumber)
            getClaimJSONView(claimNumber);
            setTempClaimId(claimNumber)
            // return () => {
            //     resetJSONView();
            // };
        }
    }, [claimNumber, getClaimJSONView, resetJSONView]);

    useEffect(() => {
        getConsolidatedClaims(0, claimNumber);
        return () => {
            resetConsolidatedClaimsView();
        };
    }, [claimNumber, getConsolidatedClaims, resetConsolidatedClaimsView]);

    const searchClaimData = () => {
        setSearchClaim(tempClaimId)
        getClaimJSONView(tempClaimId);
        getConsolidatedClaims(0, tempClaimId);

    };

    const areConsolidatedClaimsNotEmpty = () => {
        return (
            !getconsolidatedClaimsPending &&
            (consolidatedClaimsData?.length > 0 && consolidatedClaimsData?.length !== 1 )
        );
    };
    const handleConsolidatedClaims = (conSummaryId) => {
        if (conSummaryId !== currentSummaryId) {
            getClaimJSONView(conSummaryId);
            getConsolidatedClaims(conSummaryId);
        }
        setCurrentSummaryId(conSummaryId);
    };

    const resetAllData = () => {
        resetConsolidatedClaimsView();
        resetJSONView();        
        setSearchClaim("")
        setTempClaimId("")
    }

    return (<>
        <Portlet>
            <DialogTitle style={{ cursor: "move" }} id="claim-json-dialog">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        variant="h6"
                        style={{ display: "grid", alignItems: "center" }}
                    >
                        {" "}
                        Data View {searchedClaim && `(${searchedClaim})`}
                    </Typography>
                    <FormControl
                        variant="outlined"
                        style={{ width: "300px" }}
                    >
                        <InputLabel >
                            Claim No.
                        </InputLabel>
                        <OutlinedInput
                        onKeyUp={(event)=>event.key==="Enter" && searchClaimData()}
                            type="text"
                            label="Claim No."
                            value={tempClaimId || searchedClaim}
                            onChange={(e) => setTempClaimId(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={resetAllData}
                                        size="large">
                                        <CloseIcon />
                                    </IconButton>

                                    <IconButton
                                        edge="end"
                                        onClick={() => searchClaimData()}
                                        size="large">
                                        <Tooltip title="Click to Search">
                                            <SearchIcon />
                                        </Tooltip>
                                    </IconButton>
                                </InputAdornment>
                            }
                            //MUIv5: labelWidth={70}
                        />
                        <Box
                            component="span"
                            className={`${classes.helper}`}
                        >
                            Click Search Icon to search
                        </Box>
                    </FormControl>
                </div>
            </DialogTitle>
            <Divider />

            <Grid container>
                <Grid item xs={areConsolidatedClaimsNotEmpty() ? 9 : 12}>
                    <DialogContent>
                        <Loadable loading={getJsonPending}>
                            <Toolbar>
                                <Tabs
                                    value={mode}
                                    onChange={(e, x) => setMode(x)}
                                >
                                    <Tab
                                        label={`Claims (${
                                            claimJson?.json?.claims
                                                ?.length || 0
                                        })`}
                                        value="claims"
                                    />
                                    <Tab
                                        label={`Payments (${
                                            claimJson?.json?.payments
                                                ?.length || 0
                                        })`}
                                        value="payments"
                                        disabled={
                                            !claimJson?.json?.payments
                                                ?.length
                                        }
                                    />
                                    <Tab label="Tree View" value="tree" />
                                    <Tab label="Text View" value="text" />
                                </Tabs>

                                {mode === "payments" || mode == "claims" ? (
                                    <FormControlLabel
                                        className={classes.switch}
                                        control={
                                            <Switch
                                                checked={hideEmptyColumns}
                                                onChange={(_, x) =>
                                                    setHideEmptyColumns(x)
                                                }
                                            />
                                        }
                                        label="Remove Empty Columns"
                                    />
                                ) : null}
                            </Toolbar>

                            {claimJson && (
                                <div
                                    className={`vanilla-json-editor ${classes.width}`}
                                >
                                    <VanillaJSONEditor
                                        content={
                                            mode === "tree" ||
                                            mode === "text"
                                                ? claimJson
                                                : {
                                                      json:
                                                          (hideEmptyColumns
                                                              ? removeEmptyColumns(
                                                                    claimJson
                                                                        ?.json[
                                                                        mode
                                                                    ]
                                                                )
                                                              : claimJson
                                                                    ?.json[
                                                                    mode
                                                                ]) || [],
                                                  }
                                        }
                                        readOnly={false}
                                        navigationBar={false}
                                        statusBar={false}
                                        mode={
                                            mode === "payments" ||
                                            mode == "claims"
                                                ? "table"
                                                : mode
                                        }
                                        mainMenuBar={false}
                                        onChangeMode={(newMode) =>
                                            setMode(newMode)
                                        }
                                    />
                                </div>
                            )}
                        </Loadable>
                    </DialogContent>
                </Grid>

                {areConsolidatedClaimsNotEmpty() && (
                    <Grid item xs={3}>
                        <div className={classes.summaryView}>
                            <BCard.Header
                                className={classes.summaryViewHeader}
                                as="h5"
                            >
                                Summary View{" "}
                                {currentSummaryId &&
                                    `(${currentSummaryId})`}
                            </BCard.Header>
                            <Loadable
                                loading={
                                    getconsolidatedClaimsPending ||
                                    getJsonPending
                                }
                            >
                                <ul
                                    className={`${classes.summaryViewList} mb-0`}
                                >
                                    {consolidatedClaimsData?.map((item) => (
                                        <li
                                            className={`${
                                                classes.summaryViewListItem
                                            } ${
                                                currentSummaryId === item &&
                                                classes.active
                                            }`}
                                            onClick={() =>
                                                handleConsolidatedClaims(
                                                    item
                                                )
                                            }
                                        >
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </Loadable>
                        </div>
                    </Grid>
                )}
            </Grid>
        </Portlet>
    </>);
}
function removeEmptyColumns(arr) {
    return (arr && arr?.map((obj) => {
        return Object.keys(obj).reduce((acc, key) => {
            // remove empty columns
            if (
                obj[key] !== null &&
                obj[key] !== undefined &&
                obj[key] !== ""
            ) {
                acc[key] =
                    typeof obj[key] === "string"
                        ? // remove time from date strings
                          obj[key].match(/^\d\d\d\d-\d\d-\d\d 00:00:00$/)
                            ? obj[key].replace(/ 00:00:00$/, "")
                            : // convert string to number if possible
                            obj[key].match(/^\d+$/)
                            ? Number(obj[key])
                            : obj[key]
                        : // process nested arrays recursively
                        obj[key] instanceof Array
                        ? removeEmptyColumns(obj[key])
                        : obj[key];
            }
            return acc;
        }, {});
    }));
}
