import { useState } from "react";
import {
    Tooltip,
    Grid,
    IconButton,
    Box,
    Typography,
    Button
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import Helmet from 'react-helmet';
import { Portlet } from "../../partials/content/Portlet";
import { DataTile } from "../../common/widgets/DataTile";
import { getLast30Days } from "../../util/date";
import { useData, useStatePtr } from "@pure-ptr/react";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
    getDashboardData,
    getDashboardSummary,
    downloadReport,
    downloadFullReport
} from "../../store/api/rcmDashboard";
import { DateRangePicker } from "../../common/dateRangeControl";
import { DataTable, Loadable } from "../../common";
import TotalRow from "../../common/dataTable/TotalRow";

const useStyles = makeStyles((theme) => ({
    tile: {
        flex: "20%",
        "& > div": {
            height: "100%",
        },
        cursor: "pointer",
    },
    rcmContainer: {
        paddingLeft: "20px",
    },
    statisticsContainer: {
        padding: "20px 20px 20px 0",
    },

    tableContainer: {},
    selectedBorder: { border: "1px solid" },
}));

function RCMStatusPage({
    lineItemsOverPaidAndUnderpaidFields,
    totalVarianceFields,
    zeroPaymentFields,
    intl,
}) {
    const dateRangePtr = useStatePtr(getLast30Days);
    const [first, setFirst] = useState(0);
    const [reportType, setReportType] = useState<any>({});
    const [downloadFullExcel, setDownloadFullExcel] = useState(false);

    const dateRelativeFilter =
        dateRangePtr?.value?.relativeFilter?.toLowerCase();
    const {
        data: dashboardData,
        isPending,
        error,
    } = useData(async () => {
        const dashboardData = await getDashboardData(dateRelativeFilter);
        return dashboardData;
    }, [dateRelativeFilter]);

    const { data: dashboardSummaryData, isPending: isPendingSummary } =
        useData(async () => {
            const dashboardData = await getDashboardSummary(dateRelativeFilter);
            return dashboardData;
        }, [dateRelativeFilter]);

    function downloadTemplateCallback(blob,isExcel=false) {
        setReportType({});
        setDownloadFullExcel(false)
        const displayName = `${isExcel ? 'RCM Report':reportType?.title}`;
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${displayName.replace(/\s/g, "")}.${isExcel ? 'xlsx':'csv'}`;
        a.click();
    }

    const { isPending: isPendingExport } = useData(async () => {
        if (Object.keys(reportType)?.length===0) return;
        const dashboardData = await downloadReport(
            dateRelativeFilter,
            reportType?.name
        );
        downloadTemplateCallback(dashboardData);
    }, [reportType]);

    const { isPending: isPendingFullExport } = useData(async () => {
        if (!downloadFullExcel) return;
        const dashboardData = await downloadFullReport(
            dateRelativeFilter,
        );
        downloadTemplateCallback(dashboardData,true);
    }, [downloadFullExcel]);

    const searchPeriods = [
        { id: "Last7Days", value: "Last 7 Days" },
        { id: "Last30Days", value: "Last 30 Days" },
        { id: "Last90Days", value: "Last 90 Days" },
        { id: "Last6Months", value: "Last 6 Months" },
        { id: "Last12Months", value: "Last 12 Months" },
    ];

    const classes = useStyles();

    const allStatistics = [
        {
            title: `Total Variance:${
                dashboardSummaryData?.totalVarianceSummary?.count || ""
            } `,
            value: dashboardSummaryData?.totalVarianceSummary?.amount || 0,
            color: "danger",
        },
        {
            title: `Zero Payment (Denials): ${
                dashboardSummaryData?.zeroPaymentSummary?.count || ""
            }`,
            value: dashboardSummaryData?.zeroPaymentSummary?.amount || 0,
            color: "success",
        },
        {
            title: `Underpaid Claims: ${
                dashboardSummaryData?.underpaymentsSummary?.count || ""
            }`,
            value: dashboardSummaryData?.underpaymentsSummary?.amount || 0,
            color: "info",
        },
        {
            title: `Overpaid Claims: ${
                dashboardSummaryData?.overpaidClaimsSummary?.count || ""
            }`,
            value: dashboardSummaryData?.overpaidClaimsSummary?.amount || 0,
            color: "info",
        },
        {
            title: `Line Items Denials: ${
                dashboardSummaryData?.lineitemDenialsSummary?.count || ""
            }`,
            value: dashboardSummaryData?.lineitemDenialsSummary?.amount || 0,
            color: "malta",
        },
    ];

    const allGrids = [
        {
            title: "Total Variance",
            fields: totalVarianceFields,
            color: "danger",
            name: "totalVariance",
            data: dashboardData?.totalVariance,
            fullWidth: false,
            defaultPosition: 0,
        },
        {
            title: "Zero Payments",
            fields: zeroPaymentFields,
            color: "success",
            name: "zeroPayment",
            data: dashboardData?.zeroPayment,
            fullWidth: true,
            defaultPosition: 1,
        },
        {
            title: "Underpaid Claims",
            fields: lineItemsOverPaidAndUnderpaidFields,
            color: "info",
            name: "underPayments",
            data: dashboardData?.underpayments,
            fullWidth: false,
            defaultPosition: 2,
        },
        {
            title: `Overpaid Claims`,
            fields: lineItemsOverPaidAndUnderpaidFields,
            color: "info",
            name: "overpaidClaims",
            data: dashboardData?.overpaidClaims,
            fullWidth: false,
            defaultPosition: 3,
        },
        {
            title: `Line Items Denials`,
            fields: lineItemsOverPaidAndUnderpaidFields,
            color: "malta",
            name: "lineitemDenials",
            data: dashboardData?.lineitemDenials,
            fullWidth: false,
            defaultPosition: 4,
        },
    ];

    const reorderedGrids = [
        ...allGrids.filter((item) => item.defaultPosition === first),
        ...allGrids
            .filter((item) => item.defaultPosition !== first)
            .sort((a, b) => a.defaultPosition - b.defaultPosition),
    ];
    const hoverData = (rowData) => {
        const hoverColumns = new Map();
        hoverColumns.set(
            "grouping",
            <div style={{ cursor: "pointer" }}>
                {rowData?.fullDescription}{" "}
            </div>
        );

        return hoverColumns;
    }

    return (
        <>
        <Helmet title="RCM Status Page" />
        <Loadable
            loading={
                isPending !== null ||
                isPendingSummary !== null ||
                isPendingExport !== null || isPendingFullExport!==null
            }
        >
            <Portlet>
                <Box className={classes.rcmContainer}>
                    <Box sx={{textAlign:'right',p:"10px"}}>
                    <Button variant="contained" onClick={()=>setDownloadFullExcel(true)}>Download Report</Button>
                    </Box>
                    <Grid
                        className={classes.statisticsContainer}
                        container
                        spacing={2}
                    >
                        {allStatistics?.map((item, key) => (
                            <Grid
                                item
                                className={`${classes.tile}`}
                                key={key}
                                onClick={() => setFirst(key)}
                            >
                                <DataTile
                                    title={item?.title}
                                    value={item?.value}
                                    isSelected={first === key}
                                    selectedClass={classes.selectedBorder}
                                    isTwoDecimal={true}
                                    color={item?.color}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Box sx={{ width: "250px", mb: "40px" }}>
                        <DateRangePicker
                            disabled={false}
                            valuePtr={dateRangePtr}
                            label="Date Range"
                            relativeFilterOptions={searchPeriods}
                            disableDateSection={true}
                            enabledSwitch={false}
                        />
                    </Box>
                    {reorderedGrids?.map((item) => (
                        <Grid container sx={{ mb: "20px" }}>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                sx={{
                                    mt: "30px",
                                    border: "1px solid #E0E0E0",
                                    borderRadius: "10px",
                                    pt: "10px",
                                }}
                            >
                                <Box className={classes.tableContainer}>
                                    <DataTable
                                        // defaultSort={[]}
                                        actionLevelGrid={() => (
                                            <Typography
                                                className={`kt-font-${item?.color}`}
                                                sx={{
                                                    fontSize: "18px",
                                                    fontWeight: "500",
                                                    pl: "15px",
                                                }}
                                            >
                                                {item?.title}
                                            </Typography>
                                        )}
                                    
                                        tableLevelActions={() => (
                                            <>
                                                <Grid
                                                    item
                                                    alignItems={"center"}
                                                    alignContent={"center"}
                                                >
                                                    <Typography>
                                                        {
                                                            <TotalRow
                                                                customText="Total:  "
                                                                fields={
                                                                    lineItemsOverPaidAndUnderpaidFields
                                                                }
                                                                data={
                                                                    item?.data
                                                                }
                                                                whiteBg
                                                            />
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    sx={{ pr: "20px" }}
                                                    onClick={() =>
                                                        setReportType(
                                                            item
                                                        )
                                                    }
                                                >
                                                    <Tooltip title="Export">
                                                        <IconButton
                                                            aria-label="export"
                                                            size="small"
                                                        >
                                                            <GetAppIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </>
                                        )}
                                        hoverData={hoverData}
                                        fields={item?.fields}
                                        data={item?.data || []}
                                        sort={[]}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            </Portlet>
        </Loadable>
        </>

    );
}

export default RCMStatusPage;
