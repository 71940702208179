import { Breadcrumbs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ComponentType } from 'react';
import { Link, RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import { getLastFiveBreadCrumbLocations, resetBreadCrumbLocations,resetBreadCrumbLocalStorageLocations } from '../../router/RouterHelpers';
import { capitalizeFirstLetter } from '../../util';
import { pagesRouteBasedNames } from '../../store/constants/pages';

const splittedSeparateRoutes=[{
    "path": "/cms/contracts",
    "name": "Contracts",
    
}]

const useStyles = makeStyles(theme => ({
    div: {
        backgroundColor: "#fff",
        padding: '15px 13px',
        margin: '-5px -10px 10px -10px'
    },
    divClaims: {
        backgroundColor: "#fff",
        padding: '25px 13px',
        margin: '-5px -10px 10px -10px'
    },
}));

const pageNameEnums = {
    "Claim Analysis Data View": "Data View",
    "ClaimAnalysis Details": "Claim Details",
    "Claim Analysis Details": "Claim Details",
    "Modeling Results": "Results",
    "Modeling Details": "Details",
    "Modeling Data View": "Data View",
    "claimLookup": "Claim Lookup"
};
const routeEnums={
    "/cms/claimLookup":"/cms/claimLookup/:cn?"
}

interface Route {
    path : string
    name : string
}

const BreadCrumb = ({ routes ,isFromClaims=undefined} : RouteComponentProps & {
    routes: Route[],
    isFromClaims?:undefined | Boolean,
}) => {
    const classes = useStyles();
    const currentLocation = useLocation().pathname;
    const isFeeScheduleModuleAndNotViewPage=currentLocation.includes("/cms/feeschedules") &&
    currentLocation.includes("/cms/feeschedules/view") === false
    const notFeeScheduleEditPage=currentLocation.includes('/cms/feeschedules/edit')===false
    const notContractsViewPage=currentLocation.includes('/cms/contracts')===false
    const isContractsEditAndHaveDirectoryId=currentLocation.includes('/cms/contract/edit') && currentLocation?.split('/')?.length>5 
    const isContractsViewAndHaveDirectoryId=currentLocation.includes("cms/contract/view") && currentLocation?.split('/')?.length>5 

    const notFeeScheduleRateEditPage=currentLocation.includes('/cms/feeschedulesrate/edit')===false
    const isFeeScheduleViewAndHaveDirectoryId=currentLocation.includes('/cms/feeschedules/view') && currentLocation?.split('/')?.length>=7 
    const isClaimAnalysisAndNotViewPage=isFromClaims ? false :currentLocation.includes('/cms/claimAnalysis') && currentLocation.includes('/cms/claimAnalysis/view')===false && currentLocation.includes('/cms/claimAnalysis/data-view')===false 
    if (
        isFeeScheduleModuleAndNotViewPage  &&
            notFeeScheduleEditPage &&
            notFeeScheduleRateEditPage
    ) {
        resetBreadCrumbLocations();
        return <div/>;
    }

    const getRouteName = (pathname: string, routes: Route[]) => {
        const currentRoute:any = [...routes,...splittedSeparateRoutes].find((route:any) => route.path === pathname);
        return currentRoute ? currentRoute.name : false
    }
    const getBreadcrumbs = (location: string) => {
        let breadcrumbs = [];
        let activePageName = '';
        const moduleName = location.split('/')[1];
        const pageName = location.split('/')[2];
        const subPageName = location.split('/')[4];
        const currentRouteName = getRouteName(routeEnums[location] || location , routes);
        if (currentRouteName) {
            activePageName = currentRouteName;
            resetBreadCrumbLocations();
            resetBreadCrumbLocalStorageLocations()
        } else {
            if (moduleName === 'vendor') {
                activePageName = 'Vendor';
            } else if (moduleName === 'dashboard') {
                activePageName = 'Dashboard';
            } else if (subPageName === 'rule') {
                activePageName = pagesRouteBasedNames[subPageName] ?? pageName;
            } else if (pagesRouteBasedNames[pageName]) {
                activePageName = pagesRouteBasedNames[pageName];
            } else {
                activePageName = pageName === 'history' ? 'Estimate' : pageName;
            }

            const pageActionType = location.includes('new') ?
                'Add' : location.includes('edit') ?
                    'Edit' : location.includes('view') && location.includes('data-view')===false ?
                        'Details' : location.includes('data-view') ? 'Data View': location.includes('list') ?
                            'Execution' : location.includes('configuration') ?
                                'Configuration' : location.includes('results') ?
                                    'Results' : location.includes('copy') ?
                                        'Copy' : location.includes('import') ? 
                                            'Import' :'';

            if (pageActionType === 'Add' || pageActionType === 'Edit') {
                activePageName = `${pageActionType} ${activePageName}`;

            } else {
                activePageName = `${activePageName} ${pageActionType}`;
            }
        }

        const lastFiveLocations = getLastFiveBreadCrumbLocations(moduleName) ?? [];
        for (let loc of lastFiveLocations) {
            if (loc !== currentLocation) {
                const routeName = getRouteName(loc, routes);
                if (routeName) {
                    breadcrumbs.push({
                        pathname: loc,
                        name: routeName,
                    });
                } else {
                    const subModuleName = loc.split('/')[2];
                    const capitalizedName = capitalizeFirstLetter(subModuleName);
                    if (loc.includes('edit')) {
                        breadcrumbs.push({
                            pathname: loc,
                            name: `${capitalizedName} Edit`,
                        });
                    } else if (loc.includes('view')) {
                        breadcrumbs.push({
                            pathname: loc,
                            name: `${capitalizedName} Details`,
                        });
                    } else if (loc.includes('copy')) {
                        breadcrumbs.push({
                            pathname: loc,
                            name: `${capitalizedName} Copy   `,
                        });
                    } else if (loc.includes('results')) {
                        breadcrumbs.push({
                            pathname: loc,
                            name: `${capitalizedName} Results`,
                        });
                    }
                    else if (loc.includes('import')) {
                        breadcrumbs.push({
                            pathname: loc,
                            name: `${capitalizedName} Import`,
                        });
                    }
                    else if (loc.includes('fromSummary')) {
                        breadcrumbs.push({
                            pathname: loc,
                            name: `${capitalizedName}`,
                        });
                    }
                }
            }

        }
        return { breadcrumbs, activePageName }
    }
    const { breadcrumbs, activePageName } = getBreadcrumbs(currentLocation);
    return (
        
        (notFeeScheduleEditPage && !isFeeScheduleViewAndHaveDirectoryId && notContractsViewPage && !isContractsEditAndHaveDirectoryId && !isContractsViewAndHaveDirectoryId && !isClaimAnalysisAndNotViewPage) ? 
        <div className={`${isFromClaims ? classes.divClaims: classes.div} bread-crumb`}>
            <Breadcrumbs separator=">" aria-label="breadcrumb">
                {breadcrumbs.map((breadcrumb, index) =>
                    <Link className='breadcrumb-link' to={breadcrumb.pathname} key={index.toString()} color="inherit" >
                        {pageNameEnums[breadcrumb.name] || breadcrumb.name}
                    </Link>
                )}
                <Typography className='breadcrumb-active' color="textPrimary">{pageNameEnums[activePageName?.trim()] || activePageName}</Typography>
            </Breadcrumbs>
        </div>:<></>
    )
}
export default withRouter(BreadCrumb);