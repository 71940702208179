import { useMemo } from "react";
import { createSelector } from "reselect";

const filterData = (fields: string[], filter: string, data: any[]) => {
    if (!data) {
        return [];
    }
    if (!filter || !filter.length || !data.length) {
        return data;
    }

    return data.filter(o => fields.some(k => (String(o[k]).toLowerCase().trim()).includes(filter.toLowerCase().trim())));
}

export function createFilteredDataSelector(
    selectFields: Selector<string[]>,
    selectFilter: Selector<string>,
    selectData: Selector<any[]>
) {
    return createSelector(
        selectFields,
        selectFilter,
        selectData,
        filterData
    );
}

export function useFilteredData( fields: string[], filter: string, data: any[] ){
    return useMemo(() => filterData( fields, filter, data ), [fields, filter, data]);
}

const sortData = (fields: string[], sort: SortField[], data: any[]) => {
    if (!data) {
        return [];
    }
    if (data.length < 2 || (!fields || !fields.length) || (!sort || !sort.length)) {
        return data;
    }
    const { id, direction, fieldType } = sort[0];
    if (fieldType === 'Date') {
        return data.sort(function (a, b) {
            let sortA = new Date(a[id]);
            let sortB = new Date(b[id]);
            let comparison = 0;
            if (sortA > sortB) {
                comparison = 1;
            } else if (sortA < sortB) {
                comparison = -1;
            }
            if (direction === 'desc') {
                return comparison * -1;
            }
            return comparison;
        })
    } else {
        return data.sort(function (a, b) {
            const val1 = getDeepValue(a, id);
            const val2 = getDeepValue(b, id);
            let sortA;
            let sortB;
            if (typeof (val1) === 'number') {
                sortA = val1;
                sortB = val2;
            } else {
                sortA = String(val1).toUpperCase();
                sortB = String(val2).toUpperCase();
            }

            let comparison = 0;
            if (sortA > sortB) {
                comparison = 1;
            } else if (sortA < sortB) {
                comparison = -1;
            }
            if (direction === 'desc') {
                return comparison * -1;
            }
            return comparison;
        });
    }

}

export const createSortedDataSelector = (
    selectFields: Selector<string[]>,
    selectSort: Selector<SortField[]>,
    selectData: Selector<any[]>
) => {
    return createSelector(
        selectFields,
        selectSort,
        selectData,
        sortData
    )
}

export function useSortedData(fields: string[], sort: SortField[], data: any[]) {
    return useMemo(() => sortData( fields, sort, data ), [fields, sort, data]);
}

export interface SortField {
    id: string;
    direction: 'asc' | 'desc';
    fieldType: 'Date' | 'String' | 'Number';
}

export type Selector<T> = ( state: any, props?: any ) => T;

export function createFilteredSortedDataSelector(
    selectFields: Selector<string[]>,
    selectFilter: Selector<string>,
    selectSort: Selector<SortField[]>,
    selectData: Selector<any[]>
) {
    const selectFilteredData = createFilteredDataSelector(selectFields, selectFilter, selectData);
    return createSortedDataSelector(selectFields, selectSort, selectFilteredData);
}

export function useFilteredSortedData(
    fields: string[], filter: string, sort: SortField[], data: any[]
) {
    const selectFilteredData = useFilteredData(fields, filter, data);
    return useSortedData(fields, sort, selectFilteredData);
}

function getDeepValue(obj: Record<string,any>, path: string): any {
    return path
        .replace(/\[|\]\.?/g, '.')
        .split('.')
        .filter(s => s)
        .reduce((acc, val) => acc && acc[val], obj);
}