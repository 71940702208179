import React, { useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    Switch,
    FormControlLabel,
    Checkbox,
    Typography,
    Chip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MuiAlert from '@mui/material/Alert';

import { Formik } from "formik";
import { Loadable } from '../../../common';
import { Autocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '100%',
    },
    root: {
        flexGrow: 1,
    },
    checkboxlabel: {
        marginLeft: 0
    },
    switch: {
        margin: theme.spacing(1),
    },
}));

export default function PayerDialog({
    open, handleClose, mode, intl, initialValues, payerCreatePending, payerUpdateBulkPending,
    payerUpdatePending, payerContracts, selectedPayers, isBulk,
    actions: { payerCreate, payerUpdate, payerBulkUpdate, payerErrorReset, setSelected }
}) {
    const classes = useStyles();
    let shouldReloadGrid = false;

    function handleCallback(reload) {
        setSelected([]);
        handleClose(reload);
    }

    useEffect(() => {
        return function cleanup() {
            payerErrorReset();
        };
    }, [payerErrorReset]);

    function getTitle() {
        if (mode === 'edit') {
            if (isBulk) {
                return `Edit Remit Payer (${selectedPayers.length})`;
            }
            return 'Edit Remit Payer';
        }
        return 'Add Remit Payer';
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const filterOptions = createFilterOptions<any>({
        stringify: option => option.displayName,
    });

    return (
        (<Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{getTitle()}</DialogTitle>
            <DialogContent dividers>
                <Loadable loading={payerCreatePending || payerUpdatePending || payerUpdateBulkPending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors : any = {};

                            if (!values.name && !isBulk) {
                                errors.name = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            shouldReloadGrid = true;
                            let payload = {
                                active: values.active,
                                contractIds: values.contractIds.map(item => item.id) ?? [],
                                name: values.name,
                            }
                            if (mode === 'create') {
                                payerCreate(payload, handleCallback, shouldReloadGrid);
                            }
                            else {
                                if (isBulk) {
                                    payerBulkUpdate({
                                        active: values.active,
                                        contractIds: values.contractIds.map(item => item.id) ?? [],
                                        ids: selectedPayers
                                    }, handleCallback, shouldReloadGrid);
                                } else {
                                    payerUpdate({
                                        ...payload,
                                        id: values.id,
                                    }, handleCallback, shouldReloadGrid);
                                }
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting
                        }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                <div className={classes.root}>
                                    <Grid container>
                                        {isBulk && (selectedPayers.length < 1) ? (
                                            <Grid container justifyContent="flex-end">
                                                <Grid item xs={12}>
                                                    <Typography>No payer selected. Please select at least one payer to update and try again.</Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                            :
                                            <>
                                                {!isBulk && (
                                                    <Grid item xs={12} md={12}>
                                                        <TextField
                                                            required
                                                            disabled={mode === 'edit'}
                                                            label="Name"
                                                            name="name"
                                                            margin="normal"
                                                            value={values.name}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            error={Boolean(touched.name && errors.name)}
                                                            helperText={touched.name && errors.name}
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid item xs={12} md={12}>
                                                    <Autocomplete
                                                        onChange={(e, value) => setFieldValue("contractIds", value)}
                                                        filterOptions={filterOptions}
                                                        value={values.contractIds}
                                                        multiple
                                                        options={payerContracts}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(option) => option.displayName}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li {...props}>
                                                                <Checkbox
                                                                    icon={icon}
                                                                    checkedIcon={checkedIcon}
                                                                    style={{ marginRight: 8 }}
                                                                    checked={selected}
                                                                />
                                                                {option.displayName}
                                                            </li>
                                                        )}
                                                        renderTags={(tagValue, getTagProps) => {
                                                            const numTags = tagValue.length;
                                                            return (
                                                                <Typography variant="caption">
                                                                    {tagValue.slice(0, 2).map((option, index) => (
                                                                        <Chip
                                                                            size="small"
                                                                            color="secondary"
                                                                            key={index}
                                                                            label={option?.displayName}
                                                                            {...getTagProps({ index })}
                                                                        />
                                                                    ))}
                                                                    {numTags > 2 && ` +${numTags - 2}`}
                                                                </Typography>
                                                            )
                                                        }
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Contracts"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid className="mt-2" item xs={12} md={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                name="active"
                                                                checked={values.active}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={classes.switch}
                                                                value={values.active}
                                                            />
                                                        }
                                                        label="Enable"
                                                    />
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                    <hr />
                                    <Grid container className='justify-content-end'>
                                        <Button onClick={handleClose} variant="contained" className={classes.button}>
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={`ml-3 ${classes.button}`}
                                            type="submit"
                                            disabled={payerCreatePending || payerUpdatePending || payerUpdateBulkPending || (isBulk && selectedPayers.length < 1)}
                                        >
                                            {(payerCreatePending || payerUpdatePending || payerUpdateBulkPending) ? 'Saving Changes' : 'Save'}
                                        </Button>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>)
    );
}