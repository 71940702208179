import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import { createSelector } from 'reselect';
import { set, setMulti,setOpenAdvanceFiltersDialog } from '../../../store/actions/pages';

import ContractsTopFilterComponent from './ContractsTopFilter';

export const ContractsTopFilter = connect(
    () => {

        const selectCustomFilter = createSelector(
            (state) => state.pages['contracts-landing'],
            (table) => {
                return table.customFilter;
            }
        );

        const selectPending = createSelector(
            (state) => state.pages['contracts-landing'],
            (table) => {
                return table.pending;
            }
        );

        return (state, props) => ({
            customFilter: selectCustomFilter(state),
            relativeFilterValue: state.claimAnalysis.relativeFilterValue,
            finalizedState: state.pages['contractsv2-landing'].finalizedState,
            pending: selectPending(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setMulti: obj => setMulti('contracts-landing', obj),
            setFinalizedState: (value) => set('contractsv2-landing', 'finalizedState', value),
            setOpenAdvanceFiltersDialog
        }, dispatch)
    })
)(injectIntl(ContractsTopFilterComponent));