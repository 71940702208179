import { Stack } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import { useData, useStatePtr } from '@pure-ptr/react';
import React, { useMemo } from 'react';
import { CreateRowButton, XClientDataGrid } from '../../../common';
import { RefreshSchedule, refreshSchedulesEndpoint } from '../../../store/api/controlRoom';
import { createStringDataField, createStringFieldFromObjectMapping, createTimestampField } from '../../../util/format';

export const RefreshSchedulePanel = ({ dataSetId } : {
    dataSetId: string
}) => {
    const endpoint = useMemo( () =>
        refreshSchedulesEndpoint( dataSetId )
    , [ dataSetId ]);

    const columns = useMemo(() => [
        createStringFieldFromObjectMapping('refreshType', 'Refresh Type', 
            { 
                FULL_REFRESH: 'Full',
                INCREMENTAL_REFRESH: 'Incremental'
            },
            { 
                editable : true,
            }),

        createStringFieldFromObjectMapping('scheduleFrequency.interval', 'Interval', 
            {
                MINUTE15 : '15 minutes',
                MINUTE30 : '30 minutes',
                HOURLY : 'Hourly',
                DAILY : 'Daily',
                WEEKLY : 'Weekly',
                MONTHLY : 'Monthly'
            },
            { editable : true }),

        createStringFieldFromObjectMapping('scheduleFrequency.refreshOnDay.dayOfWeek', 'Day of Week', {
            'null' : 'None',
            SUNDAY : 'Sunday',
            MONDAY : 'Monday',
            TUESDAY : 'Tuesday',
            WEDNESDAY : 'Wednesday',
            THURSDAY : 'Thursday',
            FRIDAY : 'Friday',
            SATURDAY : 'Saturday'
        },{
            editable : row => row.scheduleFrequency.interval === 'WEEKLY'
        }),

        createStringDataField('scheduleFrequency.refreshOnDay.dayOfMonth', 'Day of Month', { 
            editable : true,
            nullable: true,
            validate: x => !x || x?.match( /^(?:LAST_DAY_OF_MONTH|1[0-9]|2[0-8]|[12]|[3-9])$/ ) ? undefined : 'Invalid day of month'
         }),
        createStringDataField('scheduleFrequency.timeOfTheDay', 'Time', { 
            editable : true,
            nullable: true,
            validate: x => !x || x?.match( /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/ ) ? undefined : 'Invalid time format'
         }),
        createStringDataField('scheduleFrequency.timezone', 'Timezone'),
        createTimestampField('startAfterDateTime', 'Start After', { editable: true }),
    ], []);

    const rowsPtr = useStatePtr<RefreshSchedule[]>([]);

    const { isPending } = useData( async () => {
        rowsPtr.set( await endpoint.find() );
    }
    , [ dataSetId ]);


    return (
        <Stack spacing={2} padding={2}>
            <h4>Refresh Schedule</h4>
            <Stack spacing={1}>
                <XClientDataGrid
                    fields={ columns }
                    rowsPtr={ rowsPtr }
                    loading={ Boolean(isPending) }

                    processRowUpdate={ async ( newRow, prevRow ) => {
                        return newRow.id ?
                            await endpoint.put( 
                                newRow.id, 
                                RefreshSchedule.object( newRow ) 
                            ) 
                        :
                            await endpoint.post( 
                                RefreshSchedule.object({
                                    ...newRow,
                                    id: self.crypto.randomUUID(), 
                                }) 
                            );
                    }}

                    processRowDelete={ async id => {
                        return await endpoint.del( id );
                    }}

                    hideFooter
                    slots={{
                        toolbar: Toolbar as any
                    }}
                />
            </Stack>
        </Stack>
    )
}

const Toolbar = () =>
    <GridToolbarContainer>
        <CreateRowButton
            create={ id => RefreshSchedule.object({ id })}
        />
    </GridToolbarContainer>
