import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    Switch,
    FormControlLabel,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { Formik } from "formik";
import { Loadable, TextMaskCustom } from '../../../common';

const useStyles = makeStyles(theme => ({
    textField: {
        width: '95%',
    },
    root: {
        flexGrow: 1,
    },
    checkboxlabel: {
        marginLeft: 0
    },
    switch: {
        margin: theme.spacing(1),
    },
}));

export default function FacilityDialog(props) {
    const {
        open, handleClose, mode, intl, initialValues, facilityCreatePending,
        facilityUpdatePending,
        actions: { facilityCreate, facilityUpdate }
    } = props;

    const [validationError, setValidationError] = useState(null);

    useEffect(() => {
        if (open) {
            setValidationError(null);
        }
    }, [open])

    const classes = useStyles();
    let shouldReloadGrid = false;

    function handleCallback(reload) {
        handleClose(reload);
    }

    function handleNPIChange(value, setFieldValue) {
        const withoutLineBreaks = value.replace(/[\r\n]/gm, '');
        const regex = /^[0-9a-zA-Z,]*$/g;
        if (withoutLineBreaks?.length === 0) {
            setFieldValue('npis', '');
            setFieldValue('billingProviderNPIs', []);
            setValidationError(null);
        }
        else if (withoutLineBreaks.match(regex)) {
            setFieldValue('npis', withoutLineBreaks);
            const splittedNPIs = withoutLineBreaks.split(',');
            setFieldValue('billingProviderNPIs', splittedNPIs.filter(item => item));
            setValidationError(null);
        } else {
            setValidationError('Codes are not pasted as per the required format')
        }
    }

    return (
        (<Dialog fullWidth open={open} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{mode === 'edit' ? 'Edit Facility' : 'Add Facility'}</DialogTitle>
            <DialogContent dividers>
                <Loadable loading={facilityCreatePending || facilityUpdatePending}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validate={values => {
                            const errors = {};
                            const npiRegex = /^\d{10}$/;
                            if (!values.name) {
                                errors.name = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }
                            if (!values.address) {
                                errors.address = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                            }

                            if (!values.npis) {
                                errors.npis = intl.formatMessage({
                                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                });
                                setValidationError("Required field");
                            }

                            if (values?.address?.length>500) {
                                errors.address = "Address should not exceed 500 characters.";
                            }

                            if (values.phone.replace(/\D/g, '').length !== 0 && !(/^[1-9]\d{9}$/.test(values.phone.replace(/\D/g, '')))) {
                                errors.phone = 'Invalid Phone number';
                            } 

                            if (values?.facilityNPI && !npiRegex.test(values?.facilityNPI )) {
                                errors.facilityNPI = "Facility NPI Must be a 10 Digit Number";
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            shouldReloadGrid = true;
                            
                            let payload = {
                                name: values.name,
                                description: values.description,
                                ein: values.ein,
                                address: values.address,
                                phone: values.phone.replace(/\D/g, '').length === 0 ? '' : values.phone,
                                active: values.active,
                                billingProviderNPIs: values.billingProviderNPIs,
                                enableForPricer: values.enableForPricer,
                                facilityNPI: values.facilityNPI,

                            }
                            if (mode === 'create') {
                                facilityCreate(payload, handleCallback, shouldReloadGrid);
                            }
                            else {
                                payload = {
                                    ...payload,
                                    id: values.id,
                                    
                                }
                                facilityUpdate(payload, handleCallback, shouldReloadGrid);
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
                                <div className={classes.root}>
                                    <Grid container>
                                        <Grid item xs={6} md={6}>
                                            <TextField
                                                required
                                                label="Name"
                                                name="name"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.name}
                                                helperText={touched.name && errors.name}
                                                error={Boolean(touched.name && errors.name)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <TextField
                                                label="EIN"
                                                name="ein"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.ein}
                                                helperText={touched.ein && errors.ein}
                                                error={Boolean(touched.ein && errors.ein)}
                                                inputProps={{
                                                    mask: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
                                                }}
                                                InputProps={{
                                                    inputComponent: TextMaskCustom,
                                                }}
                                            />
                                        </Grid>
                                        <Grid className="mt-2" item xs={12} md={12}>
                                            <TextField
                                                label="Description"
                                                name="description"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.description}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <TextField
                                                required
                                                multiline
                                                maxRows="3"
                                                label="Address"
                                                name="address"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.address}
                                                helperText={touched.address && errors.address}
                                                error={Boolean(touched.address && errors.address)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <TextField
                                                label="Phone"
                                                name="phone"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.phone}
                                                helperText={touched.phone && errors.phone}
                                                error={Boolean(touched.phone && errors.phone)}
                                                InputProps={{
                                                    inputComponent: TextMaskCustom,
                                                }}
                                                InputLabelProps={{shrink:true}}

                                            />
                                        </Grid>

                                        <Grid item xs={6} md={6}>
                                            <TextField
                                                type="number"
                                                
                                                label="Facility Npi"
                                                name="facilityNPI"
                                                className={classes.textField}
                                                margin="normal"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.facilityNPI}
                                                helperText={touched.facilityNPI && errors.facilityNPI}
                                                error={Boolean(touched.facilityNPI && errors.facilityNPI)}
                                            />
                                        </Grid>



                                        <Grid className="mt-2" item xs={12} md={12}>
                                            <TextField
                                                type="text"
                                                multiline
                                                maxRows={8}
                                                label="Billing NPIs"
                                                name="npis"
                                                className={classes.formControlAuto}
                                                placeholder="Type comma separated npis"
                                                margin="normal"
                                                onChange={e => handleNPIChange(e.target.value, setFieldValue)}
                                                value={values.npis}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                helperText={validationError ?? 'e.g. 1234544311,1232321142,2343434543'}
                                                error={Boolean(validationError)}
                                            // error={Boolean(validationError)}
                                            />
                                        </Grid>
                                        <Grid className="mt-2" item xs={6} md={6}>
                                            <FormControlLabel
                                                value={values.active}
                                                control={
                                                    <Switch
                                                        name="active"
                                                        color="primary"
                                                        checked={values.active}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={classes.switch}
                                                        value={values.active}
                                                    />
                                                }
                                                label="Enable"
                                            />
                                        </Grid>
                                        <Grid className="mt-2" item xs={6} md={6}>
                                            <FormControlLabel
                                                value={values.enableForPricer}
                                                control={
                                                    <Switch
                                                        name="enableForPricer"
                                                        color="primary"
                                                        checked={values.enableForPricer}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={classes.switch}
                                                        value={values.enableForPricer}
                                                    />
                                                }
                                                label="Enable Pricer"
                                            />
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    <Grid container justifyContent="flex-end">
                                        <Button
                                            onClick={() => handleClose(false)}
                                            variant="contained"
                                            className={classes.button}>
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={`ml-3 ${classes.button}`}
                                            type="submit"
                                            disabled={facilityCreatePending || facilityUpdatePending}
                                        >
                                            {(facilityCreatePending || facilityUpdatePending) ? 'Saving Changes' : 'Save'}
                                        </Button>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Loadable>
            </DialogContent>
        </Dialog>)
    );
}
