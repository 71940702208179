import {
    Autocomplete, Button,
    Dialog, DialogContent,
    DialogContentText,
    DialogTitle, FormControl, Grid,
    IconButton, TextField, Typography
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import React from "react";
import { injectIntl } from "react-intl";
import { Loadable } from "../../common";
import { updateClaimStatuses } from '../../store/actions/claimAnalysis';
import { useDispatch } from "react-redux";
import { useGlobalStore } from "../../common";

const useStyles = makeStyles(theme => ({
    textField: {
        width: "85%"
    },
    formControlAuto: {
        width: "85%"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    dropZone: {
        minHeight: 150,
        border: "none",
        backgroundColor: "#EAEAEA",
        borderRadius: "20px"
    },
    formControl: {
        marginTop: theme.spacing(2),
        width: "100%"
    }
}));

function UpdateClaimStatusDialog({
    intl,
    openPtr,
    selectedItemsIdsPtr,
    initialValues = { status: null },
    claimStatusList,
    handleRefresh
}) {
    const pending = useGlobalStore( s => s.claimAnalysis.updateClaimStatus.pending );

    const dispatch = useDispatch();

    const classes = useStyles();
 
    function handleClose() {
        openPtr.set(false);
    }

    const updateStatusCallback = (params) => {
        selectedItemsIdsPtr.set([])
        handleClose()
        handleRefresh()
    }

    const selectedItems = selectedItemsIdsPtr.value;
    return (
        (<Dialog open={openPtr.value} onClose={handleClose}>
            <DialogTitle>
                <Grid container alignItems="center">
                    <Grid item xs={10}>
                        <Typography variant="h6">Claim Status</Typography>
                    </Grid>
                    <Grid item xs={2} className="text-right">
                        <IconButton aria-label="close" onClick={handleClose} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {selectedItems?.length === 0 && (
                    <DialogContentText>
                        No Claims Selected, please select atleast one claim to
                        update status
                    </DialogContentText>
                )}

                {selectedItems?.length > 0 && (
                    <Loadable loading={pending}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validate={values => {
                                const errors : any = {};

                                if (!values.status) {
                                    errors.status = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }


                                return errors
                            }}
                            onSubmit={(
                                values,
                                {  }
                            ) => {

                                const payload={ids:selectedItems,statusId:values?.status?.value}
                                dispatch(updateClaimStatuses(payload,updateStatusCallback))
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                               
                                handleSubmit,
                                setFieldValue,
                            
                            }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FormControl
                                                className={
                                                    classes.formControlAuto
                                                }
                                            >
                                                
                                                <Autocomplete
                                                    onChange={(e, option) =>
                                                        setFieldValue(
                                                            "status",
                                                            option
                                                        )
                                                    }
                                                    //isOptionEqualToValue={(option, value) => option.name === value.name}

                                                    value={values.status}
                                                    options={claimStatusList}
                                                    
                                                    getOptionLabel={option =>
                                                        option.name || ""
                                                    }
                                               
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            label="Status"
                                                            error={Boolean(
                                                                touched.status &&
                                                                    errors.status
                                                            )}
                                                            helperText={
                                                                touched.status &&
                                                                errors.status
                                                            }
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            container
                                            className="justify-content-end pt-2"
                                            style={{ marginTop: "20px" }}
                                        >
                                            <Button type="button" onClick={handleClose} variant="contained" className="mr-3">
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                            >
                                                Update
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Loadable>
                )}
            </DialogContent>
        </Dialog>)
    );
}

export default injectIntl(UpdateClaimStatusDialog);
