import moment from 'moment';
import {
    DATE_TIMESTAMP_FORMAT, QUICKSIGHT_DATE_FORMAT
} from '../store/constants/date';

/**
 * Parses a plain date string in ISO format (YYYY-MM-DD or YYYY-MM-DDT00:00:00.000Z) and returns a Date object.
 * 
 * @param input - The ISO date string to parse. Time must be midnight if provided.
 * @returns A Date object representing the parsed date at midnight in the local time zone, or null if the input is not provided.
 * @throws Will throw an error if the input is not a string or if the date format is invalid.
 */
function parsePlainDate(input? : string, ignoreTime = false) : Date | null {
    if( !input ) return null;

    if (typeof input !== 'string') {
        console.error("[parseDate] Invalid input. Expected a valid ISO date string.");
        return null;
    }
  
    // Split date and time parts
    const [datePart, timePart] = input.split('T');
    
    // Validate date format (YYYY-MM-DD)
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!isoDateRegex.test(datePart)) {
      console.error("[parseDate] Invalid ISO date format. Expected 'YYYY-MM-DD' or 'YYYY-MM-DDT00:00:00.000Z'");
      return null;
    }
  
    // If there is a time component, ensure every time component is zero (00:00:00.000)
    if (timePart) {
      const timeRegex = /^00:00(?::00(?:\.000)?)?(?:Z|([+-]\d{2}:\d{2}))?$/;
      if (!timeRegex.test(timePart) && !ignoreTime) {
        console.warn("[parseDate] Plain date string contains a time component that is not midnight. Use 'date-time' or 'ignore-time' option.");
        return new Date(input);
      }
    }
  
    // Extract year, month, and day
    const [year, month, day] = datePart.split('-').map(Number);
  
    // Create a Date object at midnight in the local time zone
    const date = new Date(year, month - 1, day, 0, 0, 0, 0);

    if( year < 100 ){
        date.setFullYear( year );
    }

    return date;
}


/**
 * Converts a given date to an ISO formatted date string.
 *
 * @param date - The date to be converted. Can be a string or a Date object. Defaults to the current date if not provided.
 * @param options - The options for parsing the date. Defaults to 'plain-date'.
 * @returns The ISO formatted date string in 'en-CA' locale format.
 */
export function asIsoDate(date: string | Date = new Date(), options : DateOptions = 'plain-date' ): string {
    return date
        ? parseDate(date, options).toLocaleDateString('en-CA').padStart(10, '0')
        : '';
}

/**
 * Converts a given date to a formatted string according to the user's locale.
 *
 * @param date - The date to be formatted. It can be a Date object or a string. Defaults to the current date if not provided.
 * @param options - The formatting options to be used. Defaults to 'plain-date'.
 * @returns The formatted date string.
 */
export function asDate(date: Date | string = new Date(), options : DateOptions = 'plain-date' ) : string {
    // Format the date according to the user's locale
    return date
        ? parseDate(date, options).toLocaleDateString()
        : '';
}

export function asMonthDate(date: Date | string = new Date(), options : DateOptions = 'plain-date' ) : string {
    // Format the date according to the user's locale
    return date
        ? parseDate(date, options).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
        : '';
}

/**
 * Represents the options for handling dates.
 * 
 * - `plain-date`: Use only the date part, ignoring the time which must be midnight.
 * - `date-time`: Use both the date and time parts.
 * - `ignore-time`: Use the date part and ignore the time part.
 */
export type DateOptions = 'plain-date' | 'date-time' | 'ignore-time';

/**
 * Parses a date string or Date object and returns a Date object.
 *
 * @param date - The date to parse, either as a string or a Date object.
 * @param options - Options for parsing the date. Can be 'plain-date', 'date-time', or 'ignore-time'.
 *                  - 'plain-date': Parses the date without time.
 *                  - 'date-time': Parses the date with time.
 *                  - 'ignore-time': Ignores the time part of the date string.
 * @returns A Date object representing the parsed date.
 */
export function parseDate(date: string | Date, options: DateOptions = 'plain-date' ) : Date {
    return typeof date === 'string'
        ? (
            options === 'date-time'
                ? new Date(date)
                : parsePlainDate(date, options === 'ignore-time')
        )
        : date;
}

export const getFiscalDay = (fiscalYear: string) => {
    let day = "01";
    if (fiscalYear) {
        const arr = fiscalYear.split("/");
        if (arr.length > 0) {
            day = arr[1];
        }
    }
    return day;
}

export const getFiscalMonth = (fiscalYear: string) => {
    let month = "01";
    if (fiscalYear) {
        const arr = fiscalYear.split("/");
        if (arr.length > 0) {
            month = arr[0];
        }
    }
    return month;
}

/**
 * Represents a range of dates with a start date and an end date.
 * 
 * @typedef {Object} DateRange
 * @property {string} startdate - The start date of the range in ISO 8601 format.
 * @property {string} enddate - The end date of the range in ISO 8601 format.
 */
export interface DateRange {
    startdate: string;
    enddate: string;
    relativeFilter?: string;
};

export type RelativeDateRange = 
    | 'ThisMonth'
    | 'LastMonth'
    | 'Yesterday'
    | 'Last7Days'
    | 'Last30Days'
    | 'Last90Days'
    | 'Last6Months'
    | 'Last12Months'
    | 'ThisQuarter'
    | 'LastQuarter'
    | 'ThisYear'
    | 'LastYear'
    | 'ThisFiscalYear'
    | 'LastFiscalYear';

const getRelativeDate = (range: RelativeDateRange, fiscalYear?: string): DateRange => {
    switch (range) {
        case 'ThisMonth':
            return getThisMonth();
        case 'LastMonth':
            return getLastMonth();
        case 'Yesterday':
            return getYesterday();
        case 'Last7Days':
            return getLast7Days();
        case 'Last30Days':
            return getLast30Days();
        case 'Last90Days':
            return getLast90Days();
        case 'Last6Months':
            return getLast6Months();
        case 'Last12Months':
            return getLast12Months();
        case 'ThisQuarter':
            return getThisQuarter();
        case 'LastQuarter':
            return getLastQuarter();
        case 'ThisYear':
            return getThisYear();
        case 'LastYear':
            return getLastYear();
        case 'ThisFiscalYear':
            return getThisFiscalYear(fiscalYear!);
        case 'LastFiscalYear':
            return getLastFiscalYear(fiscalYear!);
        default:
            return getThisMonth();
    }
};

const formatDate = (momentDate: moment.Moment) => {
    return momentDate.format(QUICKSIGHT_DATE_FORMAT);
};

const getThisMonth = () : DateRange => {
    const currentMonth = moment().month();
    return {
        startdate: formatDate(moment().month(currentMonth).startOf('month')),
        enddate: formatDate(moment().month(currentMonth).endOf('month')),
    }
};

const getLastMonth = () : DateRange  => {
    const currentMonth = moment().month();
    return {
        startdate: formatDate(moment().month(currentMonth - 1).startOf('month')),
        enddate: formatDate(moment().month(currentMonth - 1).endOf('month')),
    }
};

const getYesterday = () : DateRange  => {
    return {
        startdate: formatDate(moment().subtract(1, 'day')),
        enddate: formatDate(moment().subtract(1, 'day')),
    }
};

const getLast7Days = () : DateRange  => {
    return {
        startdate: formatDate(moment().subtract(6, 'days')),
        enddate: formatDate(moment()),
        relativeFilter: "Last7Days"
    }
};

export const getLast30Days = () : DateRange  => {
    return {
        startdate: formatDate(moment().subtract(29, 'days')),
        enddate: formatDate(moment()),
        relativeFilter: "Last30Days"
    }
};

const getLast90Days = () : DateRange  => {
    return {
        startdate: formatDate(moment().subtract(89, 'days')),
        enddate: formatDate(moment()),
        relativeFilter: "Last90Days"
    }
};

export const getLast12Months = () : DateRange  => {
    const currentMonth = moment().month();
    return {
        startdate: formatDate(moment().subtract(11, 'months')),
        enddate: formatDate(moment().month(currentMonth).endOf('month')),
        relativeFilter: "Last12Months"
    }
};

export const getLast6Months = () : DateRange  => {
    const currentMonth = moment().month();
    return {
        startdate: formatDate(moment().subtract(6, 'months')),
        enddate: formatDate(moment().month(currentMonth).endOf('month')),
        relativeFilter: "Last6Months"
    }
};

const getThisQuarter = () : DateRange  => {
    const currentQuarter = moment().quarter();
    return {
        startdate: formatDate(moment().quarter(currentQuarter).startOf('quarter')),
        enddate: formatDate(moment().quarter(currentQuarter).endOf('quarter')),
    }
};

const getLastQuarter = () : DateRange  => {
    const currentQuarter = moment().quarter();
    return {
        startdate: formatDate(moment().quarter(currentQuarter - 1).startOf('quarter')),
        enddate: formatDate(moment().quarter(currentQuarter - 1).endOf('quarter')),
    }
};

const getThisYear = () : DateRange  => {
    const currentYear = moment().year();
    return {
        startdate: formatDate(moment().year(currentYear).startOf('year')),
        enddate: formatDate(moment().year(currentYear).endOf('year')),
    }
};

const getLastYear = () : DateRange  => {
    const currentYear = moment().year();
    return {
        startdate: formatDate(moment().year(currentYear - 1).startOf('year')),
        enddate: formatDate(moment().year(currentYear - 1).endOf('year')),
    }
};

export const getStartDate = (range: RelativeDateRange, fiscalYear?: string ) => {
    const { startdate } = getRelativeDate(range, fiscalYear);
    return startdate;
};

export const getEndDate = (range: RelativeDateRange, fiscalYear?: string ) => {
    const { enddate } = getRelativeDate(range, fiscalYear);
    return enddate;
};

export const getThisFiscalYear = (fiscalYear: string) : DateRange  => {
    const day = getFiscalDay(fiscalYear);
    const month = getFiscalMonth(fiscalYear);
    const currentYear = moment().year();
    const startdate = moment(`${month}/${day}/${currentYear}`, "MM/DD/YYYY");
    return {
        startdate: formatDate(startdate),
        enddate: formatDate(startdate.year(currentYear + 1).subtract(1, 'day')),
    }
}

export const getLastFiscalYear = (fiscalYear: string)  : DateRange => {
    const day = getFiscalDay(fiscalYear);
    const month = getFiscalMonth(fiscalYear);
    const currentYear = moment().year();
    const startdate = moment(`${month}/${day}/${currentYear - 1}`, "MM/DD/YYYY");
    return {
        startdate: formatDate(startdate),
        enddate: formatDate(startdate.year(currentYear).subtract(1, 'day')),
    }
}

export const convertDateToLocal = (dateString: moment.MomentInput) => {
    const utcDate = moment.utc(dateString, 'YYYY-MM-DDHH:mm:ss');
    const localDateString = moment(utcDate.toDate()).format(DATE_TIMESTAMP_FORMAT);
    return localDateString;
}

export const addNDaysToDate = (n: moment.DurationInputArg1) => {
    return formatDate(moment().add(n, 'days'))
};

export const last12MonthsArr = () => {
    const monthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let d = new Date();
    d.setDate(1);
    let lastMonths = [];
    for (let i = 0; i <= 11; i++) {
        lastMonths.push({
            label: monthName[d.getMonth()] + ' ' + d.getFullYear(),
            key: new Date(d)
        })
        d.setMonth(d.getMonth() - 1);
    }
    return lastMonths;
}

export const getLastNDays = (n: number) => {
    const dates = [...Array(n)].map((_, i) => {
        const d = new Date()
        d.setDate(d.getDate() - i)
        return {
            label: d.toLocaleDateString(),
            key: d
        }
    })
    return dates.reverse()
}

export const dateRangeOverlaps = (aStart: string | number | Date, aAnd: string | number | Date, bStart: string | number | Date, bEnd: string | number | Date) => {
    if (new Date(aStart) <= new Date(bStart) && new Date(bStart) <= new Date(aAnd)) return true; // b starts in a
    if (new Date(aStart) <= new Date(bEnd) && new Date(bEnd) <= new Date(aAnd)) return true; // b ends in a
    if (new Date(bStart) < new Date(aStart) && new Date(aAnd) < new Date(bEnd)) return true; // a in b
    return false;
}
