import { createSelector } from "reselect";
import {
    selectCanAccessClients,
    selectCanAccessUsers,
    selectCanAccessStatistics,
    selectCanAccessDashboardManagement,
    selectCanAccessPartnerManagement,
    selectCanAccessClaimAnalysis,
    selectCanAccessCMS,
    selectCanAccessRCMStatus,
    selectCanAccessClaimAudit,
    selectCanAccessExecutionLogs,
    selectCanAccessCmsModelling
} from "./access";
import { selectCanAccessPricer } from "./access/pricer";
import { selectCanAccessVendorManagement } from "./access/vendorManagement";
import { selectDashboardMenus } from "./dashboards";
import { selectHasCustomClaimStatusAccess, selectHasEstimatorAccess, selectHasPriceEstimateAccess, selectHasUploadsAccess, selectIsSuperAdmin ,selectHasRCMPageAccess} from "./session";
import { selectCanAccessDashoard } from "./access/dashboard";
import { selectHasSettingsAccess } from "./access/settings";
import { selectPayerIdMappingFlag } from "./settings";

export const selectRoleBasedMenu = createSelector(
    selectCanAccessClients,
    selectCanAccessUsers,
    selectCanAccessDashboardManagement,
    selectCanAccessStatistics,
    selectDashboardMenus,
    selectCanAccessPartnerManagement,
    selectCanAccessCMS,
    selectCanAccessPricer,
    selectCanAccessVendorManagement,
    selectIsSuperAdmin,
    selectHasUploadsAccess,
    selectCanAccessClaimAnalysis,
    selectHasEstimatorAccess,
    selectCanAccessDashoard,
    selectHasPriceEstimateAccess,
    selectHasSettingsAccess,
    selectCanAccessClaimAudit,
    selectCanAccessExecutionLogs,
    selectCanAccessCmsModelling,
    selectHasCustomClaimStatusAccess,
    selectPayerIdMappingFlag,selectHasRCMPageAccess,
    (canAccessClients, canAccessUsers, canAccessDashboardManagement, canAccessStatistics, dashboardMenus, canAccessPartnerManagement, hasCMSAccess, hasPricerAccess, hasVendorAccess, isSuperAdmin, canAccessUploads, hasCMSClaimAnalysisAccess, hasEstimatorAccess, hasDashboardAccess, hasPriceEstimateAccess, hasSettingsAccess, canAccessClaimAudit, canAccessExecutionLogs,canAccessCmsModeling, canAccessCustomClaimStatus, canPayerIdMappingAccess,canAccessRCM) => {

        // Admin SubMenu
        const adminSubMenu = [];
        if (canAccessClients) {
            adminSubMenu.push({
                title: "Clients",
                icon: "flaticon-rotate",
                page: "admin/clients",
            })
        }
        if (canAccessUsers) {
            adminSubMenu.push({
                title: "Users",
                icon: "flaticon-users",
                page: "admin/users",
            })
        }

       

        // if (canAccessUsers) {
        //     adminSubMenu.push({
        //         title: "Roles",
        //         icon: "flaticon-users",
        //         page: "admin/roles",
        //     })
        // }
        if (isSuperAdmin) {
            adminSubMenu.push({
                title: "Partners",
                icon: "flaticon-network",
                page: "admin/partners",
            })
        }
        if (canAccessStatistics) {
            adminSubMenu.push({
                title: "Statistics",
                icon: "flaticon-statistics",
                page: "admin/statistics/trim",
            })
        }
        if (canAccessDashboardManagement) {
            adminSubMenu.push({
                title: "Dashboards",
                icon: "flaticon-imac",
                page: "admin/dashboards",
                // submenu: [{
                //     title: "Dashboards",
                //     icon: "flaticon-graphic-1",
                //     page: "admin/dashboards",
                // },
                // {
                //     title: "Data Sources",
                //     icon: "flaticon-interface-3",
                //     page: "admin/dashboardDataSources",
                // },
                // {
                //     title: "Filters",
                //     icon: "flaticon-interface-7",
                //     page: "admin/dashboardfilters",
                // },
                // {
                //     title: "Types",
                //     icon: "flaticon-interface",
                //     page: "admin/dashboardTypes",
                // }
                // ]
            })
        }
        if (isSuperAdmin) {
            adminSubMenu.push({
                title: "API Keys",
                icon: "fas fa-key",
                page: "admin/api-key-management",
            })
        }
        if(isSuperAdmin){
            adminSubMenu.push({
                title: "API Applications",
                icon: "fas fa-key",
                page: "admin/app-id-management",
            })
        }
        let dashboardMenu = [];
        if (hasDashboardAccess) {
            dashboardMenu.push({
                title: "Dashboard",
                root: true,
                icon: "flaticon2-architecture-and-city",
                page: "dashboard",
                translate: "MENU.DASHBOARD",
                bullet: "dot",
                submenu: dashboardMenus
            })
        }

        let baseMenu = {
            header: {
                self: {},
                items: []
            },
            aside: {
                self: {},
                items: [...dashboardMenu],
            }
        }


        // if (canAccessPartnerManagement) {
        //     baseMenu = {
        //         ...baseMenu,
        //         ...baseMenu.aside.items[1].submenu.push({
        //             title: "Partners",
        //             icon: "flaticon-network",
        //             page: "admin/partners",
        //         })
        //     }
        // }

        let cmsSubMenu = [];

        if(canAccessRCM)
            {
                cmsSubMenu = cmsSubMenu.concat(
                    {
                        title: "RCM Status Report",
                        icon: "flaticon-analytics",
                        page: "cms/rcm-status",
                    },
                )
            }

        if (isSuperAdmin || hasCMSAccess) {
            cmsSubMenu.push({
                title: "Summary Dashboard",
                icon: "fas fa-chart-line",
                page: "cms/summary-dashboard",
            })
        }
        
        if (hasCMSClaimAnalysisAccess) {
            cmsSubMenu.push({
                title: "Claim Analysis",
                icon: "flaticon-analytics",
                page: "cms/claimAnalysis",
            });

            cmsSubMenu.push({
                title: "Claim Lookup",
                icon: "flaticon-analytics",
                page: "cms/claimLookup",
            });
        }




        let claimExecutionLogsMenu = [];
        if (isSuperAdmin || canAccessExecutionLogs) {
            claimExecutionLogsMenu.push({
                title: "Execution Logs",
                icon: "flaticon-time",
                page: "cms/executions",
            });
        }

        let claimAuditMenu = [];
        if (isSuperAdmin || canAccessClaimAudit) {
            claimAuditMenu.push({
                title: "Claim Audits",
                icon: "fas fa-diagnoses",
                page: "cms/claimAudits",
            });
            

            if(isSuperAdmin && (window.location.host==='dev.slicedhealth.com' || window.location.host.includes('localhost:')))
            {
                claimAuditMenu.push({
                    title: "Old Model Simulator",
                    icon: "fas fa-calculator",
                    page: "cms/simulator",
                },
                {
                    title: "New Model Simulator",
                    icon: "fas fa-calculator",
                    page: "cms/new-simulator",
                })
            }
            
        }

        if(canAccessCmsModeling)
        {
            claimAuditMenu.push({
                title: "Contract Models",
                icon: "fas fa-clone",
                page: "cms/modeling",
            })
        }

        let cmsConfigSubMenu = [
            {
                title: "Code Services",
                icon: "flaticon-signs",
                page: "cms/codeservice",
            },

            {
                title: "Search Criteria",
                icon: "flaticon-search",
                page: "cms/criteria",
            },
            {
                title: "NPI Roles",
                icon: "fa fa-file-invoice",
                page: "cms/npi-roles",
            },
            {
                title: "Modifiers",
                icon: "fas fa-edit",
                page: "cms/modifiers",
            },
            {
                title: "IPPS",
                icon: "far fa-handshake",
                page: "cms/ipps",
            },
            {
                title: "OPPS",
                icon: "far fa-handshake",
                page: "cms/opps",
            },
            {
                title: "EAPG",
                icon: "far fa-handshake",
                page: "cms/eapg",
            },
            {
                title: "Contract Families",
                icon: "flaticon-graphic-1",
                page: "cms/family",
            },
        ];

        if(hasCMSAccess && canAccessCustomClaimStatus) {
            cmsConfigSubMenu.push({
                title: "Claim Statuses",
                icon: "far fa-check-circle",
                page: "cms/claimstatuses",
            });
        }

        if (isSuperAdmin || hasCMSAccess) {
            cmsSubMenu = cmsSubMenu.concat(
                claimExecutionLogsMenu,
                {
                    title: "Contracts",
                    icon: "flaticon-graphic-1",
                    page: "cms/contracts",
                    altPage:"cms/contract"
                },

                // {
                //     title: "Contracts V2",
                //     icon: "flaticon-graphic-1",
                //     page: "cms/contracts",
                // },
                {
                    title: "Fee Schedules",
                    icon: "flaticon-interface-3",
                    page: "cms/feeschedules",
                },
                // {
                //     title: "Payments",
                //     icon: "flaticon-signs",
                //     page: "contract/payments",
                // },
                {
                    title: "Facilities",
                    icon: "flaticon-placeholder-1",
                    page: "cms/facilities",
                },
                ...claimAuditMenu,
                {
                    title: "Payers",
                    root: true,
                    icon: "fas fa-university",
                    page: "cms/payers",
                    //translate: "MENU.DASHBOARD",
                    bullet: "dot",
                    submenu: [
                        {
                            title: "Claim Payers",
                            icon: "fas fa-money-check-alt",
                            page: "cms/payers/payerinfo",
                        },
                        {
                            title: "Remit Payers",
                            icon: "fas fa-money-bill",
                            page: "cms/payers/remitPayers",
                        },
                        {
                            title: "Payer Contract",
                            icon: "fas fa-file-contract",
                            page: "cms/payers/contracts",
                        },
                        {
                            title: "Payer Scorecard",
                            icon: "fas fa-regular fa-clipboard-list",
                            page: "cms/payers/scorecard",
                        },
                        {
                            title: "Plan Management",
                            icon: "fas fa-regular fa-tasks",
                            page: "cms/payers/planManagement",
                        }
                    ]
                },
            
                {
                    title: "Configuration",
                    root: true,
                    icon: "flaticon-settings",
                    page: "cms/codeservice",
                    bullet: "dot",
                    submenu: cmsConfigSubMenu
                }
            )
        }


        if (hasCMSClaimAnalysisAccess) {
            baseMenu = {
                ...baseMenu,
                aside: {
                    ...baseMenu.aside,
                    items: [
                        ...baseMenu.aside.items,
                        {
                            title: "Contract Management",
                            root: true,
                            icon: "far fa-handshake",
                            page: "contract",
                            //translate: "MENU.DASHBOARD",
                            bullet: "dot",
                            submenu: cmsSubMenu
                        }
                    ]
                }
            }
        }

        if (hasVendorAccess) {
            baseMenu = {
                ...baseMenu,
                aside: {
                    ...baseMenu.aside,
                    items: [
                        ...baseMenu.aside.items,
                        {
                            title: "Vendor Management",
                            root: true,
                            icon: "fas fa-store",
                            page: "vendor",
                            //translate: "MENU.DASHBOARD",
                            bullet: "dot",
                            submenu: [
                                {
                                    title: "Vendor Contracts",
                                    icon: "fas fa-file-contract",
                                    page: "vendor",
                                },
                            ]
                        }
                    ]
                }
            }
        }

        let estimatorMenu = [];
        if (hasPriceEstimateAccess) {
            estimatorMenu = [{
                title: "Charge Master",
                icon: "fas fa-dollar-sign",
                page: "estimator/chargeMaster",
            },
            {
                title: "Associated",
                root: true,
                icon: "fas fa-window-restore",
                page: "estimator/associated",
                //translate: "MENU.DASHBOARD",
                bullet: "dot",
            }
        ]
        }

        if (hasPriceEstimateAccess && canPayerIdMappingAccess) {
            estimatorMenu.push({
                title: "Payer ID Mapping",
                root: true,
                icon: "fas fa-file-medical",
                page: "estimator/payeridmapping",
                bullet: "dot",
            });
        }
        if (hasEstimatorAccess) {
            baseMenu = {
                ...baseMenu,
                aside: {
                    ...baseMenu.aside,
                    items: baseMenu.aside.items.concat({
                        title: "Estimator",
                        root: true,
                        icon: "fas fa-calculator",
                        page: "estimator",
                        //translate: "MENU.DASHBOARD",
                        bullet: "dot",
                        submenu: [
                            {
                                title: "Estimator",
                                icon: "fas fa-calculator",
                                page: "estimator/history",
                            },
                            ...estimatorMenu
                        ]
                    })
                }
            }
        }
        if (canAccessClients || hasPricerAccess) {
            baseMenu = {
                ...baseMenu,
                aside: {
                    ...baseMenu.aside,
                    items: [
                        ...baseMenu.aside.items,
                        {
                            title: "Pricer",
                            root: true,
                            icon: "fas fa-dollar-sign",
                            translate: "MENU.PRICER",
                            bullet: "dot",
                            submenu: [
                                {
                                    title: "Look and Feel",
                                    root: true,
                                    icon: "fas fa-palette",
                                    page: "pricer/brandconfiguration",
                                    //translate: "MENU.DASHBOARD",
                                    bullet: "dot",
                                },
                                {
                                    title: "Payer Info",
                                    icon: "fas fa-money-check-alt",
                                    page: "pricer/payerInfo",
                                },
                                {
                                    title: "Charge Master",
                                    icon: "fas fa-dollar-sign",
                                    page: "pricer/chargeMaster",
                                },
                                {
                                    title: "Shoppable",
                                    root: true,
                                    icon: "fa fa-plus-square",
                                    page: "pricer/shoppable",
                                    bullet: "dot",
                                },
                                {
                                    title: "Associated",
                                    root: true,
                                    icon: "fas fa-window-restore",
                                    page: "pricer/associated",
                                    //translate: "MENU.DASHBOARD",
                                    bullet: "dot",
                                },
                                {
                                    title: "Negotiated Rates",
                                    root: true,
                                    icon: "far fa-check-square",
                                    page: "pricer/allowables",
                                    //translate: "MENU.DASHBOARD",
                                    bullet: "dot",
                                },
                                {
                                    title: "Configuration",
                                    root: true,
                                    icon: "flaticon-settings",
                                    page: "pricer/configuration",
                                    bullet: "dot",
                                },
                                {
                                    title: "Hospital Services",
                                    root: true,
                                    icon: "flaticon-medical",
                                    page: "pricer/hospitalservices",
                                    bullet: "dot",
                                },
                                {
                                    title: "MRF Modifiers",
                                    root: true,
                                    icon: "fas fa-edit",
                                    page: "pricer/modifiers",
                                    bullet: "dot",
                                },
                                {
                                    title: "Execution Logs",
                                    icon: "flaticon-time",
                                    page: "pricer/executions",
                                }
                            ],
                        }
                    ]
                }
            }
        }

        if (canAccessClients || canAccessUsers || canAccessDashboardManagement || canAccessStatistics) {
            baseMenu = {
                ...baseMenu,
                aside: {
                    ...baseMenu.aside,
                    items: [
                        ...baseMenu.aside.items,
                        {
                            title: "Administration",
                            root: true,
                            icon: "flaticon2-gear",
                            translate: "MENU.ADMINISTRATION",
                            bullet: "dot",
                            submenu: adminSubMenu,
                        }
                    ]
                }
            };
        }

        if (isSuperAdmin || canAccessUploads) {
            baseMenu = {
                ...baseMenu,
                aside: {
                    ...baseMenu.aside,
                    items: [
                        ...baseMenu.aside.items,
                        {
                            title: "File Management",
                            root: true,
                            icon: "fas fa-file",
                            page: "uploads",
                            //translate: "MENU.DASHBOARD",
                            bullet: "dot",
                            submenu: [
                                {
                                    title: "Uploads",
                                    icon: "fas fa-file-upload",
                                    page: "file-management/uploads",
                                },
                                {
                                    title: "Executions",
                                    icon: "flaticon-time",
                                    page: "file-management/fileExecutions",
                                }
                            ]
                        }
                    ]
                }
            }
        }

        if (isSuperAdmin) {
            baseMenu = {
                ...baseMenu,
                aside: {
                    ...baseMenu.aside,
                    items: [
                        ...baseMenu.aside.items,
                        {
                            title: "Control Room",
                            root: true,
                            icon: "fas fa-laptop-house",
                            page: "controlRoom",
                            //translate: "MENU.DASHBOARD",
                            bullet: "dot",
                            submenu: [
                                {
                                    title: "Job Statistics",
                                    icon: "flaticon-statistics",
                                    page: "controlRoom/jobStatistics",
                                },
                                {
                                    title: "S3 Listners",
                                    icon: "fas fa-assistive-listening-systems",
                                    page: "controlRoom/s3Listners",
                                },
                                {
                                    title: "Health Check",
                                    icon: "fas fa-chart-bar",
                                    page: "controlRoom/healthCheck",
                                },
                                {
                                    title: "QS Datasets",
                                    icon: "fas fa-chart-line",
                                    page: "controlRoom/qs-dataset",
                                },
                            ]
                        }
                    ]
                }
            }
        }

        if (hasSettingsAccess) {
            baseMenu = {
                ...baseMenu,
                aside: {
                    ...baseMenu.aside,
                    items: [
                        ...baseMenu.aside.items,
                        {
                            title: "Settings",
                            icon: "flaticon-cogwheel-1",
                            page: "configuration-settings",
                        }
                    ]
                }
            }
        }

        if (hasSettingsAccess) {
            baseMenu = {
                ...baseMenu,
                aside: {
                    ...baseMenu.aside,
                    items: [
                        ...baseMenu.aside.items,
                        {
                            title: "Branding",
                            icon: "fas fa-palette",
                            page: "configuration-branding",
                        }
                    ]
                }
            }
        }

        if (hasSettingsAccess) {
            baseMenu = {
                ...baseMenu,
                aside: {
                    ...baseMenu.aside,
                    items: [
                        ...baseMenu.aside.items,
                        {
                            title: "About",
                            root: true,
                            icon: "fas fa-info-circle",
                            page: "about",
                            bullet: "dot",
                            submenu: [
                                {
                                    title: "Version Updates",
                                    icon: "flaticon-layer",
                                    page: "about/version",
                                }
                            ]
                        }
                    ]
                }
            }
        }



        return baseMenu;
    }
)