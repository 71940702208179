import React, { useEffect,useState } from 'react'

import {
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Tooltip,
    Button,
    Chip,
    Badge,
    styled,
    InputAdornment,
    TextField
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from "@mui/icons-material/Search";
import { DateRangeControl } from '../../../common';
import CloseIcon from '@mui/icons-material/Close';
import { getStartDate,getEndDate } from '../../../util/date';
import PrimaryFiltersDisregardDialog from './PrimaryFiltersDisregardDialog/PrimaryFiltersDisregardDialog';

const useStyles = makeStyles(theme => ({
    switch: {
        marginRight: 0,
        marginTop: 15,
        marginLeft: 0,
        '@media (max-width: 1440px)': {
            width: '95px',
        },
        '& .MuiTypography-root':{
            fontSize:"11px"
        },
            flexDirection:"column-reverse"
    },
    gridMDMargin: {
        '@media (max-width: 1023px)': {
            paddingLeft: 10,
        }
    },
    formControlClaim: {
        margin: theme.spacing(1),
        width: 55
    },
    formControlPaid: {
        margin: theme.spacing(1),
        width: 70
    },
    formControlStatus: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: 4,
        marginRight: 4,
        width: 140,
        '@media (max-width: 1645px)': {
            width: 79,
        },
        '@media (max-width: 1366px)': {
            width: 60,
        }
    },
    width:{width:'62px'},
    formControlVarianceStatus: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: 4,
        marginRight: 4,
        width: 110,
        '@media (max-width: 1645px)': {
            width: 79,
        },
        '@media (max-width: 1366px)': {
            width: 60,
        }
    },
    formControlState: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: 4,
        marginRight: 4,
        width: 90,
        '@media (max-width: 1645px)': {
            width: 79,
        },
    },
    accordionFormControl: {
        margin: theme.spacing(2),
        width: '80%'
    },
    filterBorderBottom: {
        border: '1px solid #EBEDF2'
    },
    claimFilterAccordion: {
        position: 'absolute',
        width: '460px',
        minHeight: '210px',
        right: '-1px',
        top: '67px',
        backgroundColor: '#fff',
        zIndex: 111,
        boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
    },
    filterIcon: {
        '@media (min-width: 1366px)': {
            display: 'none'
        },
    },
    defaultVisibleFilters: {
        '@media (max-width: 1365px)': {
            display: 'none'
        },
    },
    advFilterBtn: {
        display: 'grid',
        alignItems: 'flex-end',
        paddingBottom: "11px !important",
        '& .MuiButtonBase-root': {
            textTransform: 'capitalize !important'
        }
    },
    ackStatus:{
        width:'100px',
        '@media (max-width: 1500px)': {
            width: '80px',
        },
    },
    claimSearchField:{
            '& .MuiFormHelperText-root': {
            fontSize:"9px"
            }
    }
}));
const unpaidExcludedFilters = ['remitDate','remitdate', 'chargedAmount', 'actualPay', 'variance', 'reasoncode','isDenial','recoveredAmount','isDenial'];

const filterFields = [{
    label: 'Remit Date',
    value: 'remitdate'
}, {
    label: 'Service Date',
    value: 'servicedate',
}];

interface ClaimAnalysisTopFiltersProps {
    intl : any;
    pending: boolean;
    customFilter: Array<{ field: string; value: string }>;
    handleRefresh: () => void;
    relativeFilter: string;
    relativeFilterValue: string;
    significanceFlag: boolean;
    tableFilters:any[];
    ignoreDueToQuickSearch:boolean;
    pageState:any;
    actions: {
        setMulti: (params: { list?: any[]; significance?: boolean; customFilter?: any[]; isCustomFilterSetEmpty?: boolean,filter?:any[];searchTerm?:any ;showIgnoredFilterDialog?:boolean;ignoreAcknowledgement?:boolean}) => void;
        setRelativeFilter: (value: string) => void;
    };
}

function ClaimAnalysisTopFilters({
    pending,
    customFilter,
    handleRefresh,
    relativeFilter,
    pageState,
    relativeFilterValue,
    ignoreDueToQuickSearch,
    significanceFlag,
    tableFilters,
    actions: { setMulti, setRelativeFilter }
}: ClaimAnalysisTopFiltersProps) {
    const [significance, setSignificance] = useState(true);
    const [isSignificanceDisabled, setSignificanceDisabled] = useState(false);
    const [isFirst, setIsFirst] = useState(true);

    const [acknowledgeFilter, setAcknowledgeFilter] = useState('both');
    const [followUpFilter, setFollowUpFilter] = useState('both');
    const [isPaidFilter, setPaidFilter] = useState<string | boolean>('both');
    const [denialFilter, setDenialFilter] = useState('both');
    const [contractState, setContractState] = useState('both');
    const [isAccordionOpen, setAccordionOpen] = useState(false);
    const [disregardDialog, setDisregardDialog] = useState(false);
    const [claimNumber, setClaimNumber] = useState("");


    let selectedDate= customFilter.filter((cf) => {
        return filterFields.find((f) => {
            return cf.field === f.value;
        });
    }) as any

    
    const startEF = selectedDate.find(item => item.equator === 'greater_than_or_equals_to');
    const endEF = selectedDate.find(item => item.equator === 'less_than_or_equals_to');


    const classes = useStyles();

    useEffect(() => {
        setSignificance(significanceFlag);
    }, [significanceFlag])

    useEffect(() => {
        customFilter.forEach(filter => {
            if (filter.field === 'acknowledged' && acknowledgeFilter !== filter.value) {
                setAcknowledgeFilter(filter.value);
            }
            if (filter.field === 'isPaid' && isPaidFilter !== filter.value) {
                setPaidFilter(filter.value);
            }
            if (filter.field === 'flag' && followUpFilter !== filter.value) {
                setFollowUpFilter(filter.value);
            }
            if (filter.field === 'isDenial' && denialFilter !== filter.value) {
                setDenialFilter(filter.value);
            }
            if (filter.field === 'contract.contractState.name' && contractState !== filter.value) {
                setContractState(filter.value?.toLowerCase());
            }
        });
            setClaimNumber(pageState?.searchTerm);
        
            }, [customFilter]);

    useEffect(() => {
        if (!isFirst) {
            setTimeout(() => {
                setMulti({
                    list: [],
                    significance
                });
                setSignificanceDisabled(false);
            }, 200);
        }
        setIsFirst(false);

    }, [significance, setMulti])


    const handleSignificanceChange = (event) => {
        setSignificanceDisabled(true);
        setSignificance(event.target.checked);
    }


    const handleClaimNumberSearchChange = (event) => {
        setClaimNumber(event.target.value);

        if (event.target.value?.length === 0) {
            setMulti({
                searchTerm: event.target.value,
                showIgnoredFilterDialog: false,
                ignoreAcknowledgement: false,
            });
            handleRefresh();
            return;
        }

        setMulti({ searchTerm: event.target.value });
    };

    const handleAcknowledgedFilterChange = (event) => {

        const filterValue = event.target.value;
        setAcknowledgeFilter(filterValue);

        if (filterValue !== 'both') {
            const cf = {
                field: 'acknowledged',
                equator: 'equals',
                value: filterValue,
            }
            if (customFilter && customFilter.length > 0) {
                const existingFilter = customFilter.filter(x => x.field !== 'acknowledged');
                setMulti({
                    list: [],
                    customFilter: [...existingFilter, cf]
                });
            } else {
                setMulti({
                    list: [],
                    customFilter: [cf]
                });
            }

        } else {
            if (customFilter && customFilter.length > 0) {
                const existingFilter = customFilter.filter(x => x.field !== 'acknowledged');
                if (existingFilter.length === 0) {
                    setMulti({
                        isCustomFilterSetEmpty: true,
                        list: [],
                        customFilter: existingFilter
                    });
                } else {
                    setMulti({
                        list: [],
                        customFilter: existingFilter
                    });
                }
            }
        }
        handleRefresh();
    };

    const handleFollowupFilterChange = (event) => {

        const filterValue = event.target.value;
        setFollowUpFilter(filterValue);

        if (filterValue !== 'both') {
            const cf = {
                field: 'flag',
                equator: 'equals',
                value: filterValue,
            }
            if (customFilter && customFilter.length > 0) {
                const existingFilter = customFilter.filter(x => x.field !== 'flag');
                setMulti({
                    list: [],
                    customFilter: [
                        ...existingFilter,
                        cf,
                    ]
                });
            } else {
                setMulti({
                    list: [],
                    customFilter: [cf]
                });
            }

        } else {
            if (customFilter && customFilter.length > 0) {
                const existingFilter = customFilter.filter(x => x.field !== 'flag');
                if (existingFilter.length === 0) {
                    setMulti({
                        isCustomFilterSetEmpty: true,
                        list: [],
                        customFilter: existingFilter
                    });
                } else {
                    setMulti({
                        list: [],
                        customFilter: existingFilter
                    });
                }
            }
        }
        handleRefresh();
    };

    const handleDenialFilterChange = (event) => {
        const filterValue = event.target.value;
        setDenialFilter(filterValue);

        if (filterValue !== 'both') {
            const cf = {
                field: 'isDenial',
                equator: 'equals',
                value: filterValue,
            }
            if (customFilter && customFilter.length > 0) {
                const existingFilter = customFilter.filter(x => x.field !== 'isDenial');
                setMulti({
                    list: [],
                    customFilter: [
                        ...existingFilter,
                        cf,
                    ]
                });
            } else {
                setMulti({
                    list: [],
                    customFilter: [cf]
                });
            }

        } else {
            if (customFilter && customFilter.length > 0) {
                const existingFilter = customFilter.filter(x => x.field !== 'isDenial');
                if (existingFilter.length === 0) {
                    setMulti({
                        isCustomFilterSetEmpty: true,
                        list: [],
                        customFilter: existingFilter
                    });
                } else {
                    setMulti({
                        list: [],
                        customFilter: existingFilter
                    });
                }
            }
        }
        handleRefresh();
    };

    const handlePaidFilterChange = (event) => {
        const filterValue = event.target.value;
        const isUnpaid=filterValue==="false"
        let tempFilters=[]

        setPaidFilter(filterValue);
        if(isUnpaid)
        {
            setDenialFilter("both")
            setDisregardDialog(true)   
            tempFilters = [...tableFilters];
            tempFilters = tempFilters?.filter(
                (item) => unpaidExcludedFilters.includes(item.field) === false
            );
        }

        if (filterValue !== 'both') {
            const cf = {
                field: 'isPaid',
                equator: 'equals',
                value: filterValue,
            }
            if (customFilter && customFilter.length > 0) {
                const existingFilter = customFilter.filter(x => x.field !== 'isPaid');
                if (isUnpaid) {
                    setMulti({
                        list: [],
                        customFilter: [...existingFilter, cf]?.filter(
                            (item) => unpaidExcludedFilters.includes(item.field) === false
                        ),
                        filter: tempFilters,
                    });
                } else {
                    setMulti({
                        list: [],
                        customFilter: [...existingFilter, cf],
                    });
                }
            } else {
                if (isUnpaid) {
                    setMulti({
                        list: [],
                        customFilter: [cf]?.filter(
                            (item) => unpaidExcludedFilters.includes(item.field) === false
                        ),
                        filter: tempFilters,
                    });
                } else {
                    setMulti({
                        list: [],
                        customFilter: [cf],
                    });
                }
            }

        } else {
            if (customFilter && customFilter.length > 0) {
                const existingFilter = customFilter.filter(x => x.field !== 'isPaid');
                if (existingFilter.length === 0) {
                    setMulti({
                        isCustomFilterSetEmpty: true,
                        list: [],
                        customFilter: existingFilter
                    });
                } else {
                    setMulti({
                        list: [],
                        customFilter: existingFilter
                    });
                }
            }
        }
        handleRefresh();
    };

    const handleStateChange = (event) => {
        const filterValue = event.target.value;
        setContractState(filterValue.toLowerCase());

        if (filterValue !== 'both') {
            const cf = {
                field: 'contract.contractState.name',
                equator: 'equals',
                value: filterValue,
            }
            if (customFilter && customFilter.length > 0) {
                const existingFilter = customFilter.filter(x => x.field !== 'contract.contractState.name');
                setMulti({
                    list: [],
                    customFilter: [
                        ...existingFilter,
                        cf,
                    ]
                });
            } else {
                setMulti({
                    list: [],
                    customFilter: [cf]
                });
            }

        } else {
            if (customFilter && customFilter.length > 0) {
                const existingFilter = customFilter.filter(x => x.field !== 'contract.contractState.name');
                if (existingFilter.length === 0) {
                    setMulti({
                        isCustomFilterSetEmpty: true,
                        list: [],
                        customFilter: existingFilter
                    });
                } else {
                    setMulti({
                        list: [],
                        customFilter: existingFilter
                    });
                }
            }
        }
        handleRefresh();
    }

    const setDates = (beginDate, endDate, isDateFilterActive, customField, relativeFilter) => {
        if (isDateFilterActive) {
            const dateFilter = [
                {
                    field: customField,
                    equator: 'greater_than_or_equals_to',
                    value: beginDate,
                    relativeFilter
                },
                {
                    field: customField,
                    equator: 'less_than_or_equals_to',
                    value: endDate,
                    relativeFilter
                }
            ];

            const existingFilters = customFilter.filter((cf) => {
                return !filterFields.find((f) => {
                    return cf.field === f.value;
                });
            });
            setMulti({
                list: [],
                customFilter: [
                    ...existingFilters,
                    ...dateFilter,
                ]
            });
        } else {
            const existingFilters = customFilter.filter((cf) => {
                return !filterFields.find((f) => {
                    return cf.field === f.value;
                });
            });
            if (existingFilters.length === 0) {
                setMulti({
                    isCustomFilterSetEmpty: true,
                    list: [],
                    customFilter: existingFilters
                });
            } else {
                setMulti({
                    list: [],
                    customFilter: existingFilters
                });
            }
        }
    }

    const handleAccordionOpen = () => {
        setAccordionOpen((prevState) => !prevState);
    }

    const handleAccordionClose = () => {
        setAccordionOpen(false);
    }

    const handleCloseDisregardDialog = () => {
        setDisregardDialog(false)   
        
    }
    const switchToServiceDate = () => {
        handleCloseDisregardDialog()
        setDates(
            startEF ? startEF?.value : getStartDate("Last12Months"),
            endEF ? endEF?.value : getEndDate("Last12Months"),
            true,
            "servicedate",
            selectedDate[0].relativeFilter ?? "Last12Months"
        );
        handleRefresh()
    };



    return (
        (<div>
            <Grid container spacing={1} className={classes.gridMDMargin}>
                <Grid item>
                    <DateRangeControl
                        refresh={handleRefresh}
                        filterName="Date Filter"
                        setDates={setDates}
                        fields={isPaidFilter==="false" ? [filterFields[1]]:filterFields}
                        customFilter={customFilter}
                        hasCustomField={true}
                        relativeFilter={relativeFilter}
                        disabled={pending || ignoreDueToQuickSearch}
                        isForClaims={true}
                        relativeFilterValue={relativeFilterValue}
                        setRelativeFilter={setRelativeFilter}
                    />
                </Grid>
                <Grid item>
                    <FormControl className={classes.formControlClaim}>
                        <InputLabel id="demo-simple-select-label">Paid</InputLabel>
                        <Select
                            disabled={pending || ignoreDueToQuickSearch}
                            labelId="Paid-label"
                            id="Paid"
                            label="isPaid"
                            value={isPaidFilter}
                            onChange={handlePaidFilterChange}
                        >
                            <MenuItem value="true">Yes</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                            <MenuItem value="both">Both</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl className={classes.formControlVarianceStatus}>
                        <InputLabel id="demo-simple-select-label">Variance Status</InputLabel>
                        <Select
                            disabled={pending || (!pending && isPaidFilter==="false") || ignoreDueToQuickSearch}
                            labelId="followup-label"
                            id="DenailFilter"
                            label="DenailFilter"
                            value={denialFilter || ignoreDueToQuickSearch}
                            onChange={handleDenialFilterChange}
                        >
                            <MenuItem value="true">Denials</MenuItem>
                            <MenuItem value="false">Underpayments</MenuItem>
                            <MenuItem value="both">Both</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item className={classes.filterIcon}>
                    <Grid container className='align-items-center h-100'>
                        <Tooltip title='View remaining filters'>
                            <IconButton onClick={handleAccordionOpen} size="large">
                                <i className="fas fa-filter font-16"></i>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>


                <Grid item className={classes.defaultVisibleFilters}>
                    <FormControl className={`${classes.formControlStatus} ${classes.ackStatus}`}>
                        <InputLabel id="demo-simple-select-label">Ack. Status</InputLabel>
                        <Select
                            disabled={pending || ignoreDueToQuickSearch}
                            labelId="Acknowledged-label"
                            id="Acknowledged"
                            label="Acknowledged"
                            value={acknowledgeFilter}
                            onChange={handleAcknowledgedFilterChange}
                        >
                            <MenuItem value="true">Acknowledged</MenuItem>
                            <MenuItem value="false">Unacknowledged</MenuItem>
                            <MenuItem value="both">Both</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item className={classes.defaultVisibleFilters}>
                    <FormControl className={`${classes.formControlStatus} ${classes.width}`}>
                        <InputLabel id="demo-simple-select-label">Follow Up</InputLabel>
                        <Select
                            disabled={pending || ignoreDueToQuickSearch}
                            labelId="followup-label"
                            id="FollowUp"
                            label="FollowUp"
                            value={followUpFilter}
                            onChange={handleFollowupFilterChange}
                        >
                            <MenuItem value="true">Yes</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                            <MenuItem value="both">Both</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item className={classes.defaultVisibleFilters}>
                    <FormControl className={classes.formControlState}>
                        <InputLabel id="ContractState">Contract State</InputLabel>
                        <Select
                            disabled={pending || ignoreDueToQuickSearch}
                            labelId="ContractState"
                            id="ContractState"
                            label="Contract State"
                            value={contractState}
                            onChange={handleStateChange}
                        >
                            <MenuItem value="both">All</MenuItem>
                            <MenuItem value='draft'>Draft</MenuItem>
                            <MenuItem value='finalized'>Finalized</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <TextField
                        label="Claim Search"
                        onKeyUp={(event)=>event.key==="Enter" && claimNumber.length>2 && handleRefresh()}
                        disabled={pending }
                        id="standard-start-adornment"
                        className={classes.claimSearchField}
                        value={claimNumber}
                        onChange={handleClaimNumberSearchChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ m: 1, width: "110px" }}
                        placeholder='Name/#'
                        InputProps={{
                            endAdornment: <SearchIcon sx={{fontSize:"14px",opacity:0.7}}/>,
                        }}
                        variant="standard"
                        helperText="Minimum 3 Characters"
                    />
                </Grid>
                <Grid item className={classes.defaultVisibleFilters}    >
                    <FormControlLabel
                        disabled={pending || isSignificanceDisabled || (!pending && isPaidFilter==="false") || ignoreDueToQuickSearch}
                        className={classes.switch}
                        control={
                            <Switch
                                checked={significance}
                                size="small"
                                color="primary"
                                onChange={handleSignificanceChange}
                            />
                        }
                        label="Significant Variance?"
                        labelPlacement="start"
                    />
                </Grid>

                <Grid className={`${classes.claimFilterAccordion} ${!isAccordionOpen && 'd-none'}`}>
                    <Grid container className={`justify-content-between align-items-center p-3 ${classes.filterBorderBottom}`}>
                        <h5 className='m-0'>Filter</h5>
                        <IconButton onClick={handleAccordionClose} className='p-2' size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <FormControl className={classes.accordionFormControl}>
                                <InputLabel id="demo-simple-select-label">Ack. Status</InputLabel>
                                <Select
                                    disabled={pending || ignoreDueToQuickSearch}
                                    labelId="Acknowledged-label"
                                    id="Acknowledged"
                                    label="Acknowledged"
                                    value={acknowledgeFilter}
                                    onChange={handleAcknowledgedFilterChange}
                                >
                                    <MenuItem value="true">Acknowledged</MenuItem>
                                    <MenuItem value="false">Unacknowledged</MenuItem>
                                    <MenuItem value="both">Both</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl className={classes.accordionFormControl} > 
                                <InputLabel id="demo-simple-select-label">Follow Up</InputLabel>
                                <Select
                                    disabled={pending || ignoreDueToQuickSearch}
                                    labelId="followup-label"
                                    id="FollowUp"
                                    label="FollowUp"
                                    value={followUpFilter}
                                    onChange={handleFollowupFilterChange}
                                >
                                    <MenuItem value="true">Yes</MenuItem>
                                    <MenuItem value="false">No</MenuItem>
                                    <MenuItem value="both">Both</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={classes.accordionFormControl}>
                                <InputLabel id="ContractState">Contract State</InputLabel>
                                <Select
                                    disabled={pending || ignoreDueToQuickSearch}
                                    labelId="ContractState"
                                    id="ContractState"
                                    label="Contract State"
                                    value={contractState}
                                    onChange={handleStateChange}
                                >
                                    <MenuItem value="both">All</MenuItem>
                                    <MenuItem value='draft'>Draft</MenuItem>
                                    <MenuItem value='finalized'>Finalized</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                disabled={pending || isSignificanceDisabled || (!pending && isPaidFilter==="false") || ignoreDueToQuickSearch}
                                className={classes.switch}
                                control={
                                    <Switch
                                        checked={significance}
                                        size="small"
                                        color="primary"
                                        onChange={handleSignificanceChange}
                                    />
                                }
                                label="Significant Variance?"
                                labelPlacement="start"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <PrimaryFiltersDisregardDialog open={disregardDialog} handleClose={handleCloseDisregardDialog} ef={
               selectedDate
            } switchDate={switchToServiceDate}/>
        </div>)
    );
}

const NumberChip = styled('div')({
    backgroundColor: '#f5f5f5',
    padding: '0px 8px',
    borderRadius: '16px',
    fontSize: '12px !important',
    color: '#000',
    marginLeft: '5px'
})

export default ClaimAnalysisTopFilters;
