export const PAGINATION_PAGE_LENGTH = 100;

export const INITIAL_TABLE = {
    filter: [],
    order: null,
    sort: null,
    selectedSort: null,
    selected: [],
    isColumnDialogOpen: false,
    isReorderDialogOpen: false
}

export const INITIAL_TABLE_PAGED = {
    filter: [],
    customFilter: [] as any[],
    sort: [],
    selected: [],
    page: { recordsPerPage: PAGINATION_PAGE_LENGTH },
    timeFilter: {
        lowRange: 70,
        highRange: 100,
    },
    list: [],
    pending: false,
    selectedFilter: null,
    waitForCriteriaFilter: true,
    waitForTopLevelFilter: true,
    significance: true,
    isCustomFilterSetEmpty: false,
    isColumnDialogOpen: false,
    isReorderDialogOpen: false,
    isSearchApiCompleted: false,
    highlightRowId: null,
}

export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/
export const CLIENT_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^\W_]{12,20}$/

export const EMAIL_REGEX = /^[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;
export const ALPHABETS_REGEX = /^[A-Za-z]+$/;
export const LOWER_CASE_ALPHABETS_REGEX = /^[a-z0-9_\-]+$/;
export const ALPHABETS_WITH_SPACE_REGEX = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/
export const ONLY_NUMERIC_REGEX = /^(0|[1-9]\d*)(\.\d+)?$/

export const CLIENT_SCHEMA_REGEX = /^(?=.{0,20}$)([-'\w]+\s)*[-'\w]+$/;
export const CIDR_REGEX = /^((?:\d{1,3}\.){3}\d{1,3}\/\d{1,2})(,\s*(?:\d{1,3}\.){3}\d{1,3}\/\d{1,2})*$/;
export const PUBLIC_KEY_REGEX = /^(ssh-)?(rsa|dss|ed25519|ecdsa-sha2-nistp256|ecdsa-sha2-nistp384|ecdsa-sha2-nistp521)? ([A-Za-z0-9+/=]+)( (.*))?$/;


export const FILE_TYPE_PDF = 'application/pdf';

export const FIELD_TYPE_INT = 'number';
export const FIELD_TYPE_STRING = 'text';
export const FIELD_TYPE_DATE = 'date';
export const FIELD_TYPE_BOOLEAN = 'boolean';

export const getEquators = (type) => {
    switch (type) {
        case FIELD_TYPE_BOOLEAN:
            return BooleanEquators;
        case FIELD_TYPE_STRING:
            return StringEquators;
        case FIELD_TYPE_INT:
            return integerEquators;
        case FIELD_TYPE_DATE:
            return dateEquators;
        default:
            return [];
    }
}

export const BooleanEquators = {
    type: FIELD_TYPE_BOOLEAN,
    equatorArr: [
        { value: 'equals', label: 'Equal To' },
        { value: 'not_equal_to', label: 'Not Equal To' },
    ]
}
export const StringEquators = {
    type: FIELD_TYPE_STRING,
    equatorArr: [
        { value: 'equals', label: 'Equal To' },
        { value: 'like', label: 'Contains' },
        { value: 'does_not_contain', label: 'Does not Contain' },
        { value: 'starts_with', label: 'Starts with' },
        { value: 'ends_with', label: 'Ends with' },
        { value: 'not_equal_to', label: 'Not Equal To' },
        { value: 'alpha_between', label: 'Between' },
    ]
};

export const integerEquators = {
    type: FIELD_TYPE_INT,
    equatorArr: [
        { value: 'equals', label: 'Equal To' },
        { value: 'not_equal_to', label: 'Not Equal To' },
        { value: 'greater_than', label: 'Greater than' },
        { value: 'greater_than_or_equals_to', label: 'Greater than or Equals to' },
        { value: 'less_than', label: 'Less than' },
        { value: 'less_than_or_equals_to', label: 'Less than or Equals to' },
        { value: 'in_between', label: 'Between' },
    ]
};

export const dateEquators = {
    type: FIELD_TYPE_DATE,
    equatorArr: [
        { value: 'equals', label: 'Equal To' },
        { value: 'not_equal_to', label: 'Not Equal To' },
        { value: 'greater_than', label: 'Greater than' },
        { value: 'greater_than_or_equals_to', label: 'Greater than or Equals to' },
        { value: 'less_than', label: 'Less than' },
        { value: 'less_than_or_equals_to', label: 'Less than or Equals to' },
        { value: 'in_between', label: 'Between' },
    ]
};

export const equatorsMap = {
    'alpha_between': "Between",
    'ends_with': "Ends with",
    'equals': "Equal To",
    'greater_than': "Greater than",
    'greater_than_or_equals_to': "Greater than or Equals to",
    'in_between': "Between",
    'less_than': "Less than",
    'less_than_or_equals_to': "Less than or Equals to",
    'like': "Contains",
    'not_equal_to': "Not Equal To",
    'starts_with': "Starts with",
    'does_not_contain': 'Does not Contain'
}

export const pagesNames = {
    'claimAnalysis-landing': 'Claim Analysis',
    'contracts-landing': 'Contracts',
    'feeSchedules-landing': 'Fee Schedule',
    'feeschedule-items-landing': 'Fee Schedule Items',
    'payerinfo-landing': 'Payer Info',
    'payers-contracts-landing': 'Payer Contracts',
    'contracted-rates-landing': 'Contracted Rates',
    'charge-master-landing': 'Charge Master',
    'associated-landing': 'Associated',
    'shoppable-landing': 'Shoppable',
    'allowable-landing': 'Allowables',
    'pricer-payerinfo-landing': 'Pricer Payer Info',
    'props.name - v1': 'props.name - v1',
    'claim-audits-landing': 'Claim Audits',
    'vendor-contracts-landing': 'Vendor Contracts',
    'model-executions-landing': 'Model Executions',
    'executions-landing': 'Execution Logs',
    'app-id-landing': 'Application Management Landing',
    'payers-stats-landing': 'Payer Statistics',
    'remit-payers-stats-landing': 'Remit Payer Statistics',
    'client-files-stats-landing': 'Clients Files Statistics',
    'stl-load-errors-landing': 'Load Errors',
    'pricer-executions-landing': 'Execution Logs',
    'analysis-stats-landing': 'Sales Statistics'
}

export const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

export const moduleNames = [
    { id: 'cms', name: 'Contract Management' },
    { id: 'vendor', name: 'Vendor Management' },
    { id: 'estimator', name: 'Estimator' },
    { id: 'pricer', name: 'Pricer' },
    { id: 'admin', name: 'Administration' },
    { id: 'file-management', name: 'File Management' },
    { id: 'controlRoom', name: 'Control Room' },
    { id: 'about', name: 'About' },
];

export const pagesRouteBasedNames = {
    'clients': 'Client',
    'dashboards': 'Dashboard',
    'users': 'User',
    'partners': 'Partner',
    'api-key-management': 'API Keys',

    'statistics': 'Statistics',
    'feeschedules': 'Feeschedule',
    'feeschedulesrate': 'Feeschedule Rate',
    'contract': 'Contract',
    'codeservice': 'Code Service',
    'ipps': 'IPPS',
    'opps': 'OPPS',
    'modeling': 'Modeling',
    'vendor': 'Vendor',
    'claimAnalysis': 'Claim Analysis',
    'estimator': 'Estimator',
    'rule': 'Rule',
    'eapg': 'EAPG',
    'app-id-management': 'Application',
    'planManagement': 'Plan Management',

}
export const copyContractConstants={
    FEESCHEDULE:'Rule Fee Schedule Mapping',
    CODESERVICE:'Rule Code Services Mapping',
    PPSCONFIGURATION:'PPS Configuration Mapping'
}