import { Chip, Tooltip as MaterialTooltip } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import { isArray } from "lodash";
import moment from 'moment';
import { ComponentProps, default as React } from "react";
import { OverlayTrigger, ProgressBar, ProgressBarProps, Tooltip } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { prependZero } from "..";
import { PopoverLink } from "../../common";
import { APIKeyShowComponent } from "../../pages/administration/APIKeysPage/APIKeyShowComponent";
import { CopyButton } from "../../common/CopyButton";
import { CONFIG_TYPE_EAPG, CONTRACT_STATE_FINALIZED, CONTRACT_STATE_MODEL, RULE_TYPE_CARVEOUT, RULE_TYPE_DEFAULT, RULE_TYPE_RULE } from "../../store/constants/contract";
import { dateEquators, StringEquators } from "../../store/constants/pages";
import { asDate, convertDateToLocal, parseDate } from '../../util/date';
import { transformOptions } from "../../common";

export type AnyProps = Record<string, any>;

export const createStringFieldFromArrayJoin = <T extends AnyProps>(id: string, label: string, keyName: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: any[]) => {
            if(keyName !== null) {
                return value.filter((value: any) => value !== null).map((item: { [x: string]: any; }) => item[keyName] ?? "").join(', ')
            }
            else {
                return value.filter((value: any) => value !== null).join(', ')
            }
        },
        options
    };
}

export const createStringFieldFromArrayMapping = <T extends AnyProps>(id: string, label: string, dataArray: any[], keyName: string, options: T = {} as T) => {
    
    return {
        id,
        label,
        renderString: (value: any[]) => {
            const filteredArray = dataArray.filter((arrayItem: { id: any; }) => value.some((y: { [x: string]: any; }) => y[keyName] == arrayItem.id));
            return filteredArray.map((item: { name: any; }) => item.name).join(', ')
        },
        options
    };
}

export const createStringFieldFromObjectMapping = <T extends AnyProps, U extends AnyProps>(id: string, label: string, dataObject: T, options: U = {} as U) => {
    return transformOptions({
        id,
        label,
        renderString: (value: string | number) => {
            return dataObject[value]
        },
        options
    },{
        renderString: 'valueFormatter',
        type: 'singleSelect',
        valueGetter: x => x == null ? 'null' : x,
        valueSetter: x => x === 'null' ? null : x,
        valueOptions: Object.keys(dataObject).map((key) => ({ value: key, label: dataObject[key] }))
    });
}

export const createStringDataField = <T extends AnyProps>(id: string, label: string, options : T = {} as T) => {
    return transformOptions({
        id,
        label,
        renderString: (value: { [x: string]: any; }) => {

            if (typeof value === 'string') {
                if (value) {
                    return value;
                }
                return '';
            }
            if (typeof value === 'number') {
                if (value) {
                    return value;
                }
                return '';
            }

            if (typeof value === 'object') {
                const { secondLevel } = options;
                if (value && value[secondLevel]) {
                    return value[secondLevel];
                }
                return '';
            }
        },
        options
    },{
        type: 'string',

        ...( options.nullable ? { 
            valueGetter: x => x == null ? '' : x,
            valueSetter: x => x === '' ? null : x,        
        } : {} ),

        renderString: false,
    });
}

export const createClaimNumberFieldWithCopyButton = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return transformOptions({      
        id,
        label,
        renderString: (value: { claimnumber: string; id: any; }) => {
            let text = '';
            if (value) {
                text = value.claimnumber;
            }

            return <CopyButton text={text} claimId={value.id ?? 'null'} />
        },
        options
    },{
        type: 'string', 
        renderString: 'renderCell',
    });
}

export const createClaimAnalysisPatientNameField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return transformOptions({
        id,
        label,
        renderString: (value: { patientLastname: string; patientFirstname: any; patientMiddlename: any; }) => {
            return value?.patientLastname ? value?.patientLastname + ', ' + `${value?.patientFirstname ?? ''} ${value?.patientMiddlename ?? ''}` : ''
        },
        options
    }, {
        type: 'string', 
        renderString: 'valueGetter',
    });
}
export const createClaimAnalysisSubscriberNameField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return transformOptions({
        id,
        label,
        renderString: (value: { snResponsecontactlastororganizationname: string; snResponsecontactfirstname: any; snResponsecontactmiddlename: any; }) => {
            return value?.snResponsecontactlastororganizationname ? value?.snResponsecontactlastororganizationname + ', ' + `${value?.snResponsecontactfirstname ?? ''} ${value?.snResponsecontactmiddlename ?? ''}` : ''
        },
        options
    }, {
        type: 'string', 
        renderString: 'valueGetter',
    });
}

export const createClaimAnalysisPhysicianNameField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return transformOptions({
        id,
        label,
        renderString: (value: { physicianLastname: any; physicianFirstname: any; }) => {
            return value?.physicianLastname ? `${value?.physicianLastname}, ${value?.physicianFirstname ?? ''}` : ''
        },
        options
    },{
        type: 'string', 
        renderString: 'valueGetter',
    });
}

export const createStringFieldFromMultiKeys = <T extends object>(keyArray: string[], label: string, options: T = {} as T) => {
    return transformOptions({
        id:label,
        label,
        renderString: (value: { [x: string]: any; }) => {
            let str = '';
            for (let item of keyArray) {
                str = `${str} ${value[item] ?? ''}`;
                if (value[item] && options['commaSeparated']) {
                    str += ',';
                }
            }
            return str;
        },
        options
    },{
        type: 'string', 
        renderString: "valueGetter",
    });
}

const renderTooltip = (props: React.ComponentProps<typeof Tooltip>, columnData: AnyProps, numerator: string, denominator: string, name: any) => {
    const isFailed = columnData.state === 'FAILED';
    return (
        <Tooltip id="button-tooltip" {...props as any}>
            {isFailed ? `${columnData.exception}` : `${columnData[numerator]} of ${columnData[denominator]} ${name}`}
        </Tooltip>
    );
}

const popover = (props: React.ComponentProps<typeof Tooltip>, columnData: AnyProps) => {
    return (
        <Tooltip id="button-tooltip" {...props as any}>
            {`${columnData.exception}`}
        </Tooltip>
    );
}

export const createProgressBarField = <T extends AnyProps>(id: string, label: string, numerator: string, denominator: string, name: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: AnyProps) => {
            let color: ProgressBarProps['variant'] = 'info';
            const state = value.state;
            let percentage = 0;
            let label = '';
            if (state === 'FAILED') {
                percentage = 100;
                label = 'Failed';
            } else if (state === 'SUBMITTED') {
                percentage = 0;
            } else {
                percentage = ((value[numerator] * 100) / value[denominator]) || 0;
                label = `${(percentage?.toFixed())}%`;
            }
            switch (state) {
                case 'COMPLETED':
                    color = 'success';
                    break;
                case 'INPROGRESS':
                    color = 'info';
                    break;
                case 'FAILED':
                    color = 'danger';
                    break;
                case 'SUBMITTED':
                    color = 'info';
                    break;
                default:
                    color = 'warning';
                    break;
            }

            return (
                <>
                    {
                        (value.state !== 'FAILED' && (value[numerator] === null || value[denominator] === null)) ?
                            (<ProgressBar className="progress-min-width" label={label} variant={color} now={percentage} />) :
                            color === 'danger' ? (
                                <OverlayTrigger trigger="click" rootClose placement="right" overlay={(props: any) => popover(props, value)}>
                                    <ProgressBar className="progress-min-width" label={label} variant={color} now={percentage} />
                                </OverlayTrigger>
                            ) : color === 'warning' ?
                                <OverlayTrigger
                                    placement="right"
                                    overlay={(props: any) => renderTooltip(props, value, numerator, denominator, name)}
                                >
                                    <ProgressBar className="progress-min-width warning-progress-bar font-weight-600" label={label} now={percentage} />
                                </OverlayTrigger> :
                                <OverlayTrigger
                                    placement="right"
                                    overlay={(props: any) => renderTooltip(props, value, numerator, denominator, name)}
                                >
                                    <ProgressBar className="progress-min-width" label={label} variant={color} now={percentage} />
                                </OverlayTrigger>
                    }
                </>

            );
        },
        options: {
            ...options,
            returnWholeRow: true,
            hideFromFilter: true,
            width: 130
        }
    };
}

export const createBooleanDataField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return transformOptions({
        id,
        label,
        renderString: (value: any) => {
            if (typeof value === 'boolean') {
                if (value) {
                    return 'Active';
                } else {
                    return 'In Active'
                }
            }
            return '';
        },
        options
    },{
        type: 'boolean', 
        renderString: false,
    });
}

export const createPlainDateField = <T extends AnyProps>(id: string, label: string, options : T = {} as T) => {
    return transformOptions({
        id,
        label,
        renderString: (value: moment.MomentInput) => (typeof value === 'string' && value) ? asDate(value) : 'N/A',
        options
    },{
        type: 'date', 
        renderString: false,
        valueGetter: x => parseDate( x, 'plain-date' )
    });
}

export const createDateField = <T extends AnyProps>(id: string, label: string, options : T = {} as T) => {
    return transformOptions({
        id,
        label,
        renderString: (value: moment.MomentInput) => (typeof value === 'string' && value) ? asDate( new Date( value ) ) : 'N/A',
        options
    }, {
        type: 'date', 
        renderString: false,
        valueGetter: x => parseDate( x, 'date-time' )
    })
}

export const createTimestampField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return transformOptions({
        id,
        label,
        renderString: (value: any) => {
            return (typeof value === 'string' && value) ? convertDateToLocal(value) : 'N/A';
        },
        options
    },{
        type: 'dateTime', 
        renderString: false,
        valueGetter: x => parseDate( x, 'date-time' )
    });
}

export const createTimeDifferenceField = <T extends AnyProps>(startTime: string, endTime: string, label: string, options: T = {} as T) => {
    return {
        id: startTime,
        label,
        renderString: (value: { [x: string]: moment.MomentInput; }) => {
            const diff = moment.duration(moment(value[endTime]).diff(moment(value[startTime])));
            const hh = diff.get("hours");
            const mm = diff.get("minutes");
            const ss = diff.get("seconds");
            if (hh === 0 && mm === 0) {
                return `${prependZero(ss)} sec`
            } else if (hh === 0 && mm > 0) {
                return `${prependZero(mm)}:${prependZero(ss)}`;
            } else {
                return `${prependZero(hh)}:${prependZero(mm)}:${prependZero(ss)}`
            }
        },
        options
    };
}

export const createBucketNameField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: any[]) => (value) ? <ul className="mb-0 pl-4"> {value.map((item: { name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; }, index: React.Key) => { return (<li key={index}>{item.name}</li>) })} </ul> : '',
        options
    };
}

export const createBucketNameListField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: any[]) => (value) ? <ul style={{ paddingInlineStart: '15px' }} className="mb-0">
            {value.map((item: { bucketName: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; }, index: { toString: () => any; }) =>
                <li style={{ paddingBottom: '3px' }} key={`bucket-${index.toString()}`}><b>{item.bucketName}</b>
                </li>
            )}
        </ul> : '',
        options
    };
}

export const createBucketStatusListField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: any[]) => (value) ? <ul style={{ paddingInlineStart: '10px', listStyleType: 'none' }} className="mb-0">
            {value.map((item: { state: {}; }, index: any) =>
                <li style={{ paddingBottom: '3px' }}>
                    <MaterialTooltip title={item.state}>
                        <i title='text' style={{ fontSize: '1.3rem' }} className={item.state === 'ENABLED' ? "fas fa-check-circle text-success" : item.state === 'DISABLED' ? "fas fa-times-circle text-danger" : "fas fa-exclamation-circle text-secondary"}></i>
                    </MaterialTooltip>
                    
                </li>
            )}
        </ul> : '',
        options
    };
}

export const createOrderDataField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: number) => (value !== 99999) ? value : '-',
        options
    };
}

export const createClientListField = <T extends { clients: { clientId: any; name: string; }[] }>(id: string, label: string, options: T) => {
    return {
        id,
        label,
        renderString: (value: any[], rowData: any) => {
            const { clients } = options;
            if (value) {
                return (
                    value.map((item: any, index: React.Key) => {
                        return (
                            <li key={index}>
                                {clients.find((client: { clientId: any; }) => client.clientId === item)?.name}
                            </li>
                        )
                    })
                )
            }
            return '';
        },
        options
    };
}

export const createNumberDataField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return transformOptions({
        id,
        label,
        renderString: (value: any) => (typeof value === 'number' && value) ? value : 0,
        options
    },{
        type: 'number', 
        renderString: 'valueFormatter',    
        valueFormatter : options.valueFormatter
    });
}

export const createCurrencyDataField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return transformOptions({
        id,
        label,
        renderString: (value: any) => (typeof value === 'number') ? `$ ${createFloatingPoint(value)}` : "N/A",
        options
    },{
        type: 'number', 
        renderString: "valueFormatter",    
    });
}

export const createPercentageDataField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: any) => (typeof value === 'number' && value) ? `${value} %` : 0,
        options
    };
}

export const createBooleanPayerDataField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: any) => {
            if (typeof value === 'boolean') {
                if (value) {
                    return 'Payer';
                } else {
                    return 'Vendor';
                }
            }
            return '';
        },
        options
    };
}

export const createTypeMetadataDataField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: string) => {
            let metadata = {};
            try {
                metadata = JSON.parse(value);
            }
            catch {
                metadata = {};
            }

            if (metadata) {
                return (metadata as any).type;
            }
            return '';
        },
        options
    };
}

export const createFloatingPoint = (value: number) => {
    if (value) {
        return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return 0.00;
}

export const createPayMethodDataField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: { name: any; }) => (typeof value === 'object' && value) ? value.name : '',
        options
    };
}

export const createSecondLevelField = <T extends AnyProps>(id: string, label: string, field: string, options: T = {} as T) => {
    return transformOptions({
        id,
        label,
        renderString: (value: { [x: string]: any; }) => (typeof value === 'object' && value) ? value[field] : '',
        options
    },{
        type: 'string', 
        renderString: 'valueGetter',
    });
}

export const createIconDataField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return transformOptions({
        id,
        label,
        renderString: (value: any) => {
            if (typeof value === 'boolean') {
                if (value) {
                    return true;
                } else {
                    return false;
                }
            }
            return false;
        },
        options
    },{
        type: 'boolean', 
        renderString: false,    
    });
}

export const createReverseIconDataField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: any) => {
            if (typeof value === 'boolean') {
                if (value) {
                    return false;
                } else {
                    return true;
                }
            }
            return false;
        },
        options
    };
}

export const createSearchFilterDataField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: string) => {
            let filters = [];
            try {
                filters = JSON.parse(value);
            }
            catch {
                filters = [];
            }

            if (isArray(filters)) {
                return (
                    <ul style={{ paddingInlineStart: '15px' }} className="mb-0">
                        {
                            filters.map((item, index) => {
                                const equators = [...dateEquators.equatorArr, ...StringEquators.equatorArr];
                                const extractedEquator = equators.find(eq => eq.value === item.equator);
                                return <li key={`${index}-${item.field}`}>
                                    {item.field} <i>{extractedEquator ? extractedEquator.label : item.equator}</i> <b>{item.value}</b></li>
                            })
                        }
                    </ul>
                );

            }
            return '';
        },
        options
    };
}

export const createKPIDataField = <T extends AnyProps>(id: string, label: string, options: T = {} as T, payerScoreCard: undefined) => {
    if (id === 'timeToPay' || id == 'payerYield') {
        return {
            id,
            label,
            renderString: (value: { [x: string]: any; detailData?: any; timeToPayColor?: any; payerYieldColor?: any; }) => {
                const { paidOnTime, totalClaims, payerYieldExpected, payerYieldActual, payerYieldTotal, payerYieldVariance, timeToPayVariance } = value.detailData;
                const { timeToPayColor, payerYieldColor } = value;

                // receiving color from backend
                let className = '';
                if (id === 'timeToPay') {
                    className = timeToPayColor === 'GREEN' ? 'text-white bg-success'
                        : timeToPayColor === 'RED' ? 'bg-danger text-white'
                            : timeToPayColor === 'YELLOW' ? 'bg-warning text-dark' : '';

                } else if (id === 'payerYield') {
                    className = payerYieldColor === 'GREEN' ? 'text-white bg-success'
                        : payerYieldColor === 'RED' ? 'bg-danger text-white'
                            : payerYieldColor === 'YELLOW' ? 'bg-warning text-dark' : '';
                }

                const kpi = value[id];
                const timeToPayHoverData = [
                    {
                        label: 'Paid On Time',
                        data: paidOnTime
                    },
                    {
                        label: 'Total Claims',
                        data: totalClaims
                    },
                    {
                        label: 'Time To Pay Variance',
                        data: timeToPayVariance
                    }
                ];

                const payerYieldPayHoverData = [
                    {
                        label: 'Payer Yield Expected',
                        data: payerYieldExpected
                    },
                    {
                        label: 'Payer Yield Actual',
                        data: payerYieldActual
                    },
                    {
                        label: 'Payer Yield Total',
                        data: payerYieldTotal
                    },
                    {
                        label: 'Payer Yield Variance',
                        data: payerYieldVariance
                    }
                ];


                return <PopoverLink
                    columnData={
                        <Chip
                            style={{ borderRadius: '5px', width: '70px', fontWeight: '600' }}
                            label={id === 'timeToPay' ? kpi : `${kpi}%`}
                            className={className}
                        />
                    }
                    hoverData={id === 'timeToPay' ? timeToPayHoverData : id === 'payerYield' ? payerYieldPayHoverData : []}
                    title={label}
                />
            },
            options
        };

    }
    else {
        return {
            id,
            label,
            renderString: (value: { [x: string]: any; detailData?: any; errorRateColor?: any; denialRateColor?: any; }) => {
                const { errorClaims, deniedClaims, totalClaims } = value.detailData;
                const { errorRateColor, denialRateColor } = value;

                // receiving color from backend
                let className = '';
                if (id === 'errorRate') {
                    className = errorRateColor === 'GREEN' ? 'text-white bg-success'
                        : errorRateColor === 'RED' ? 'bg-danger text-white'
                            : errorRateColor === 'YELLOW' ? 'bg-warning text-dark' : '';

                } else if (id === 'denialRate') {
                    className = denialRateColor === 'GREEN' ? 'text-white bg-success'
                        : denialRateColor === 'RED' ? 'bg-danger text-white'
                            : denialRateColor === 'YELLOW' ? 'bg-warning text-dark' : '';
                }

                const kpi = value[id];

                const errorRateHoverData = [
                    {
                        label: 'Error Claims',
                        data: errorClaims
                    },
                    {
                        label: 'Total Claims',
                        data: totalClaims
                    },
                ];
                const denialRateHoverData = [
                    {
                        label: 'Denied Claims',
                        data: deniedClaims
                    },
                    {
                        label: 'Total Claims',
                        data: totalClaims
                    },
                ]

                return <PopoverLink
                    columnData={
                        <Chip
                            style={{ borderRadius: '5px', width: '70px', fontWeight: '600' }}
                            label={`${kpi}%`}
                            className={className}
                        />
                    }
                    hoverData={id === 'errorRate' ? errorRateHoverData : id === 'denialRate' ? denialRateHoverData : []}
                    title={label}
                />
            },
            options
        };
    }
}

export const createExceptionHoverField = <T extends AnyProps>(id: string, label: string, equalTo: string | boolean, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: { [x: string]: any; exception: any; }) => {
            if (value[id] === equalTo) {
                return <i style={{ fontSize: '1.3rem' }} className="fas fa-check-circle text-success ml-2"></i>
            } else {
                return <PopoverLink
                    columnData={
                        <ErrorIcon className="ml-2" color="error" />
                    }
                    hoverData={[{ label: value.exception }]}
                    title={'Exception Details'}
                />;
            }
        },
        options
    };
}

export const createUploadsStatusField = <T extends AnyProps>(id: string, label: string, textKey: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: { [x: string]: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; }) => {
            let color : ComponentProps<typeof Chip>['color'] = 
                value[id] === 'PROCESSED' ? 'secondary'
                : value[id] === 'FAILED' ? 'error'
                : 'primary';

            if (value[id] === 'FAILED') {
                return <PopoverLink
                    columnData={
                        <Chip
                            style={{ borderRadius: '5px', width: '90px', height: '18px', fontWeight: '400', paddingTop: '1px' }}
                            label={value[id]}
                            color={color}
                        />
                    }
                    hoverData={[{ label: value[textKey ?? 'exception'] }]}
                    title={'Exception Details'}
                />;
            } else {
                return <Chip
                    style={{ borderRadius: '5px', width: '90px', height: '18px', fontWeight: '400', paddingTop: '1px' }}
                    label={value[id]}
                    color={color}                />
            }
        },
        options
    };
}

export const createListField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: { [x: string]: any[]; }) => {
            if (value) {
                return (
                    value[id].map((item: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal, index: { toString: () => any; }) => {
                        return (
                            <li key={`${item}-${index.toString()}`}>
                                {item}
                            </li>
                        )
                    })
                )
            }
            return '';
        },
        options
    };
}


export const createContractListField = <T extends { class?: string }>(id: string, label: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: { contractFamilyXref: any[]; }) => {
            if (value) {
                const contracts = value.contractFamilyXref?.sort((a: { precedence: number; }, b: { precedence: number; }) => a.precedence - b.precedence);

                if (contracts.length > 5) {
                    const visibleContracts = contracts.slice(0, 5);
                    const hiddenContracts = contracts.slice(5);

                    return (
                        <>
                            {visibleContracts.map((item: { contractName: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal; }, index: number) => (
                                <li key={`${item}-${index.toString()}`}>
                                   {item?.contractName} {index===visibleContracts.length-1 && <span className={options.class}>...</span>}
                                </li>
                            ))}
                        </>
                    );
                } else {
                    return contracts.map((item: { contractName: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; }, index: { toString: () => any; }) => (
                        <li key={`${item}-${index.toString()}`}>
                            {item?.contractName}
                        </li>
                    ));
                }
            }
            return '';
        },
        options
    };
}

export const createStatusField = <T extends AnyProps>(id: string, label: string, options : T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: {
            [x: string]:
                | boolean
                | React.ReactChild
                | React.ReactFragment
                | React.ReactPortal;
        }) => {
            let color : ComponentProps<typeof Chip>['color'] =
                value[id] === "COMPLETED" ? "secondary" : 
                value[id] === "FAILED" ? "error" : 
                "primary"

            return options?.haveLink && value[id] === "IGNORED" ? (
                <>
                    <Chip
                        sx={{
                            borderRadius: "5px",
                            width: "90px",
                            height: "18px",
                            fontWeight: "400",
                            paddingTop: "1px",
                            mr:"10px"
                        }}
                        label={value[id]}
                        color={ color }
                    />
                    <Link
                        to={`/cms/claimLookup/${value?.claimnumber}`}
                        style={{ textDecoration: "underline" }}
                    >
                        View Details
                    </Link>{" "}
                </>
            ) : (
                <Chip
                    style={{
                        borderRadius: "5px",
                        width: "90px",
                        height: "18px",
                        fontWeight: "400",
                        paddingTop: "1px",
                    }}
                    label={value[id]}
                    color={ color }
                />
            );
        },
        options,
    };
}

export const createTooltipIconField = <T extends AnyProps>(id: string, label: string, options: T) => {
    return transformOptions({
        id,
        label,
        renderString: (value: { [x: string]: any; }) => {
            const tooltipTitle = value[id] ? options.checkedTooltip : options.uncheckedTooltip;
            return <MaterialTooltip title={tooltipTitle}>
                <i className={`${options.iconClass} ${value[id] ? 'text-success' : 'text-secondary'} ml-2 font-20`}></i>
            </MaterialTooltip>
        },
        options
    },{
        type: 'boolean', 
        renderString: 'renderCell'
    });
}

export const createTooltipIconForEmptyField = <T extends AnyProps>(id: string, label: string, options: T) => {
    return {
        id,
        label,
        renderString: (value: { [x: string]: any; }) => {
            if(value[id]) {
                return value[id];
            }
            return <MaterialTooltip title={options.errorTooltip}>
                <i className={`${options.iconClass} ${options.iconColor} ml-2 font-20`}></i>
            </MaterialTooltip>
        },
        options
    };
}

export const createTooltipIconForNonEmptyField = <T extends AnyProps>(id: string, label: string, options: T) => {  
    return {
        id,
        label,
        renderString: (value: any) => {
            if(value) {
                return <MaterialTooltip title={options.errorTooltip}>
                    <i className={`${options.iconClass} ${options.iconColor} ml-2 font-20`}></i>
                </MaterialTooltip>
            }
            else {
                return '';
            }
            
        },
        options
    };
}

export const createContractStateField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: {}) => {
            let className = value === CONTRACT_STATE_FINALIZED ? 'contract-badge-green'
                : value === CONTRACT_STATE_MODEL ? 'contract-badge-orange'
                    : 'contract-badge-blue';

            return <Chip
                style={{ borderRadius: '5px', width: '80px', height: '18px', fontWeight: '600', paddingTop: '0px' }}
                label={value}
                className={className}
            />
        },
        options
    };
}

export const createContractRuleTypeField = <T extends AnyProps>(id: string, label: string, field: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: { [x: string]: any; }) => {
            let ruleType = (typeof value === 'object' && value) ? value[field] : '';
            let className = ruleType === RULE_TYPE_CARVEOUT ? 'contract-badge-green'
                : ruleType === RULE_TYPE_RULE ? 'contract-badge-orange'
                : ruleType === RULE_TYPE_DEFAULT ? 'contract-badge-blue'
                : ruleType === CONFIG_TYPE_EAPG ? 'contract-badge-purple'
                    : 'contract-badge-red';

            return <Chip
                style={{ borderRadius: '5px', width: '100px', height: '18px', fontWeight: '600', paddingTop: '0px' }}
                label={ruleType}
                className={className}
            />
        },
        options
    };
}


export const createClaimAnalysisStatusField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return transformOptions({
        id,
        label,
        renderString: (value: { [x: string]: string; }) => {
            let status = value[id] || '';
            let className = status === "New" ? 'contract-badge-green'
                : status === "InProgress" ? 'contract-badge-orange'
                : status === "ClosedRecovered" ? 'contract-badge-blue'
                : status === "ClosedIgnored" ? 'contract-badge-purple'
                    : 'contract-badge-red';

            return <Chip
                style={{ borderRadius: '5px', width: '100px', height: '18px', fontWeight: '600', paddingTop: '0px' }}
                label={status}
                className={className}
                title={status}
            />
        },
        options
    },{
        type: 'string', 
        renderString: 'renderCell'
    });
}

export const createColumnsSumField = <T extends AnyProps>(id: string, label: string, keyArr: string[], options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: { [x: string]: number; }) => {
            let sum = 0;
            for (let item of keyArr) {
                sum = sum + value[item];
            }
            return sum;
        },
        options
    };
}

export const createModifiersFieldWithColons = <T extends AnyProps>(id: string, label: string, options : T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: { [x: string]: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal; }) => {
            if (value) {
                let result = [];

                options.list.forEach((item: { id: React.Key; }, index: number) => {
                    if (value[item.id]) {
                        result.push(
                            <span key={item.id}>
                                {value[item.id]}
                                {index < options.list.length - 1 && <strong> : </strong>}
                            </span>
                        );
                    }
                });

                return result;
            }

            return null;
        },
        options
    };
}

export const createClientProductFlags = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: { products: { [x: string]: any; }; }) => {
            return <span>
                Dashboards: <i style={{ fontSize: '1.3rem' }} className={value?.products?.[id] ? "fas fa-check-circle ml-2 text-success" : "fas fa-check-circle text-secondary ml-2"}></i>
            </span>
        },
        options
    };
}

export const createAPIKeyField = <T extends { appId?: boolean }>(id: string, label: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: any) => {
            return <APIKeyShowComponent value={value} isAppId={options.appId} />
        },
        options
    };
}

export const createContractModelField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: { [x: string]: any; }) => {
            if (typeof value === 'object') {
                if (value) {
                    if(value["ExecutionError"] && value["ExecutionError"] !== '') {
                        return  <PopoverLink
                                    columnData={
                                        <ErrorIcon className="ml-2" color="error" />
                                    }
                                    hoverData={[{ label: value["ExecutionError"] }]}
                                    title={'Error Details'}
                                />;
                    }
                    else 
                        return (typeof value["ExpectedPay"] === 'number') ? `$ ${createFloatingPoint(value["ExpectedPay"])}` : "N/A" ;
                }
                return 'N/A';
            }
        },
        options
    };
}

export const createExecutionStatusField = <T extends AnyProps>(id: string, label: string, options: T = {} as T) => {
    return {
        id,
        label,
        renderString: (value: string) => {

            let color : ComponentProps<typeof Chip>['color'] =
            value === "COMPLETED" ? "secondary" : 
            (value === 'INPROGRESS' || value === 'SUBMITTED') ? "primary" : 
            value === "FAILED" ? "error" : 
            "default"

            return <Chip
                style={{ borderRadius: '5px', width: '90px', height: '18px', fontWeight: '400', paddingTop: '1px' }}
                label={value}
                color={color}                
            />
        },
        options
    };
}

const usDollarsFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
  
export function asDollars( amount: number ) : string {
    return amount == null ? '' : 
        amount === 0 ? '-' :
        usDollarsFormatter.format(amount);
}

/**
 * Converts a fraction to a percentage string.
 *
 * @param fraction - The fraction to convert. If the fraction is null or undefined, an empty string is returned.
 * @returns The percentage representation of the fraction, rounded to the nearest whole number, followed by a '%' sign.
 */
export function asPercentage( fraction: number ) : string{
    return fraction == null ? '' :
        Math.abs( fraction ) < 0.000001 ? '-' :
        `${Math.round(fraction * 100)}%`;
}