import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from "react-intl";
import RCMStatusPageComponent from "./RCMStatusPage";
import { createSelector } from 'reselect';
import { renderHoverLink } from "../../common";
import { createStringDataField,createPlainDateField,createCurrencyDataField,createClaimNumberFieldWithCopyButton } from '../../util/format';
import { StringEquators } from '../../store/constants/pages';

export const RCMStatusPage = connect(
    () => {

        const selectLineItemOverPaidAndUnderpaidFields:any = createSelector(
            () => [
                createStringDataField('contractName', 'Contract Name', { sortable: false,hideFromFilter:true }),
                createStringDataField('volume', 'Volume', { sortable: false,hideFromFilter:false}),
                createCurrencyDataField('variance', 'Variance', { sortable: false,hideFromFilter:false,calculateTotal:true}),
            ]
        );

        const selectTotalVarianceFields:any = createSelector(
            () => [
                createStringDataField('type', 'Type', { sortable: false,hideFromFilter:true }),
                createStringDataField('volume', 'Volume', { sortable: false,hideFromFilter:false}),
                createCurrencyDataField('variance', 'Variance', { sortable: false,hideFromFilter:false,calculateTotal:true}),
            ]
        );

        const selectZeroPaymentFields:any = createSelector(
            () => [
                createStringDataField('contractName', 'Contract Name', { sortable: false,hideFromFilter:true }),
                createStringDataField('denialCode', 'Denial Code', { sortable: false,hideFromFilter:false}),
                // createStringDataField('fullDescription', 'Full Description', { sortable: false,hideFromFilter:false}),
                createStringDataField('grouping', 'Grouping', { sortable: false,hideFromFilter:false, onHover: true}),
                // createStringDataField('shortDescription', 'Short Description', { sortable: false,hideFromFilter:false}),
                createStringDataField('volume', 'Volume', { sortable: false,hideFromFilter:false}),
                createCurrencyDataField('variance', 'Variance', { sortable: false,hideFromFilter:false,calculateTotal:true}),

            ]
        );
      

        return (state, props) => ({
            lineItemsOverPaidAndUnderpaidFields:selectLineItemOverPaidAndUnderpaidFields(state),
            totalVarianceFields:selectTotalVarianceFields(state),
            zeroPaymentFields:selectZeroPaymentFields(state)

        });
    },
    (dispatch) => ({
        actions: bindActionCreators({

        }, dispatch)
    })
)(injectIntl(RCMStatusPageComponent));