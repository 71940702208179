import { FormControl, FormControlLabel, FormHelperText, Input, InputLabel, MenuItem, Select, Snackbar, Switch, Tooltip } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import MuiAlert from '@mui/material/Alert';
import { Formik } from "formik";
import React, { useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { Loadable } from '../../../common';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";

import { Button, Checkbox, Chip, Grid, TextField, Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { Card } from "react-bootstrap";
import { BackButton } from "../../../common/BackButton";
import { MODULE_TYPE_CONTRACT } from "../../../store/constants/contract";
import { asIsoDate } from "../../../util/date";
import { DocumentUpload } from "../DocumentUpload";
import { ContractsBreadCrumb } from '../ContractsBreadCrumb'; 
import ContactFieldArray from './ConfigurationFieldArrays/ContactFieldArray';
import ContractAllowableCodeSelection from "./ConfigurationFieldArrays/ContractAllowableCodeSelection";
import ContractDenialCodeSelection from "./ConfigurationFieldArrays/ContractDenialCodeSelection";
import ContractPpsSelectionCriteriaFieldArray from "./ConfigurationFieldArrays/ContractPpsSelectionCriteriaFieldArray";
import ContractEAPGSelectionCriteriaFieldArray from "./ConfigurationFieldArrays/ContractEAPGSelectionCriteriaFieldArray";
import ContractOPPSSelectionCriteriaFieldArray from "./ConfigurationFieldArrays/ContractOPPSSelectionCriteriaFieldArray";
import ContractPatientResponsibilitySelection from "./ConfigurationFieldArrays/ContractPatientResponsibilitySelection";
import ContractSelectionCriteriaFieldArray from "./ConfigurationFieldArrays/ContractSelectionCriteriaFieldArray";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '85%',
  },
  formControlDropdown: {
    marginTop: theme.spacing(2),
    width: '85%',
  },
  description: {
    width: '83%',
  },
  portlet: {
    'box-shadow': 'none',
  },
  successIcon: {
    color: 'green'
  },
  errorIcon: {
    color: 'red'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  formControlType: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    width: '85%',
  },
  checkbox: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
  },
  panel: {
    marginBottom: theme.spacing(1),
    'box-shadow': 'none !important',
    border: '1px solid #dddddd',
    borderRadius: 4,
  },
  switch: {
    //margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1)
  },
  formControlAuto: {
    marginTop: theme.spacing(5),
    width: '85%',
  },
  paper: {
    marginTop: theme.spacing(1),
  },
  facilityFormControlAuto: {
    marginTop: theme.spacing(2),
    width: '85%',
  },
  helpClass: {
    position: 'relative',
    top: 45,
    marginLeft: 10,
  },
  redColor: {
    color: '#fd397a',
  },
  errorText: {
    color: '#fd397a',
    fontSize: '12px',
    marginBottom: 0
  },
  disabledSection: {
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      cursor: 'not-allowed'
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ContractsEditPage({
  intl, initialValues, contractsGetPending, contractId, contactTemplate, contractsStates, casCodes, drgs,
  contractsCreatePending, history, contractsUpdatePending, mode, clientId, alertUsers, facilities, contractCriteria, oppsList, eapgList,directoryId,
  actions: { contractGet, contractCreate, contractUpdate, contractDocumentList, contractGetAlertUsers, getOPPSTiny, getEAPGTiny,
    contractGetFacilities, contractEditReset, getContractCriteria, getStates, getCascodes, getDRGTiny,getHierarchy
} }) {
  const [open, setOpen] = React.useState(false);
  const formikRef = useRef();


  const search = useLocation().search;
  const isFromIPPS = (Boolean)(new URLSearchParams(search).get('fromipps'));
  const isFromOPPS = (Boolean)(new URLSearchParams(search).get('fromopps'));
  const isFromEAPG = (Boolean)(new URLSearchParams(search).get('fromeapg'));

  const classes = useStyles();
  const isEdit = mode === 'edit';

  const handleCallback = (newContractId) => {
    history.push(`/cms/contract/view/${newContractId}${directoryId ? `/${directoryId}`:''}`);
  }

  const handleEditCallback = () => {
    history.push(`/cms/contract/view/${contractId}${directoryId ? `/${directoryId}`:''}`);
  }

  useEffect(() => {
    getOPPSTiny();
  }, [getOPPSTiny]);

  useEffect(() => {
    getDRGTiny();
  }, [getDRGTiny]);

  useEffect(() => {
    getEAPGTiny();
  }, [getEAPGTiny]);

  useEffect(() => {
    getCascodes()
  }, [getCascodes]);

  useEffect(() => {
    getStates();
  }, [getStates]);

  useEffect(() => {
    getContractCriteria();
  }, [getContractCriteria]);

  useEffect(() => {
    getHierarchy();
  }, [getHierarchy]);



  

  useEffect(() => {
    return () => {
      contractEditReset();
    }
  }, []);

  useEffect(() => {
    contractGetFacilities();
  }, [contractGetFacilities]);

  useEffect(() => {
    contractGetAlertUsers(clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractGetAlertUsers]);

  useEffect(() => {
    if (document.readyState === 'complete' && !contractsGetPending && !contractsCreatePending && !contractsUpdatePending) {
      if(isFromIPPS) { RedirectToSection('ipps-div'); }
      else if(isFromOPPS) { RedirectToSection('opps-div'); }
      else if(isFromEAPG) { RedirectToSection('eapg-div'); }
    }
  }, [isFromIPPS, isFromOPPS, isFromEAPG, contractsGetPending, contractsCreatePending, contractsUpdatePending]);

  useEffect(() => {
    if (mode === 'edit') {
      contractGet(contractId);
    }
  }, [mode, contractGet, contractId]);

  function RedirectToSection(secName) {
      const element = document.getElementById(secName);
      
      if(!element) return;

      const position = element.getBoundingClientRect();
      const x = position.left;
      const y = position.top;
      window.scrollTo(x, y - 70); // subtracting header height
  }

  function handleUploadCallback() {
    setOpen(true)
    contractDocumentList(contractId);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const filterOptions = createFilterOptions<{ name: string; email: string }>({
    stringify: option => option.name + ' ' + option.email,
  });

  const facilityFilterOption = createFilterOptions<{ name: string }>({
    stringify: option => option.name
  });

  const handleDenialCasCodeToggleChange = (e, setFieldValue) => {
    setFieldValue('isDenialCasCodeActive', e.target.checked);
    if (e.target.checked) {
      const defaultSelectedCascodes = casCodes.filter(item => item.isDenial === true);
      setFieldValue('contractDenialCascodeXref', defaultSelectedCascodes);
    }
  }

  const handleAllowableToggleChange = (e, setFieldValue) => {
    setFieldValue('isAllowableCodeActive', e.target.checked);
  }

  const createAbleFilterOption = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option);
    if (inputValue !== '' && !isExisting) {
      filtered.push(`Add ${inputValue}`);
    }
    return filtered;
  }

  const handleRedirectToContractView = () => {
    history.push(`/cms/contract/view/${contractId}${directoryId ? `/${directoryId}`:''}`);
  }

  return (
    <>
{directoryId &&    <ContractsBreadCrumb directoryId={directoryId} isEdit={isEdit}/>
}    <Loadable loading={contractsGetPending || contractsCreatePending || contractsUpdatePending}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Document successfully uploaded!
        </Alert>
      </Snackbar>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        innerRef={formikRef}
        validate={values => {
          const errors: Record<string,string> = {};

          if (!values.name) {
            errors.name = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (!values.effectiveDate) {
            errors.effectiveDate = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }
          if (!values.terminationDate) {
            errors.terminationDate = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if( values.effectiveDate > values.terminationDate) {
            errors.terminationDate = 'Termination date should be greater than effective date';
            errors.effectiveDate = 'Effective date should be less than termination date';
          }

          // Contract Selection Criteria Error
          const contractCriteriaError = values.contractSelectionCriteriaXref.map((crite,index) => {
            const criteriaError = {};
            let hasError = false;
            if (crite.contractSelectionCriteriaId !== null) {
              if (!crite.equator) {
                criteriaError['equator'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              }
              if (crite.isBetween) {
                if (!crite.startsAt) {
                  criteriaError['startsAt'] = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                  hasError = true;
                }
                if (!crite.endsAt) {
                  criteriaError['endsAt'] = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                  hasError = true;
                }
              } else {
                if (!crite.value) {
                  criteriaError['value'] = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                  hasError = true;
                }
              }

              for (let i = index - 1; i >= 0; i--) {
                  const previous = values.contractSelectionCriteriaXref[i];
                  const doesCriteriaNameMatces =
                      crite.contractSelectionCriteriaId?.name ===
                      previous.contractSelectionCriteriaId?.name;
                  const validationMsg =
                      "Duplicate selection criteria are not allowed. Please review your input";

                  if (
                      crite.isBetween &&
                      previous.isBetween &&
                      crite.startsAt === previous.startsAt &&
                      crite.endsAt === previous.endsAt &&
                      doesCriteriaNameMatces
                  ) {
                      criteriaError["startsAt"] = validationMsg;
                      hasError = true;
                      break;
                  } else if (
                      crite.value === previous.value &&
                      crite.equator === previous.equator &&
                      crite.contractSelectionCriteriaId?.name ===
                          previous.contractSelectionCriteriaId?.name
                  ) {
                      criteriaError["value"] = validationMsg;
                      hasError = true;
                      break;
                  }
              }

            }

            if (hasError) {
              return criteriaError;
            }

            return false;
          });
          const filteredCriteriaError = contractCriteriaError.filter(value => Object.keys(value).length > 0);

          if (filteredCriteriaError.length > 0 && values.isCriteriaActive) {
            errors['contractSelectionCriteriaXref'] = contractCriteriaError;
          }

          if (values.contractPatientResponsibilityXref.length === 0) {
            errors.contractPatientResponsibilityXref = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if(values.contractPatientResponsibilityXref.length > 0 && values.contractOtherAllowableXref.length > 0) {
            const duplicates = values.contractPatientResponsibilityXref.some(pr => values.contractOtherAllowableXref.some(oa => pr.code === oa.code));
            if (duplicates) {
              errors.contractOtherAllowableXref = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }
          }

          const drgconfigurationToContractXrefError = values.drgconfigurationToContractXref.map((crite) => {
            const criteriaError = {};
            let hasError = false;
            if (crite.drgConfigurationId !== null) {
              if (crite.percentage === '') {
                criteriaError['percentage'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              } else if (parseInt(crite.percentage) < 0) {
                criteriaError['percentage'] = 'Percentage value should be greater than 0'
                hasError = true;
              }

              if (!crite.effectiveFrom) {
                criteriaError['effectiveFrom'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              } else {
                const criteEF = crite.effectiveFrom;
                const effectiveFrom = asIsoDate(crite.drgConfigurationId.effectiveFrom)
                const effectiveTo = asIsoDate(crite.drgConfigurationId.effectiveTo);

                if( criteEF < effectiveFrom || criteEF > effectiveTo) {
                  criteriaError['effectiveFrom'] = 'Effective date should be within the range of PPS configurations date range'
                  hasError = true;
                }
              }
              
              if (crite.customPPSError) {
                criteriaError['customPPSError'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              }
            }

            if (hasError) {
              return criteriaError;
            }

            return false;
          });
          const filtereddrgconfigurationToContractError = drgconfigurationToContractXrefError.filter(value => Object.keys(value).length > 0);

          if (filtereddrgconfigurationToContractError.length > 0) {
            errors['drgconfigurationToContractXref'] = drgconfigurationToContractXrefError;
          }


          const contractOppsconfigurationXrefError = values.contractOppsconfigurationXref.map((crite) => {
            const criteriaError = {};
            let hasError = false;
            if (crite.oppsConfigurationId !== null) {
              if (crite.percentage === '') {
                criteriaError['percentage'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              } else if (parseInt(crite.percentage) < 0) {
                criteriaError['percentage'] = 'Percentage value should be greater than 0'
                hasError = true;
              }
              if (!crite.effectiveFrom) {
                criteriaError['effectiveFrom'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              } else {
                const criteEF = crite.effectiveFrom;
                const effectiveFrom = asIsoDate(crite.oppsConfigurationId.effectiveFrom);
                const effectiveTo = asIsoDate(crite.oppsConfigurationId.effectiveTill);

                if (criteEF < effectiveFrom || criteEF > effectiveTo) {
                  criteriaError['effectiveFrom'] = 'Effective date should be within the range of PPS configurations date range'
                  hasError = true;
                }
              }

              if (crite.customPPSError) {
                criteriaError['customPPSError'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              }
            }


            if (hasError) {
              return criteriaError;
            }

            return false;
          });
          const filteredcontractOppsconfigurationXrefErr = contractOppsconfigurationXrefError.filter(value => Object.keys(value).length > 0);

          if (filteredcontractOppsconfigurationXrefErr.length > 0) {
            errors['contractOppsconfigurationXref'] = contractOppsconfigurationXrefError;
          }

          const contractEapgconfigurationXrefError = values.contractEapgconfigurationXref.map((crite) => {
            const criteriaError = {};
            let hasError = false;
            if (crite.eapgConfigurationId !== null) {
              if (crite.percentage === '') {
                criteriaError['percentage'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              } else if (parseInt(crite.percentage) < 0) {
                criteriaError['percentage'] = 'Percentage value should be greater than 0'
                hasError = true;
              }
              if (!crite.effectiveFrom) {
                criteriaError['effectiveFrom'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              } else {
                const criteEF = crite.effectiveFrom;
                const effectiveFrom = asIsoDate(crite.eapgConfigurationId.effectiveFrom);
                const effectiveTo = asIsoDate(crite.eapgConfigurationId.effectiveTill);

                if (criteEF < effectiveFrom || criteEF > effectiveTo) {
                  criteriaError['effectiveFrom'] = 'Effective date should be within the range of EAPG configurations date range'
                  hasError = true;
                }
              }

              if (crite.customEAPGError) {
                criteriaError['customEAPGError'] = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
                hasError = true;
              }
            }


            if (hasError) {
              return criteriaError;
            }

            return false;
          });
          const filteredcontractEapgconfigurationXrefErr = contractEapgconfigurationXrefError.filter(value => Object.keys(value).length > 0);

          if (filteredcontractEapgconfigurationXrefErr.length > 0) {
            errors['contractEapgconfigurationXref'] = contractEapgconfigurationXrefError;
          }

          return errors;

        }}
        onSubmit={(values) => {
          let payload:any = {
            facilityIds :
              values.facilityIds && values.facilityIds.length > 0 ?
                values.facilityIds.map(facility => facility.id) 
                : [],

            alertContactIds :
              values.alertContacts && values.alertContacts.length > 0 ?
                values.alertContacts.map(contact => contact.userId) 
                : [],
            
            contractDenialCascodeXref : 
              values.isDenialCasCodeActive ? 
                values.contractDenialCascodeXref.map(item => item.id) 
                : [],

            contractSelectionCriteriaXref :
              values.isCriteriaActive ?
                values.contractSelectionCriteriaXref
                  .filter(x => x.contractSelectionCriteriaId !== null || x.equator !== '' || x.value !== '')
                  .map(item => 
                    item.isBetween ?
                      {
                        contractSelectionCriteriaId: item.contractSelectionCriteriaId.id,
                        equator: item.equator,
                        value: `${item.startsAt}&${item.endsAt}`
                      }
                    :
                      {
                        contractSelectionCriteriaId: item.contractSelectionCriteriaId.id,
                        equator: item.equator,
                        value: item.value
                      }
                  )
                : [],
                
            drgconfigurationToContractXref:
              values.isPpsSelectionActive ?
                values.drgconfigurationToContractXref
                  .filter(item => item.drgConfigurationId !== null).
                  map(item => ({ 
                    drgConfigurationId: item.drgConfigurationId.id, 
                    percentage: parseInt(item.percentage), 
                    effectiveFrom: item.effectiveFrom, 
                    includeIME: item.includeIME,
                    includeDSH: item.includeDSH 
                  }))
                : [],

            contractOppsconfigurationXref:
              values.isPpsSelectionActive && !values.isEAPGSelectionActive ?
                values.contractOppsconfigurationXref
                  .filter(item => item.oppsConfigurationId !== null)
                  .map(item => ({ 
                    oppsConfigurationId: item.oppsConfigurationId.id, 
                    percentage: parseInt(item.percentage), 
                    effectiveFrom: item.effectiveFrom 
                  }))
                : [],

            contractEapgconfigurationXref:
              values.isEAPGSelectionActive ?
                values.contractEapgconfigurationXref
                  .filter(item => item.eapgConfigurationId !== null)
                  .map(item => ({ 
                    eapgConfigurationId: item.eapgConfigurationId.id, 
                    percentage: parseInt(item.percentage), 
                    effectiveFrom: item.effectiveFrom 
                  }))
                : [],

            id : undefined,
            renew : undefined,
            alert : undefined,
            name: values.name,
            effectiveDate: values.effectiveDate,
            terminationDate: values.terminationDate,
            isPayer: values.isPayer === "true" ? true : false,
            description: values.description,
            notification: values.notification,
            vendorContacts: values.vendorContacts,
            expectedDaysToPay: values.expectedDaysToPay === '' ? null : values.expectedDaysToPay,
            contractStateId: values.contractStateId,
            contractOtherAllowableXref:
              values.isAllowableCodeActive ?
                [
                  ...values
                    .contractPatientResponsibilityXref
                    .map(item => ({ cascodeId: item.id, isPR: true })), 
                  ...values
                    .contractOtherAllowableXref
                    .map(item => ({ cascodeId: item.id, isPR: false }))
                ]
                : values
                    .contractPatientResponsibilityXref
                    .map(item => ({ cascodeId: item.id, isPR: true })),
          }

          if(directoryId && directoryId!=="root")
          {
            payload={...payload,
            contractDirectoryId:directoryId
            }
            

          }

          if (mode === 'create') {
            contractCreate(payload, handleCallback);
          }
          else {
            payload = {
              ...payload,
              id: values.id,
              renew: values.renew,
              alert: values.alert,
              vendorContacts: values.vendorContacts.map(contact => (
                {
                  ...contact,
                  contractId: contractId
                }
              )),
            }
            contractUpdate(values.id, payload, handleEditCallback);
          }
        }
        }
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldError,
          dirty
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.container}>
            <Portlet>
              <PortletHeader
                title={`${isEdit ? 'Edit' : 'Add'} Contract`}
                toolbar={
                  <PortletHeaderToolbar>
                    <BackButton defaultRoute={`/cms/contract/view/${contractId}${directoryId ? `/${directoryId}`:''}`} />
                    {mode === 'edit' && <Tooltip title="Redirects you to the view page of this contract">
                      <Button
                        variant="contained"
                        className='ml-3'
                        type="button"
                        onClick={handleRedirectToContractView}
                      >
                        Contract View 
                      </Button>
                    </Tooltip>}
                    <Button
                      variant="contained"
                      color="primary"
                      className='ml-3'
                      type="submit"
                      disabled={contractsCreatePending || contractsUpdatePending || (!dirty && isEdit)}
                    >
                      Save
                    </Button>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <div className="root">
                  <Grid container>
                    <Grid item xs={9}>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            required
                            label="Name"
                            name="name"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            helperText={touched.name && errors.name}
                            error={Boolean(touched.name && errors.name)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            type="date"
                            label="Effective Date"
                            name="effectiveDate"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.effectiveDate}
                            helperText={touched.effectiveDate && errors.effectiveDate}
                            error={Boolean(touched.effectiveDate && errors.effectiveDate)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            type="date"
                            label="Termination Date"
                            name="terminationDate"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.terminationDate}
                            helperText={touched.terminationDate && errors.terminationDate}
                            error={Boolean(touched.terminationDate && errors.terminationDate)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {mode === 'edit' && (
                      <Grid item xs={9}>
                        <Grid container>
                          <Grid item xs={6}>
                            <TextField
                              type="date"
                              label="Renew"
                              name="renew"
                              className={classes.textField}
                              margin="normal"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.renew}
                              helperText={touched.renew && errors.renew}
                              error={Boolean(touched.renew && errors.renew)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              type="date"
                              label="Alert"
                              name="alert"
                              className={classes.textField}
                              margin="normal"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.alert}
                              helperText={touched.alert && errors.alert}
                              error={Boolean(touched.alert && errors.alert)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={9}>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            type="text"
                            multiline
                            rows={3}
                            label="Description"
                            name="description"
                            className={classes.description}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                            helperText={(touched.description && errors.description) ?? 'This description is used in the MRF as additional notes.'}
                            error={Boolean(touched.description && errors.description)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl className={classes.formControlAuto}>
                            <Autocomplete
                              onChange={(e, value) => setFieldValue("alertContacts", value)}
                              filterOptions={filterOptions}
                              value={values.alertContacts}
                              multiple
                              options={alertUsers}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.name}
                                </li>
                              )}
                              renderTags={(tagValue, getTagProps) => {
                                const numTags = tagValue.length;
                                return (
                                  <Typography variant="caption">
                                    {tagValue.slice(0, 2).map((option, index) => (
                                      <Chip
                                        size="small"
                                        color="secondary"
                                        key={index}
                                        label={option.name}
                                        {...getTagProps({ index })}
                                      />
                                    ))}
                                    {numTags > 2 && ` + ${numTags - 2}`}
                                  </Typography>
                                )
                              }
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Alert Receipients"
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl className={classes.facilityFormControlAuto}>
                            <Autocomplete
                              onChange={(e, value) => setFieldValue("facilityIds", value)}
                              filterOptions={facilityFilterOption}
                              value={values.facilityIds}
                              multiple
                              options={facilities}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.name}
                              getOptionDisabled={option => {
                                if (!option.active) {
                                  const temp = values.facilityIds.find(item => item.id === option.id);
                                  return temp ? false : true
                                }
                                return false
                              }}
                              renderOption={(props, option, { selected }) => {
                                return (
                                  <li {...props}>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    <span className={!option.active && 'text-muted'}> {option.name}</span>
                                  </li>
                                )
                              }}
                              renderTags={(tagValue, getTagProps) => {
                                const numTags = tagValue.length;
                                return (
                                  <Typography variant="caption">
                                    {tagValue.slice(0, 2).map((option, index) => (
                                      <Chip
                                        size="small"
                                        color={option.active ? "secondary" : "default"}
                                        key={index}
                                        label={option.name}
                                        {...getTagProps({ index })}
                                      />
                                    ))}
                                    {numTags > 2 && ` +${numTags - 2}`}
                                  </Typography>
                                )
                              }
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Facilities"
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Expected Days to Pay"
                            name="expectedDaysToPay"
                            className={classes.textField}
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type='number'
                            value={values.expectedDaysToPay}
                            helperText={touched.expectedDaysToPay && errors.expectedDaysToPay}
                            error={Boolean(touched.expectedDaysToPay && errors.expectedDaysToPay)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl className={classes.formControlDropdown}>
                            <InputLabel htmlFor="contractStateId">Contract State</InputLabel>
                            <Select
                              required
                              value={values.contractStateId}
                              onChange={handleChange}
                              error={Boolean(touched.contractStateId && errors.contractStateId)}
                              input={<Input id="contractStateId" />}
                              inputProps={{
                                name: "contractStateId",
                                id: "contractStateId"
                              }}
                            >
                              {contractsStates.map(state => (
                                <MenuItem
                                  key={state.id}
                                  value={state.id}
                                >
                                  {state.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText error>{touched.contractStateId && errors.contractStateId}</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            type="text"
                            label="Finalized Date"
                            name="finalizedDate"
                            className={classes.textField}
                            margin="normal"
                            value={values.finalizedDate}
                            disabled={true}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>

                    </Grid >
                    <Grid item xs={9}>
                      <Grid container className="mt-4">
                        {/* <Grid item xs={6}>
                          <FormGroup>
                            <Typography component="div">
                              <Grid component="label" container alignItems="center" spacing={0}>
                                <Grid item>
                                  <FormControl>
                                    <FormLabel>Contract Type</FormLabel>
                                    <RadioGroup
                                      className={classes.group}
                                      row
                                      aria-label="isPayer"
                                      name="isPayer"
                                      value={values.isPayer}
                                      onChange={handleChange}

                                    >
                                      <FormControlLabel
                                        value="true"
                                        control={<Radio />}
                                        label="Payer"
                                        disabled={isEdit}
                                      />
                                      <FormControlLabel
                                        value="false"
                                        control={<Radio />}
                                        label="Vendor"
                                        disabled={isEdit}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Typography>
                          </FormGroup>
                        </Grid> */}
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="notification"
                                color="primary"
                                checked={values.notification}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={classes.switch}
                                value={values.notification}
                              />
                            }
                            label="Notification"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="isCriteriaActive"
                                color="primary"
                                checked={values.isCriteriaActive}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={classes.switch}
                                value={values.isCriteriaActive}
                              />
                            }
                            label="Criteria"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="isDenialCasCodeActive"
                                color="primary"
                                checked={values.isDenialCasCodeActive}
                                onChange={(e) => handleDenialCasCodeToggleChange(e, setFieldValue)}
                                onBlur={handleBlur}
                                className={classes.switch}
                                value={values.isDenialCasCodeActive}
                              />
                            }
                            label="Denial Codes"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="isAllowableCodeActive"
                                color="primary"
                                checked={values.isAllowableCodeActive}
                                onChange={(e) => handleAllowableToggleChange(e, setFieldValue)}
                                onBlur={handleBlur}
                                className={classes.switch}
                                value={values.isAllowableCodeActive}
                              />
                            }
                            label="Other Allowable"
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="isPpsSelectionActive"
                                color="primary"
                                checked={values.isPpsSelectionActive}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={classes.switch}
                                value={values.isPpsSelectionActive}
                              />
                            }
                            label="Medicare PPS"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="isEAPGSelectionActive"
                                color="primary"
                                checked={values.isEAPGSelectionActive}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={classes.switch}
                                value={values.isEAPGSelectionActive}
                              />
                            }
                            label="EAPG"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={10} className="mt-3">
                      <Card>
                        <Card.Header as="h5" className={`${Boolean((touched.contractPatientResponsibilityXref && errors.contractPatientResponsibilityXref) || errors.contractOtherAllowableXref) && classes.redColor}`}>Patient Responsibility
                          {Boolean(touched.contractPatientResponsibilityXref && errors.contractPatientResponsibilityXref) && <span className={classes.errorText}> (Please select at least one patient responsibility)</span>}
                          {Boolean(errors.contractOtherAllowableXref) && <span className={classes.errorText}> (Patient responsibility codes cannot be used for Other Allowables.)</span>}
                        </Card.Header>
                        <Card.Body>
                          <ContractPatientResponsibilitySelection
                            values={values}
                            casCodes={casCodes}
                            setFieldValue={setFieldValue}
                          />
                        </Card.Body>
                      </Card>
                    </Grid>
                    {values.isCriteriaActive &&
                      <Grid item xs={10} className="mt-3">
                        <Card>
                          <Card.Header as="h5">Contract Selection Criteria</Card.Header>
                          <Card.Body>
                            <ContractSelectionCriteriaFieldArray
                              values={values}
                              contractCriteria={contractCriteria}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                              setFieldValue={setFieldValue}
                            />
                          </Card.Body>
                        </Card>
                      </Grid>
                    }
                    {
                      values.isPpsSelectionActive && <>
                      <Grid id="ipps-div" item xs={10} className="mt-3">
                        <Card>
                          <Card.Header as="h5">IPPS configurations (Optional)</Card.Header>
                          <Card.Body>
                            <ContractPpsSelectionCriteriaFieldArray
                              values={values}
                              drgs={drgs}
                              touched={touched}
                              errors={errors}
                              setFieldValue={setFieldValue}
                            />
                          </Card.Body>
                        </Card>
                      </Grid>
                      <Grid id="opps-div" item xs={10} className="mt-3">
                        <Card>
                          <Card.Header as="h5">OPPS configurations (Optional)</Card.Header>
                          <Card.Body className={values.isEAPGSelectionActive ? classes.disabledSection : ''}>
                            <ContractOPPSSelectionCriteriaFieldArray
                              values={values}
                              oppsList={oppsList}
                              touched={touched}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              disabled={values.isEAPGSelectionActive}
                            />
                          </Card.Body>
                        </Card>
                      </Grid>
                      </>
                    }
                    {
                      values.isEAPGSelectionActive &&
                      <Grid id="eapg-div" item xs={10} className="mt-3">
                        <Card>
                          <Card.Header as="h5">EAPG configurations (Optional)</Card.Header>
                          <Card.Body>
                            <ContractEAPGSelectionCriteriaFieldArray
                              values={values}
                              eapgList={eapgList}
                              touched={touched}
                              errors={errors}
                              setFieldValue={setFieldValue}
                            />
                          </Card.Body>
                        </Card>
                      </Grid>
                    }

                    {
                      values.isDenialCasCodeActive &&
                      <Grid item xs={10} className="mt-3">
                        <Card>
                          <Card.Header as="h5">Contract Denial Code Selection (Optional)</Card.Header>
                          <Card.Body>
                            <ContractDenialCodeSelection
                              values={values}
                              casCodes={casCodes}
                              setFieldValue={setFieldValue}
                            />
                          </Card.Body>
                        </Card>
                      </Grid>
                    }
                    {values.isAllowableCodeActive &&
                      <Grid item xs={10} className="mt-3">
                        <Card>
                          <Card.Header as="h5" className={`${Boolean(errors.contractOtherAllowableXref) && classes.redColor}`}>Other Allowable (Optional)
                          {Boolean(errors.contractOtherAllowableXref) && <span className={classes.errorText}> (Patient responsibility codes cannot be used for Other Allowables.)</span>}
                          </Card.Header>
                          <Card.Body>
                            <ContractAllowableCodeSelection
                              values={values}
                              casCodes={casCodes.filter(cc => cc.code !== '1' && cc.code !== '2' && cc.code !== '3')}
                              setFieldValue={setFieldValue}
                            />
                          </Card.Body>
                        </Card>
                      </Grid>
                    }
                    {
                      isEdit && (
                        <Grid item xs={10} className="mt-3">
                          <DocumentUpload contractId={contractId} handleUploadCallback={handleUploadCallback} module={MODULE_TYPE_CONTRACT} />
                        </Grid>
                      )
                    }
                    <Grid item xs={10} className="mt-3">
                      <ContactFieldArray
                        mode={mode}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched}
                        errors={errors}
                        vendorContacts={values.vendorContacts}
                        contactTemplate={contactTemplate}
                      />
                    </Grid>
                  </Grid >
                </div >
              </PortletBody >
            </Portlet >
          </form >
        )
        }
      </Formik >
    </Loadable >
    </>
  );
}