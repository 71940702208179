import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';

import { setArchivedClaim } from '../../../store/actions/claimAnalysis';
import { selectClaimHistory } from '../../../store/selectors/claimAnalysis';
import { asIsoDate } from '../../../util/date';
import ClaimHistoryComponent from './ClaimHistory';

export const ClaimHistory = connect(
    () => {

        const selectOneClaimFromDetails = state => {
            return state.claimAnalysis.detail[0] ?? []
        };

        const selectCurrentVariance = createSelector(state => state.claimAnalysis.detail,
            (details) => {
                const temp = details.reduce((acc, curr) => acc + curr.variance, 0)
                return parseFloat(temp?.toFixed(2)) ?? 0
            })

        const selectLineChartData = createSelector(selectClaimHistory, selectCurrentVariance,
            (history, currentVariance) => {
                const varianceData = history.map(item => item.variance)
                return [...varianceData, currentVariance]
            })

        const selectLineChartLabels = createSelector(selectClaimHistory, selectOneClaimFromDetails,
            (history, selectedClaim) => {
                const remitDatesData = history.map(item => (asIsoDate(item.remitDate)))
                return [...remitDatesData, asIsoDate(selectedClaim?.remitDate)]
            })

        return (state, props) => ({
            lineChartData: selectLineChartData(state, props),
            lineChartLabels: selectLineChartLabels(state, props),
            claimHistory: selectClaimHistory(state, props),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            setArchivedClaim: setArchivedClaim,
        }, dispatch)
    })
)(injectIntl(ClaimHistoryComponent));