import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { set,setMulti } from '../../store/actions/pages';
import { createSelector } from 'reselect';

import { claimAnalysisLanding } from '../../store/actions/pages';
import ClaimAnalysisComponent from './ClaimAnalysisPage';

import { getClaimStatuses, setClaimUpdateStatusDialog } from '../../store/actions/claimAnalysis';

import { claimAnalysisStats, download, flag, resetClaimProcessError, setBulkAcknowledge, setBulkFlagged, setDrilldown, setFlagToggle, setOpenAcknowledgeDialog, setOpenFlagDialog, setOpenPlayDialog, setSelectedClaims } from '../../store/actions/claimAnalysis';
import { tiny } from '../../store/actions/contracts';
import { npiRolesLanding } from '../../store/actions/npiRoles';
import { getPayersTiny } from '../../store/actions/payers';
import { getByName, reset } from '../../store/actions/search';
import { selectDefaultCustomFilters } from '../../store/selectors/claimAnalysis';
import { selectSelectedClientName } from "../../store/selectors/clients";
import { selectSessionHasClaimProcessorAccess } from '../../store/selectors/session';

export const selectContractsTiny = (state) => state.contracts.tiny;
export const selectPayersTiny = (state) => state.payers.tiny;
export const selectClaimStatusDropdownValues = (state) => state.claimAnalysis.claimStatuses || [];
export const selectNpiRolesDropdownValues = (state) => (state.npiRoles.data?.map((item)=>({label:item?.name,value:item?.name,name:item?.name})))

const selectPending = createSelector(
        (state) => state.pages,
        (state) => state.search.getByName.pending,
        ( pages, searchPending) => {
            return pages['claimAnalysis-landing'].pending || searchPending
        }
    );

export const ClaimAnalysisPage = connect(
    () => {
        return (state, props) => ({
            hasClaimProcessorAccess: selectSessionHasClaimProcessorAccess(state),
            selectedClientName: selectSelectedClientName(state),
            defaultCustomFilters: selectDefaultCustomFilters(state, props),
            claimStatusList:selectClaimStatusDropdownValues(state),
            pending:selectPending(state)
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            claimAnalysisLanding: claimAnalysisLanding,
            getClaimAnalysisStats: claimAnalysisStats,
            claimAnalysisSetDrilldown: setDrilldown,
            getClaimStatuses:getClaimStatuses,
            getNpiRoles:npiRolesLanding,
            searchGetByName: getByName,
            searchReset: reset,
            setCustomFilter: (value) => set('claimAnalysis-landing', 'customFilter', value),
            setFilter: (value) => set('claimAnalysis-landing', 'filter', value),
            resetSearchCriteria: (value) => set('claimAnalysis-landing', 'selectedFilter', value),
            resetPagedTable: () => set('claimAnalysis-landing', 'list', []),
            resetCriteriaWaiting: () => set('claimAnalysis-landing', 'waitForCriteriaFilter', false),
            resetSearchAPICompleted: () => set('claimAnalysis-landing', 'isSearchApiCompleted', false),
            downloadAnalysisReport: download,
            flagClaims: flag,
            getTinyContracts: tiny,
            getPayersTiny: getPayersTiny,
            resetClaimProcessError: resetClaimProcessError,
            setOpenAcknowledgeDialog: setOpenAcknowledgeDialog,
            setOpenFlagDialog: setOpenFlagDialog,
            setFlagToggle: setFlagToggle,
            setBulkFlagged: setBulkFlagged,
            setBulkAcknowledge: setBulkAcknowledge,
            setSelectedClaims: setSelectedClaims,
            setOpenPlayDialog: setOpenPlayDialog,
            setClaimUpdateStatusDialog:setClaimUpdateStatusDialog,
            setMulti: obj => setMulti("claimAnalysis-landing", obj),
        }, dispatch)
    })
)(injectIntl(ClaimAnalysisComponent));