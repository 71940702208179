import { FieldArray } from "formik";
import React, { useState } from "react";

import { Button, FormControlLabel, Grid, IconButton, InputAdornment, Switch, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete } from '@mui/material';
import { asDate, dateRangeOverlaps } from "../../../../util/date";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '85%',
        marginLeft: theme.spacing(1),
    },
    portlet: {
        'box-shadow': 'none',
    },
    formControl: {
        width: '85%',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    autoComplete: {
        width: '85%',
        marginLeft: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1)
    },
    paper: {
        marginTop: theme.spacing(2),
    },
    iconButton: {
        marginTop: theme.spacing(3),
    },
    clearButton: {
        marginTop: theme.spacing(3),
        marginLeft: '10px',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    effectiveBox: {
        height: '100%',
        paddingTop: '25px'
    },
    switch: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

export default function ContractPpsSelectionCriteriaFieldArray({
    values, touched, errors, drgs, setFieldValue
}) {
    const [customErrors, setCustomErrors] = useState([]);

    const classes = useStyles();

    function getError(key, index) {
        if (errors.drgconfigurationToContractXref) {
            if (errors.drgconfigurationToContractXref[index]) {
                return errors.drgconfigurationToContractXref[index][key];
            }
            return false;
        }
        return false;
    }

    function handlePpsChangeHandler(index, criteriaObj) {
        if (criteriaObj) {
            setFieldValue(`drgconfigurationToContractXref.${index}.drgConfigurationId`, criteriaObj);

            const tempXref = [...values.drgconfigurationToContractXref];
            tempXref[index].drgConfigurationId = criteriaObj;

            const filteredConfgs = tempXref.filter(item => item.drgConfigurationId)
            const dateAlreadyExist = filteredConfgs.find(item => {
                const isDateOverlapping = dateRangeOverlaps(criteriaObj.effectiveFrom, criteriaObj.effectiveTo, item.drgConfigurationId.effectiveFrom, item.drgConfigurationId.effectiveTo)

                return isDateOverlapping && item.drgConfigurationId.id !== criteriaObj.id;
            });

            if (dateAlreadyExist) {
                const temp = [...customErrors];
                temp[index] = 'Configuration with same date range already exists. Please select different configuration.'
                setCustomErrors(temp);
                setFieldValue(`drgconfigurationToContractXref.${index}.customPPSError`, 'error');
            } else {
                setFieldValue(`drgconfigurationToContractXref.${index}.customPPSError`, undefined);
                const temp = [...customErrors];
                temp[index] = null
                setCustomErrors(temp);
            }

        } else {
            setFieldValue(`drgconfigurationToContractXref.${index}.drgConfigurationId`, criteriaObj);
            setFieldValue(`drgconfigurationToContractXref.${index}.effectiveFrom`, '');

            const temp = [...customErrors];
            temp[index] = null
            setCustomErrors(temp);
            setFieldValue(`drgconfigurationToContractXref.${index}.customPPSError`, undefined);
        }
    }

    function handleWageIndexChage(e, index) {
        setFieldValue(`drgconfigurationToContractXref.${index}.percentage`, e.target.value);
    }

    function handleEffectiveFromChange(e, index) {
        setFieldValue(`drgconfigurationToContractXref.${index}.effectiveFrom`, e.target.value);
    }

    function handleInlcudeIMEChange(e, index) {
        setFieldValue(`drgconfigurationToContractXref.${index}.includeIME`, e.target.checked || false);
    }

    function handleInlcudeDSHChange(e, index) {
        setFieldValue(`drgconfigurationToContractXref.${index}.includeDSH`, e.target.checked || false);
    }

    function handleClearFields() {
        setFieldValue(`drgconfigurationToContractXref`, [{
            drgConfigurationId: null,
            value: '',
            percentage: 100,
            effectiveFrom: '',
            includeIME: false,
            includeDSH: false
        }]);

        setCustomErrors([]);
    }

    return (
        (<FieldArray
                name="drgconfigurationToContractXref"
                render={({ remove, insert }) => (
                    <Grid container>
                        <Grid item xs={12}>
                            {values.drgconfigurationToContractXref.map((drgCriteria, index) =>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            className={classes.autoComplete}
                                            value={drgCriteria?.drgConfigurationId}
                                            disableClearable={false}
                                            onChange={(e, value) => { handlePpsChangeHandler(index, value) }}
                                            isOptionEqualToValue={(option, value) => option === value}
                                            options={drgs}
                                            getOptionLabel={(option) => `${option.name}`}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    name={`drgconfigurationToContractXref.${index}.drgConfigurationId`}
                                                    label="IPPS"
                                                    margin="normal"
                                                    error={customErrors[index]}
                                                    helperText={customErrors[index]}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        {drgCriteria?.drgConfigurationId &&
                                            <Grid className={classes.effectiveBox}>
                                                <Grid><b>Effective Range</b></Grid>
                                                <span>
                                                    {asDate(drgCriteria?.drgConfigurationId?.effectiveFrom)}
                                                    {' to '}
                                                    {asDate(drgCriteria?.drgConfigurationId?.effectiveTo)}
                                                </span>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            type="date"
                                            label="Effective From"
                                            name={`drgconfigurationToContractXref.${index}.effectiveFrom`}
                                            className={classes.textField}
                                            margin="normal"
                                            value={drgCriteria.effectiveFrom}
                                            onChange={e => handleEffectiveFromChange(e, index)}
                                            helperText={getError('effectiveFrom', index)}
                                            error={getError('effectiveFrom', index)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TextField
                                            type="number"
                                            label="Percentage"
                                            name={`drgconfigurationToContractXref.${index}.percentage`}
                                            className={classes.textField}
                                            margin="normal"
                                            value={drgCriteria.percentage}
                                            onChange={e => handleWageIndexChage(e, index)}
                                            helperText={getError('percentage', index)}
                                            error={getError('percentage', index)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                            <FormControlLabel
                                                className={classes.switch}
                                                control={
                                                <Switch
                                                    name={`drgconfigurationToContractXref.${index}.includeIME`}
                                                    color="primary"
                                                    checked={drgCriteria.includeIME || false}
                                                    onChange={e => handleInlcudeIMEChange(e, index)}
                                                    value={drgCriteria.includeIME || false}
                                                />
                                                }
                                                label="Include IME"
                                            />
                                            </Grid>
                                            <Grid item xs={6}>
                                            <FormControlLabel
                                                className={classes.switch}
                                                control={
                                                <Switch
                                                    name={`drgconfigurationToContractXref.${index}.includeDSH`}
                                                    color="primary"
                                                    checked={drgCriteria.includeDSH || false}
                                                    onChange={e => handleInlcudeDSHChange(e, index)}
                                                    value={drgCriteria.includeDSH || false}
                                                />
                                                }
                                                label="Include DSH"
                                            />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={1}>
                                        <Grid container>
                                        {(index >= 0 && values.drgconfigurationToContractXref.length > 1) && (
                                            <IconButton
                                                edge="start"
                                                aria-label="Delete"
                                                onClick={() => {
                                                    remove(index)
                                                    const temp = [...customErrors];
                                                    temp[index] = null
                                                    setCustomErrors(temp);
                                                    setFieldValue(`drgconfigurationToContractXref.${index}.customPPSError`, undefined);
                                                }}
                                                className={classes.iconButton}
                                                size="large">
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                        {index === 0 && (
                                            <>
                                            <IconButton
                                                edge="start"
                                                color="primary"
                                                aria-label="Add"
                                                onClick={() => insert(values.drgconfigurationToContractXref.length, {
                                                    drgConfigurationId: null,
                                                    effectiveFrom: '',
                                                    percentage: '100'

                                                })}
                                                className={classes.iconButton}
                                                size="large">
                                                <AddCircleIcon />
                                            </IconButton>
                                            {/* <Button
                                                size="small"
                                                onClick={handleClearFields}
                                                className={classes.clearButton}
                                                startIcon={<DeleteIcon />}
                                            >
                                                Clear All
                                            </Button> */}
                                            </>
                                        )}
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
            />)
    );
}
