import React, { useState, useEffect } from 'react';
import { Tooltip, IconButton, Grid, Tabs, AppBar, Tab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Helmet from 'react-helmet';
import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../../partials/content/Portlet";
import { PagedTable } from '../../../common';
import classNames from 'classnames';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { TrimStatsPage } from '../StatisticsPage/TrimStatsPage';
import { PayerStatisticsPage } from '../../PayerStatisticsPage';
import { ClaimsAndRemitsPage } from '../StatisticsPage/Claims&RemitsPage';
import {AnalysisStatisticsPage} from '../AnalysisStatisticsPage';

const DEFAULT_SORT = [{ id: 'name', direction: 'asc' }];
const SHOW_SEARCH_FILTERS = true;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    tabs: {
        borderBottom: '1px solid #ebedf2' 
    },
    icon: {
        fontSize: 17,
        marginTop: 1,
    }
}));

export default function StatisticsTabPage({userRole,intl}) {

    const [tab, setTab] = React.useState(0);
    const classes = useStyles();

    const isSuperAdmin=userRole==="super_admin"
    const isSuperAdminOrAdmin=userRole==="super_admin" || userRole==="administrator"


    function a11yProps(index) {
        return {
            id: `vertical-stats-tab-${index}`,
            'aria-controls': `vertical-stats-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <>
            <Helmet title="Stats" />
            <Portlet>
                <PortletBody>
                    <Tabs
                        value={tab}
                        onChange={handleChange}
                        aria-label="Stats Tab"
                        className={classes.tabs}
                    >
                        {isSuperAdmin && (
                            <Tab label="Files" {...a11yProps(0)} />
                        )}
                        {isSuperAdminOrAdmin && (
                            <Tab label="Claim Payers" {...a11yProps(1)} />
                        )}
                        {isSuperAdminOrAdmin && (
                            <Tab label="Remit Payers" {...a11yProps(2)} />
                        )}
                        {isSuperAdmin && (
                            <Tab label="CLAIMS & REMITS" {...a11yProps(3)} />
                        )}
                        {isSuperAdmin && (
                            <Tab label="Failed Files" {...a11yProps(4)} />
                        )}
                        {isSuperAdmin && (
                            <Tab label="Load Errors" {...a11yProps(5)} />
                        )}
                        {isSuperAdmin && (
                            <Tab label="Sales Statistics" {...a11yProps(6)} />
                        )}
                    </Tabs>
                    {tab === 0 && (
                        <>
                            <TrimStatsPage />
                        </>
                    )}
                    {(tab === 1 || tab === 2) && (
                        <>
                            <PayerStatisticsPage tab={tab} />
                        </>
                    )}
                    {tab === 3 && (
                        <>
                            <ClaimsAndRemitsPage />
                        </>
                    )}

                    {tab === 4 && (
                        <>
                            <PayerStatisticsPage tab={tab} />
                        </>
                    )}

                    {tab === 5 && (
                        <>
                            <PayerStatisticsPage tab={tab} />
                        </>
                    )}
                    {tab === 6 && (
                        <>
                            <AnalysisStatisticsPage tab={tab} />
                        </>
                    )}
                </PortletBody>
            </Portlet>
        </>
    );
}