import { GridColDef } from "@mui/x-data-grid-pro";
import { PurePtr } from "@pure-ptr/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { set } from "../../store/actions/pages";
import { RootState } from "../../store/reducers";
import { CombinedFilter, getPaginationFilter, getTableSearchFilter } from "../../util/pagination";
import { PopoverLink } from "../popoverLink";

export type SortOptions = { id: string; direction: string }[]
export type PaginationFilters = { sort: SortOptions, filters: CombinedFilter, pagination: { currentPage: number; recordsPerPage: number; totalRecords?: number } }

export function createActionsField( getActions, headerName = 'Actions') : GridColDef {
    return {
        field: 'actions',
        headerName,
        type: 'actions' as any,
        width: 80,
        getActions( params ) {
            return params.id === 'totals' ? [] : getActions( params );
        }
    }
}

export function renderHoverLink( hoverData, titleSubstring = false ) {
    return (params) => (
        <PopoverLink
            columnData={params.value}
            title={titleSubstring ? `${params.value.substring(0, 15)}...` : params.value}
            hoverData={ hoverData( params.row )}
        />
    )
}

/**
 * Generates a hook for retrieving grid filters, sorting options, and pagination settings for a specified page.
 *
 * @template K - The key of the page in the RootState.
 * @param {K} page - The key representing the page in the RootState.
 * @param {Object} [defaults] - Optional default values for filters and sorting options.
 * @param {any[]} [defaults.filter] - Default filter values.
 * @param {SortOptions} [defaults.sort] - Default sorting options.
 * @returns {() => { sort: SortOptions, filters: CombinedFilter, pagination: { currentPage: number; recordsPerPage: number; totalRecords?: number } }} 
 *          A hook function that returns the current sorting options, combined filters, and pagination settings.
 */
export function pageGridFilters<K extends keyof RootState['pages']>( page : K, defaults? : { filter?: any[], sort?: SortOptions }) 
    : () => { sort: SortOptions, filters: CombinedFilter, pagination: { currentPage: number; recordsPerPage: number; totalRecords?: number } }
{
    return function useGridFilters() {
        const filter = useGlobalStore( x => x.pages[page].filter ),
            customFilter = useGlobalStore( x => x.pages[page].customFilter ),
            timeFilter = useGlobalStore( x => x.pages[page].timeFilter ),
            defaultFilter = useGlobalStore( x => x.pages[page].defaultFilter ),
            defaultSort = useGlobalStore( x => x.pages[page].defaultSort ),
            defaultCustomFilter = useGlobalStore( x => x.pages[page].defaultCustomFilter ),

            searchTerm = useGlobalStore( x => x.pages[page].searchTerm ),
            sort = useGlobalStore( x => x.pages[page].sort ),
            pagination = useGlobalStore( x => x.pages[page].page );

        const quickSearch = searchTerm ? getTableSearchFilter( searchTerm ) : null;

        const paginationFilters = getPaginationFilter(
            getDefault(filter, defaults?.filter ?? defaultFilter), 
            undefined, 
            timeFilter, 
            getDefault(customFilter, defaultCustomFilter)
                .filter( ( item : any ) => item.value !== 'both') as any
        );
        
        return {
            sort : getDefault(sort, defaults?.sort ?? defaultSort),
            filters : ( quickSearch ? {
                    ...paginationFilters,
                    operands: [
                        ...(paginationFilters?.operands || []),
                        ...quickSearch?.operands,
                    ],
                }
            : paginationFilters ) as any,
            pagination
        }
    }
}

type Initializer<T> = T | (() => T);

export function usePageStore<P extends keyof RootState['pages'], K extends keyof RootState['pages'][P]>( page : P, key : K, init? : Initializer<RootState['pages'][P][K]> ) : RootState['pages'][P][K] {
    const val = useSelector<RootState>( x => x.pages[page][key] );

    return getDefault( val, init );
}

export function usePageStorePtr<P extends keyof RootState['pages'], K extends keyof RootState['pages'][P]>( page : P, key : K, init? : Initializer<RootState['pages'][P][K]> ) : PurePtr<RootState['pages'][P][K]> {
    const dispatch = useDispatch(),
        val = useSelector<RootState>( x => x.pages[page][key] );

    return PurePtr.value( getDefault( val, init ), x => dispatch( set( page, key, x )));
}

/**
 * A utility function to create a typed selector hook for a specific page in the Redux store.
 * 
 * @template K - The key of the page in the RootState's pages object.
 * @template V - The type of the value returned by the selector function.
 * 
 * @param {K} page - The key of the page in the RootState's pages object.
 * 
 * @returns {function} - A hook function that takes a selector function and returns the selected value from the specified page's state.
 * 
 * @example
 * ```tsx
 * const useHomePageStore = pageStore('home');
 * ...
 * const someValue = useHomePageStore(x => x.someValue);
 * ```
 */
export function pageStore<K extends keyof RootState['pages']>( page : K, options? : any ) : {
    usePageStore<V>( selector : ( state : RootState['pages'][K] ) => V ) : V;
    useGridFilters() : ReturnType<ReturnType<typeof pageGridFilters>>;
    usePageStorePtr<V extends keyof RootState['pages'][K]>( key : V, init? : any ) : PurePtr<RootState['pages'][K][V]>;
}{
    return {
        usePageStore( selector : ( state : RootState['pages'][K] ) => any ){
            return useSelector<RootState, any>( x => selector(x.pages[page]) );
        },

        useGridFilters : pageGridFilters( page, options ),

        usePageStorePtr( key : any, init? : any ){
            const value = useSelector<RootState, any>( x => x.pages[page][key] );
            const dispatch = useDispatch();
            return PurePtr.value( getDefault( value, init ) , x => dispatch( set( page, key, x )));
        }
    }
}

/**
 * Creates a hook that returns a pointer to a specific value in the given Redux store's `pages` slice.
 *
 * @template K - The key of the `pages` slice in the `RootState`.
 * @template V - The key of the specific value within the `pages` slice.
 * 
 * @param {K} page - The key of the `pages` slice in the `RootState`.
 * @returns {function(V): PurePtr<RootState['pages'][K][V]>} - A function that takes a key and returns a pointer to the value in the Redux store.
 */
export function pageStorePtr<K extends keyof RootState['pages']>( page : K ) : <V extends keyof RootState['pages'][K]>( key : V, init?: any ) => PurePtr<RootState['pages'][K][V]> {
    return function usePageStorePtr( key : any, defaultVal? : any ){
        const value = useSelector<RootState, any>( x => x.pages[page][key] );
        const dispatch = useDispatch();
        return PurePtr.value( getDefault( value, defaultVal ) , x => dispatch( set( page, key, x )));
    }
}

export function useGlobalStore<T>( selector : ( state : RootState ) => T ) : T {
    return useSelector<RootState, T>( selector ?? ( x => x as any ));
}

/* TODO: Need reducer.

export function useGlobalStorePtr<K extends keyof RootState>( key : K ) : PurePtr<RootState[K]> {
    const value = useSelector<RootState, any>(  x => x[ key ] );
    const dispatch = useDispatch();
    return PurePtr.value( value, x => dispatch( x as any ));
}
*/

function getDefault<T>( arr : any, defaultArr : T | (() => T) ) : T {
    if( defaultArr === undefined ) return arr;
    
    const defaultValue = typeof defaultArr === 'function' ? ( defaultArr as any )() : defaultArr;
    
    return Array.isArray( defaultValue ) ?
        ( !arr?.length ? defaultArr : arr ) :
        arr ?? defaultArr;
}

export const dataGridStyles = {
    '& .Mui-error': {
        backgroundColor: 'rgba(255, 0, 0, 0.1)',
    },
    
    '& .MuiDataGrid-footerContainer': {
        minHeight: 36,
        '& .MuiTablePagination-toolbar': {
            minHeight: 36,
        }
    },

    '& .MuiDataGrid-columnHeaders': {
        '& [role="row"]': {
            backgroundColor: '#F5F5F5',
        }
    },

    '& .MuiDataGrid-pinnedRows': {
        '& .MuiDataGrid-row': {
            backgroundColor: '#FAFAFA',
        },

        '& .MuiDataGrid-cell': {
            fontWeight: 600,
        }
    }
}