export const CONTRACTSV2_GET = 'CONTRACTSV2_GET';
export const CONTRACTSV2_GET_PENDING = 'CONTRACTSV2_GET_PENDING';
export const CONTRACTSV2_GET_FULFILLED = 'CONTRACTSV2_GET_FULFILLED';
export const CONTRACTSV2_GET_REJECTED = 'CONTRACTSV2_GET_REJECTED';


export const CONTRACTSV2_HIERARCHY = 'CONTRACTSV2_HIERARCHY';
export const CONTRACTSV2_HIERARCHY_PENDING = 'CONTRACTSV2_HIERARCHY_PENDING';
export const CONTRACTSV2_HIERARCHY_FULFILLED = 'CONTRACTSV2_HIERARCHY_FULFILLED';
export const CONTRACTSV2_HIERARCHY_REJECTED = 'CONTRACTSV2_HIERARCHY_REJECTED';


export const CONTRACTSV2_CREATE_DIRECTORY = 'CONTRACTSV2_CREATE_DIRECTORY';
export const CONTRACTSV2_CREATE_DIRECTORY_PENDING = 'CONTRACTSV2_CREATE_DIRECTORY_PENDING';
export const CONTRACTSV2_CREATE_DIRECTORY_FULFILLED = 'CONTRACTSV2_CREATE_DIRECTORY_FULFILLED';
export const CONTRACTSV2_CREATE_DIRECTORY_REJECTED = 'CONTRACTSV2_CREATE_DIRECTORY_REJECTED';

export const CONTRACTSV2_UPDATE_DIRECTORY = 'CONTRACTSV2_UPDATE_DIRECTORY';
export const CONTRACTSV2_UPDATE_DIRECTORY_PENDING = 'CONTRACTSV2_UPDATE_DIRECTORY_PENDING';
export const CONTRACTSV2_UPDATE_DIRECTORY_FULFILLED = 'CONTRACTSV2_UPDATE_DIRECTORY_FULFILLED';
export const CONTRACTSV2_UPDATE_DIRECTORY_REJECTED = 'CONTRACTSV2_UPDATE_DIRECTORY_REJECTED';

export const CONTRACTSV2_DIRECTORY_EDIT_DIALOG = 'CONTRACTSV2_DIRECTORY_EDIT_DIALOG';
export const CONTRACTSV2_RESET_DIRECTORY_DEPDENDENCY = 'CONTRACTSV2_RESET_DIRECTORY_DEPDENDENCY';
export const CONTRACTSV2_DIRECTORY_SET_DEPENDENCY_DIALOG = 'CONTRACTSV2_DIRECTORY_SET_DEPENDENCY_DIALOG';
export const CONTRACTSV2_MOVE_DIALOG = 'CONTRACTSV2_MOVE_DIALOG';
export const CONTRACTSV2_RESET = 'CONTRACTSV2_RESET';

export const CONTRACTSV2_SEARCH = 'CONTRACTSV2_SEARCH';
export const CONTRACTSV2_SEARCH_PENDING = 'CONTRACTSV2_SEARCH_PENDING';
export const CONTRACTSV2_SEARCH_FULFILLED = 'CONTRACTSV2_SEARCH_FULFILLED';
export const CONTRACTSV2_SEARCH_REJECTED = 'CONTRACTSV2_SEARCH_REJECTED';


export const CONTRACTSV2_DIRECTORY_DELETE_DEPENDENCIES = 'CONTRACTSV2_DIRECTORY_DELETE_DEPENDENCIES';
export const CONTRACTSV2_DIRECTORY_DELETE_DEPENDENCIES_PENDING = 'CONTRACTSV2_DIRECTORY_DELETE_DEPENDENCIES_PENDING';
export const CONTRACTSV2_DIRECTORY_DELETE_DEPENDENCIES_FULFILLED = 'CONTRACTSV2_DIRECTORY_DELETE_DEPENDENCIES_FULFILLED';
export const CONTRACTSV2_DIRECTORY_DELETE_DEPENDENCIES_REJECTED = 'CONTRACTSV2_DIRECTORY_DELETE_DEPENDENCIES_REJECTED';

export const CONTRACTSV2_DIRECTORY_DELETE = 'CONTRACTSV2_DIRECTORY_DELETE';
export const CONTRACTSV2_DIRECTORY_DELETE_PENDING = 'CONTRACTSV2_DIRECTORY_DELETE_PENDING';
export const CONTRACTSV2_DIRECTORY_DELETE_FULFILLED = 'CONTRACTSV2_DIRECTORY_DELETE_FULFILLED';
export const CONTRACTSV2_DIRECTORY_DELETE_REJECTED = 'CONTRACTSV2_DIRECTORY_DELETE_REJECTED';


export const CONTRACTSV2_MOVE = 'CONTRACTSV2_MOVE';
export const CONTRACTSV2_MOVE_PENDING = 'CONTRACTSV2_MOVE_PENDING';
export const CONTRACTSV2_MOVE_FULFILLED = 'CONTRACTSV2_MOVE_FULFILLED';
export const CONTRACTSV2_MOVE_REJECTED = 'CONTRACTSV2_MOVE_REJECTED';

export const getContractsV2 = (directoryId,isFinalized) => ({
    type: CONTRACTSV2_GET,
    payload: { directoryId,isFinalized }
});
export const getHierarchy = () => ({
    type: CONTRACTSV2_HIERARCHY
});

export const setDirectoryEditDialog = (directoryEditDialog) => ({
    type: CONTRACTSV2_DIRECTORY_EDIT_DIALOG,
    payload: { directoryEditDialog }
});
export const createDirectory = (options, callback) => ({
    type: CONTRACTSV2_CREATE_DIRECTORY,
    payload: { options, callback }
});

export const updateDirectory = (options, callback) => ({
    type: CONTRACTSV2_UPDATE_DIRECTORY,
    payload: { options, callback }
});

export const setContractsV2DirectoryDependencyDialog = (directoryDependencyDialog) => ({
    type: CONTRACTSV2_DIRECTORY_SET_DEPENDENCY_DIALOG,
    payload: { directoryDependencyDialog }
});
export const resetDirectoryDependencies = () => ({
    type: CONTRACTSV2_RESET_DIRECTORY_DEPDENDENCY
});

export const getContractsV2DirectoryDependencies = (directoryId) => ({
    type: CONTRACTSV2_DIRECTORY_DELETE_DEPENDENCIES,
    payload: { directoryId }
});

export const deleteContractsV2ScheduleDirectory = (directoryId, callback) => ({
    type: CONTRACTSV2_DIRECTORY_DELETE,
    payload: { directoryId, callback }
});

export const setMoveDialog = (moveDialog) => ({
    type: CONTRACTSV2_MOVE_DIALOG,
    payload: { moveDialog }
});
export const moveDirectoryOrContracts = (options, callback) => ({
    type: CONTRACTSV2_MOVE,
    payload: { options, callback }
});
export const resetContracts = () => ({
    type: CONTRACTSV2_RESET
});
export const contractsSearch = (options) => ({
    type: CONTRACTSV2_SEARCH,
    payload: { options }
});