import { Autocomplete, Box, Checkbox, Chip, createFilterOptions, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import { ContentRow, Loadable, DataTable } from '../../../common';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { DateRangePicker } from '../../../common/dateRangeControl';
import { useStatePtr } from '@pure-ptr/react';
import { getLast30Days } from '../../../util/date';
import { STATES } from '../../../store/constants/clients';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    autocomplete: {
        width: '100%',
        marginTop: '-2px'
    },
    portlet: {
        display: 'flex',
        flexGrow: 1,
    },
}));

export default function AnalysisStatisticsPage({
    intl,
    tab, 
    data, fields, sort, order, analysisStatsPending, filter,
    actions: {
        analysisStatsLanding,
        setSort, setOrder, setFilter
    }
}) {
    const [refresh, setRefresh] = useState(Promise.resolve());
    const [isStatesFilterDropdownOpen, setIsStatesFilterDropdownOpen] = useState(false);
    const dateRangePtr = useStatePtr( getLast30Days );
    const ALL_OPTION = { displayName: 'ALL', abbreviation: 'all' };
    const statesList = [ALL_OPTION, ...STATES];
    const statesPtr = useStatePtr([ALL_OPTION]);

    const classes = useStyles();

    useEffect(() => {
        analysisStatsLanding(
            {
                FromDate: dateRangePtr.value.startdate,
                ToDate: dateRangePtr.value.enddate,
                States: statesPtr.value?.map(item => item.abbreviation),
            }
        );
    }, [analysisStatsLanding, dateRangePtr.value, refresh]);

    function handleRefresh() {
        setRefresh(Promise.resolve());
    }

    useEffect(() => {
        if(!isStatesFilterDropdownOpen) {
            handleRefresh();
        }
    }, [isStatesFilterDropdownOpen, statesPtr.value]);

    const filterOptions = createFilterOptions<{ displayName: string; abbreviation: string }>({
        stringify: option => option.displayName + ' ' + option.abbreviation,
    });

    const handleChange = (e, value) => {
        if(value.some((option) => option.abbreviation === "all")) {
            statesPtr.set([ALL_OPTION]);
        }
        else {
            statesPtr.set(value.filter((opt) => opt.abbreviation != "all"));
        }
        
    }

    return (
        <>
            <Helmet title="Sales Statistics" />
            <Portlet>
                <PortletHeader
                    title="Statistics"
                    name="analysis-stats-landing"
                    toolbar={
                        <PortletHeaderToolbar fullWidth className={classes.portlet}>
                        <Grid container spacing={3} justifyContent="flex-end" className='mt-2'>
                            <Grid item md={2}>
                                <DateRangePicker
                                    disabled={false}
                                    valuePtr={dateRangePtr} 
                                    label="Date Range"
                                    enabledSwitch={false}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Autocomplete
                                    className={classes.autocomplete}
                                    onChange={handleChange}
                                    onOpen={() => setIsStatesFilterDropdownOpen(true)}
                                    onClose={() => setIsStatesFilterDropdownOpen(false)}
                                    filterOptions={filterOptions}
                                    value={statesPtr.value}
                                    multiple
                                    options={statesList}
                                    disableCloseOnSelect
                                    isOptionEqualToValue={(option, value) => option.abbreviation === value.abbreviation}
                                    getOptionLabel={(option) => `${option.displayName} - ${option.abbreviation}`}
                                    renderOption={(props, option, { selected }) => {
                                        const isDisabled = statesPtr.value.some((item) => item.abbreviation === "all") &&
                                            option.abbreviation != "all";
                                        return (
                                        <li {...props}>
                                            
                                            <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            disabled={isDisabled}
                                            />
                                            {option.displayName === 'ALL' ? <span> {`${option.displayName}`}</span> : <span style={{color: isDisabled ? '#aeaeae' : 'inherit'}}> {`${option.displayName} - ${option.abbreviation}`}</span>}
                                        </li>
                                        )
                                    }}
                                    renderTags={(tagValue, getTagProps) => {
                                        const numTags = tagValue.length;
                                        return (
                                        <Typography variant="caption">
                                            {tagValue.slice(0, 6).map((option, index) => (
                                            <Chip
                                                size="small"
                                                color={"secondary"}
                                                key={index}
                                                label={`${option.abbreviation}`}
                                                {...getTagProps({ index })}
                                            />
                                            ))}
                                            {numTags > 6 && ` +${numTags - 6}`}
                                        </Typography>
                                        )
                                    }
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        label="State(s)"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </PortletHeaderToolbar>
                        
                    }
                />
                <PortletBody>
                    <>
                        <div className="row">
                            <div className="col-md-12">
                                <DataTable
                                    fields={fields}
                                    data={data}
                                    order={order}
                                    onOrder={setOrder}
                                    sort={sort}
                                    onSort={setSort}
                                    hasActions={false}
                                    loading={analysisStatsPending}
                                    name="Sales Statistics"
                                    handleRefresh={handleRefresh}
                                    displayTotal={true}
                                />
                            </div>
                        </div>
                    </>
                </PortletBody>
            </Portlet>
        </>
    );
}